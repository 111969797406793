import React, { useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import AllOrdersScreen from '~/components/screens/AllOrdersScreen';
import CashScreen from '~/components/screens/CashScreen';
import CommissionsScreen from '~/components/screens/CommissionsScreen';
import InterestSwapScreen from '~/components/screens/InterestSwapScreen';
import NewsScreen from '~/components/screens/NewsScreen';
import NotificationsScreen from '~/components/screens/NotificationsScreen';
import OpenTradesScreen from '~/components/screens/OpenTradesScreen';
import ResultScreen from '~/components/screens/ResultScreen';
import WatchlistItemScreen from '~/components/screens/WatchlistItemScreen';
import { useStyles, useAppSelector } from '~/hooks';
import { appSettingsSelector, favoritesDataSelector } from '~/state/selectors';
import BigChart from '../BigChart';
import DesktopFooter from './DesktopFooter';
import DesktopHeader from './DesktopHeader';
import DesktopHeaderNavigation from './DesktopHeaderNavigation';
import EditFavoritesList from '../Favorites/EditFavoritesList';
import MoreScreen from '~/components/screens/MoreScreen';
import SettingsScreen from '~/components/screens/SettingsScreen';
import WithdrawFundsScreen from '~/components/screens/WithdrawFundsScreen';
import ChangePasswordScreen from '~/components/screens/ChangePasswordScreen';
import NotificationsSettingsScreen from '~/components/screens/NotificationsSettingsScreen';
import AddFundsScreen from '~/components/screens/AddFundsScreen';
import AddFundsCategoryScreen from '~/components/screens/AddFundsCategoryScreen';
import AddFundsAlternativeScreen from '~/components/screens/AddFundsAlternativeScreen';
import AddFundsBankScreen from '~/components/screens/AddFundsBankScreen';
import AddFundsCardScreen from '~/components/screens/AddFundsCardScreen';
import InvestorsScreen from '~/components/screens/InvestorsScreen';
import InvestmentsScreen from '~/components/screens/InvestmentsScreen';
import AddFundsAlternativePaymentScreen from '~/components/screens/AddFundsAlternativePaymentScreen';
import LanguagePicker from './LanguagePicker';
import { Theme } from '~/theme';
import { Text } from '~/components/core';
import { FONT_SIZE_XS } from '~/constants';
import { AntDesign } from "~/components/core/Icons";
import UploadDocumentsScreen from '~/components/screens/UploadDocumentsScreen';
import ClientReportScreen from '~/components/screens/ClientReportScreen';
import VerificationCenterScreen from '~/components/screens/VerificationCenterScreen';
import AccountsScreen from '~/components/screens/AccountsScreen';
import ResultReportScreen from '~/components/screens/ResultReportScreen';
import SwapReportScreen from '~/components/screens/SwapReportScreen';
import AddFundsCardPaytikoScreen from "~/components/screens/AddFundsCardPaytikoScreen";


export default function Desktop() {
    const styles = useStyles(stylesGenerator);
    const [activeRightTab, setActiveRightTab] = useState('bottom-tab-news');
    const favorites = useAppSelector(favoritesDataSelector);
    const [activeWatchList, setActiveWatchlist] = useState(favorites.id);
    const [activeChart, setActiveChart] = useState<string[]>([favorites.instrumentCodes.length>0 ? favorites.instrumentCodes[0] : null, favorites.instrumentCodes.length>1 ? favorites.instrumentCodes[1] : null, favorites.instrumentCodes.length>2 ? favorites.instrumentCodes[2] : null, favorites.instrumentCodes.length>3 ?  favorites.instrumentCodes[3] : null]);
    const [openEditFavorites, setOpenEditFavorites] = useState(false);
    const [currentAmount, setCurrentAmount] = useState('');
    const [provider, setProvider] = useState('');
    const [paytikoPaymentProvider, setPaytikoPaymentProvider] = useState<number>(0);
    const [selectedId, setSelectedId] = useState('');
    const [openLanguageMenu, setOpenLanguageMenu] = useState(false);
    const [hideRightColumn, setHideRightColumn] = useState(false);
    const [showHideButton, setShowHideButton] = useState(false);
    const bigChartScreen = true;//useAppSelector(appSettingsSelector);

    return (
        <View style={styles.container}>
            {openLanguageMenu ?
                <LanguagePicker setOpenLanguageMenu={setOpenLanguageMenu} /> : null
            }
            <View>
                <DesktopHeader setActiveRightTab={setActiveRightTab} setOpenLanguageMenu={setOpenLanguageMenu} openLanguageMenu={openLanguageMenu} activeRightTab={activeRightTab} />
            </View>

            <View>
                <DesktopHeaderNavigation
                    activeWatchList={activeWatchList}
                    setActiveWatchlist={setActiveWatchlist}
                    setOpenEditFavorites={setOpenEditFavorites}
                    activeRightTab={activeRightTab}
                    setActiveRightTab={setActiveRightTab}
                />
            </View>
            <View style={styles.contentContainer}>
                <div onMouseMove={() => { setShowHideButton(false) }} style={{ width: '100%', height: '100%' }}>
                    <View style={{ width: '100%', height: '100%' }}>
                        {activeWatchList === -2 ? (
                            <InvestmentsScreen selectedId={selectedId} setActiveWatchlist={setActiveWatchlist} />
                        ) : null}
                        {activeWatchList === -1 ? (
                            <InvestorsScreen setActiveWatchlist={setActiveWatchlist} setSelectedId={setSelectedId} />
                        ) : null}
                        {activeWatchList >= 0 ? (
                            <View style={styles.watchListContainer}>
                                {openEditFavorites ? (
                                    <EditFavoritesList
                                        selectedId={activeWatchList}
                                        setOpenEditFavorites={setOpenEditFavorites}
                                    />
                                ) : (
                                    <WatchlistItemScreen watchListId={activeWatchList} setActiveChart={setActiveChart} activeChart={activeChart} setOpenEditFavorites={setOpenEditFavorites} />
                                )}
                            </View>
                        ) : null}
                    </View>
                </div>
                <View style={(hideRightColumn && activeRightTab === '') ? styles.chartContainerFull : styles.chartContainer}>
                    {bigChartScreen==true ? (
                        <div onMouseMove={() => { setShowHideButton(false) }} style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}>
                            <View style={{ width: '100%', height: '100%' }}>
                                {activeChart ?
                                    <BigChart chartSymbol={activeChart[0]} />
                                    : null}
                            </View>
                        </div> )
                    :
                    (
                        <>
                        {activeChart.length>0 && activeChart[0]!=null ? 
                        (<div onMouseMove={() => { setShowHideButton(false); } } style={{ width: '50%', height: '50%', position: 'absolute', top: 0, left: 0 }}>
                            <View style={{ width: '100%', height: '100%' }}>
                                {activeChart ?
                                    <BigChart chartSymbol={activeChart[0]} />
                                    : null}
                            </View>
                        </div>) : null}
                        {activeChart.length>1 && activeChart[1]!=null ? 
                        <div onMouseMove={() => { setShowHideButton(false); } } style={{ width: '50%', height: '50%', position: 'absolute', top: 0, left: '50%' }}>
                            <View style={{ width: '100%', height: '100%' }}>
                                {activeChart ?
                                    <BigChart chartSymbol={activeChart[1]} />
                                    : null}
                            </View>
                        </div> : null}
                        {activeChart.length>2 && activeChart[2]!=null ? 
                        (<div onMouseMove={() => { setShowHideButton(false); } } style={{ width: '50%', height: '50%', position: 'absolute', top: '50%', left: 0 }}>
                            <View style={{ width: '100%', height: '100%' }}>
                                {activeChart ?
                                    <BigChart chartSymbol={activeChart[2]} />
                                    : null}
                            </View>
                        </div>) : null}
                        {activeChart.length>3 && activeChart[3]!=null ? 
                        (<div onMouseMove={() => { setShowHideButton(false); } } style={{ width: '50%', height: '50%', position: 'absolute', top: '50%', left: '50%' }}>
                            <View style={{ width: '100%', height: '100%' }}>
                                {activeChart ?
                                    <BigChart chartSymbol={activeChart[3]} />
                                    : null}
                            </View>
                            </div>) : null}
                        </>) }
                </View>

                <div onMouseMove={() => { setShowHideButton(true) }}>
                    <View style={styles.rightContainer}>
                        {showHideButton ?
                            <Pressable style={styles.hideButtonContainer} onPress={() => { setActiveRightTab(''); setHideRightColumn(true) }}>
                                <AntDesign name={'caretright'} size={10} style={styles.hideButtonArrow} />
                                <Text fontType='LATO_REGULAR' style={styles.hideButtonText}>{'hide'}</Text>
                            </Pressable>
                            : null}
                        {activeRightTab === 'open-trades' ? <OpenTradesScreen /> : null}
                        {activeRightTab === 'all-orders' ? <AllOrdersScreen /> : null}
                        {activeRightTab === 'result' ? <ResultScreen /> : null}
                        {activeRightTab === 'cash' ? <CashScreen /> : null}
                        {activeRightTab === 'position-commission' ? <CommissionsScreen /> : null}
                        {activeRightTab === 'interest-swap' ? <InterestSwapScreen /> : null}
                        {activeRightTab === 'notifications-label' ? <NotificationsScreen /> : null}
                        {activeRightTab === 'bottom-tab-news' ? <NewsScreen /> : null}
                        {activeRightTab === 'more' ? <MoreScreen setActiveRightTab={setActiveRightTab} /> : null}
                        {activeRightTab === 'settings' ? <SettingsScreen setActiveRightTab={setActiveRightTab} /> : null}
                        {activeRightTab === 'accounts' ? <AccountsScreen setActiveRightTab={setActiveRightTab} /> : null}
                        {activeRightTab === 'withdraw' ? (
                            <WithdrawFundsScreen setActiveRightTab={setActiveRightTab} />
                        ) : null}
                        {activeRightTab === 'change-password' ? (
                            <ChangePasswordScreen setActiveRightTab={setActiveRightTab} />
                        ) : null}
                        {activeRightTab === 'notifications-settings' ? <NotificationsSettingsScreen /> : null}
                        {activeRightTab === 'add-money' ? (
                            <AddFundsScreen setActiveRightTab={setActiveRightTab} setCurrentAmount={setCurrentAmount} />
                        ) : null}
                        {activeRightTab === 'add-money-category' ? (
                            <AddFundsCategoryScreen
                                currentAmount={currentAmount}
                                setActiveRightTab={setActiveRightTab}
                                setProvider={setProvider}
                                setPaytikoPaymentProvider={setPaytikoPaymentProvider}
                            />
                        ) : null}
                        {activeRightTab === 'add-money-card' ? (
                            <AddFundsCardScreen
                                provider={provider}
                                paytikoPaymentProvider={paytikoPaymentProvider}
                                currentAmount={currentAmount}
                                setActiveRightTab={setActiveRightTab}
                            />
                        ) : null}
                        {activeRightTab === 'add-money-card-paytiko' ? (
                            <AddFundsCardPaytikoScreen
                                provider={provider}
                                setPaytikoPaymentProvider={setPaytikoPaymentProvider}
                                currentAmount={currentAmount}
                                setActiveRightTab={setActiveRightTab}
                            />
                        ) : null}
                        {activeRightTab === 'upload-documents' ? (
                            <UploadDocumentsScreen
                                setActiveRightTab={setActiveRightTab}
                            />
                        ) : null}
                        {activeRightTab === 'veryfication-center' ? (
                            <VerificationCenterScreen setActiveRightTab={setActiveRightTab} />
                        ) : null}
                        {activeRightTab === 'add-money-alternative-payment' ? (
                            <AddFundsAlternativePaymentScreen
                                provider={provider}
                                currentAmount={currentAmount}
                                setActiveRightTab={setActiveRightTab}
                            />
                        ) : null}
                        {activeRightTab === 'add-money-alternative' ? <AddFundsAlternativeScreen
                            currentAmount={currentAmount}
                            setActiveRightTab={setActiveRightTab}
                            setProvider={setProvider} /> : null}
                        {activeRightTab === 'add-money-bank' ? (
                            <AddFundsBankScreen setActiveRightTab={setActiveRightTab} currentAmount={currentAmount} />
                        ) : null}
                        {activeRightTab === 'client-report' ? (
                            <ClientReportScreen />
                        ) : null}
                        {activeRightTab === 'result-report' ? (
                            <ResultReportScreen />
                        ) : null}
                        {activeRightTab === 'swap-report' ? (
                            <SwapReportScreen />
                        ) : null}
                    </View>
                </div>
            </View>
            <View>
                <DesktopFooter />
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        hideButtonArrow: { color: theme.white },
        hideButtonText: { fontSize: FONT_SIZE_XS, color: theme.white },
        hideButtonContainer: {
            justifyContent: 'center', alignSelf: 'flex-end', alignItems: 'center', width: 35, height: 35, borderRadius: 5, backgroundColor: theme.background_message, opacity: 0.8,
            position: 'absolute', top: 0, right: 9, zIndex: 1000
        },
        editFavoritesContainer: { width: '100%', justifyContent: 'center', alignItems: 'center' },
        contentContainer: {
            height: 'calc(100% - 60px - 35px - 60px)',
            flexDirection: 'row',
            width: '100%',
        },
        watchListContainer: {
            width: 375,
            height: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        chartContainer: { width: 'calc(100% - 375px - 375px)', height: '100%' },
        chartContainerFull: { width: 'calc(100% - 375px)', height: '100%' },
        rightContainer: {
            width: 375,
            height: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        container: {
            flex: 1,
            width: '100%',
            justifyContent: 'center',
        },
    });
