import React, { useState, useEffect } from 'react';
import { View, StyleSheet, KeyboardAvoidingView, Platform } from 'react-native';

import { isDesktop, scaleSize } from '~/constants/scale';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useStyles } from '~/hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Theme } from '~/theme';
import { CustomerDataResponse } from '~/api-services/VerificationService/types';
import { Button, ErrorMessage, Switch, Text, TextInput } from '~/components/core';
import { FONT_SIZE_M, FONT_SIZE_S, FONT_SIZE_XS } from '~/constants';
import VerificationService from '~/api-services/VerificationService';
import i18n from '~/i18n';
import { Picker } from '@react-native-picker/picker';
import { countries as globalCountries } from '~/constants/global';
import Checkbox from '~/components/core/Checkbox';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';


type Props = {
    customerData: CustomerDataResponse;
    goToNextStep: Function;
    goToPrevStep: Function;
    setLoading: Function;
};

export default function InvestmentKnowledge({ customerData, goToNextStep, goToPrevStep, setLoading }: Props) {
    const styles = useStyles(stylesGenerator);

    const [educationFinancialServicesPast3Years, setEducationFinancialServicesPast3Years] = useState(false);
    const [depositLeverageQuestion, setDepositLeverageQuestion] = useState('');
    const [tradedUsingLeverageMagnifiesPotentialProfitsLossesText, setTradedUsingLeverageMagnifiesPotentialProfitsLossesText] = useState('');
    const [realDeliveryAsset, setRealDeliveryAsset] = useState('');
    const [automaticallyClosedPosition, setAutomaticallyClosedPosition] = useState('');
    const [whatHappenStopLossOrder, setWhatHappenStopLossOrder] = useState('');
    const [whatTypeOrder, setWhatTypeOrder] = useState('');//New
    const [iAmAware, setIAmAware] = useState(false);//New

    const [showDepositLeverageQuestionValidationMessage, setShowDepositLeverageQuestionValidationMessage] = useState(false);
    const [showTradedUsingLeverageMagnifiesPotentialProfitsLossesTextValidationMessage, setShowTradedUsingLeverageMagnifiesPotentialProfitsLossesTextValidationMessage] = useState(false);
    const [showRealDeliveryAssetValidationMessage, setShowRealDeliveryAssetValidationMessage] = useState(false);
    const [showAutomaticallyClosedPositionValidationMessage, setShowAutomaticallyClosedPositionValidationMessage] = useState(false);
    const [showWhatHappenStopLossOrderValidationMessage, setShowWhatHappenStopLossOrderValidationMessage] = useState(false);
    const [showWhatTypeOrderValidationMessage, setShowWhatTypeOrderValidationMessage] = useState(false);
    const [showIAmAwareValidationMessage, setShowIAmAwareValidationMessage] = useState(false);



    const [generalError, setGeneralError] = useState('');


    useEffect(() => {
        if (customerData) {
            if (customerData.educationFinancialServicesPast3Years) {
                setEducationFinancialServicesPast3Years(customerData.educationFinancialServicesPast3Years);
            }
            if (customerData.depositLeverageQuestion) {
                setDepositLeverageQuestion(customerData.depositLeverageQuestion);
            }
            if (customerData.tradedUsingLeverageMagnifiesPotentialProfitsLossesText) {
                setTradedUsingLeverageMagnifiesPotentialProfitsLossesText(customerData.tradedUsingLeverageMagnifiesPotentialProfitsLossesText);
            }
            if (customerData.realDeliveryAsset) {
                setRealDeliveryAsset(customerData.realDeliveryAsset);
            }
            if (customerData.automaticallyClosedPosition) {
                setAutomaticallyClosedPosition(customerData.automaticallyClosedPosition);
            }
            if (customerData.whatHappenStopLossOrder) {
                setWhatHappenStopLossOrder(customerData.whatHappenStopLossOrder);
            }
            if (customerData.whatTypeOrder) {
                setWhatTypeOrder(customerData.whatTypeOrder);
            }
            if (customerData.iAmAware) {
                setIAmAware(customerData.iAmAware);
            }
        }
    }, [customerData]);

    function validateForm(): boolean {
        let formValid: boolean = true;
        setGeneralError('');
        setShowDepositLeverageQuestionValidationMessage(false);
        setShowTradedUsingLeverageMagnifiesPotentialProfitsLossesTextValidationMessage(false);
        setShowRealDeliveryAssetValidationMessage(false);
        setShowAutomaticallyClosedPositionValidationMessage(false);
        setShowWhatHappenStopLossOrderValidationMessage(false);
        setShowWhatTypeOrderValidationMessage(false);
        setShowIAmAwareValidationMessage(false);

        if (depositLeverageQuestion === '') {
            formValid = false;
            setShowDepositLeverageQuestionValidationMessage(true);
        }
        if (tradedUsingLeverageMagnifiesPotentialProfitsLossesText === '') {
            formValid = false;
            setShowTradedUsingLeverageMagnifiesPotentialProfitsLossesTextValidationMessage(true);
        }

        if (realDeliveryAsset === '') {
            formValid = false;
            setShowRealDeliveryAssetValidationMessage(true);
        }
        if (automaticallyClosedPosition === '') {
            formValid = false;
            setShowAutomaticallyClosedPositionValidationMessage(true);
        }
        if (whatHappenStopLossOrder === '') {
            formValid = false;
            setShowWhatHappenStopLossOrderValidationMessage(true);
        }
        if (whatTypeOrder === '') {
            formValid = false;
            setShowWhatTypeOrderValidationMessage(true);
        }
        if (iAmAware === false) {
            formValid = false;
            setShowIAmAwareValidationMessage(true);
        }

        return formValid;
    }

    async function setInvestmentKnowledge() {
        if (validateForm()) {
            setLoading(true);
            const { status, data } = await VerificationService.setInvestmentKnowledge(
                {
                    educationFinancialServicesPast3Years: educationFinancialServicesPast3Years,
                    depositLeverageQuestion: depositLeverageQuestion,
                    tradedUsingLeverageMagnifiesPotentialProfitsLossesText: tradedUsingLeverageMagnifiesPotentialProfitsLossesText,
                    realDeliveryAsset: realDeliveryAsset,
                    automaticallyClosedPosition: automaticallyClosedPosition,
                    whatHappenStopLossOrder: whatHappenStopLossOrder,
                    whatTypeOrder: whatTypeOrder,
                    iAmAware: iAmAware
                }
            );
            if (status === 200) {
                if (data.status === true) {
                    goToNextStep();
                }
                else {
                    if (data.errorCode) {
                        if (data.errorCode === 'ArgumentRequiredException') {
                            if (data.errorContext && data.errorContext.field) {
                                if (data.errorContext.field) {
                                    setGeneralError(i18n.t('please-fill-all-required-fields') + ': ' + data.errorContext.field);
                                }
                                else {
                                    setGeneralError(data.errorCode);
                                }
                            }
                            else {
                                setGeneralError(data.errorCode);
                            }
                        }
                        else {
                            setGeneralError(data.errorCode);
                        }
                    }
                }
            }
            else {
                setGeneralError('Error');
            }
            setLoading(false);
        }
    }


    return (
        <View style={styles.safeAreaStyle}>
            <View style={styles.vcTitleContainer}>
                <Text fontType="LATO_BOLD" style={styles.vcTitleText}>
                    investment-knowledge
                </Text>
            </View>
            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                do-you-have-prior-education
            </Text>
            <View style={styles.yesNoContainer}>
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {'no'}
                </Text>
                <Switch
                    value={educationFinancialServicesPast3Years}
                    onValueChange={() => {
                        setEducationFinancialServicesPast3Years(!educationFinancialServicesPast3Years);
                    }}
                />
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {'yes'}
                </Text>
            </View>
            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                when-you-make-a-deposit
            </Text>
            <Picker
                style={styles.pickerStyle}
                itemStyle={styles.pickerRow}
                selectedValue={depositLeverageQuestion}
                onValueChange={(itemValue) => setDepositLeverageQuestion(itemValue)}
            >
                <Picker.Item
                    label={i18n.t('please-select-dots')}
                    value={''} />
                <Picker.Item
                    label={i18n.t('30 000 EUR')}
                    value={'30 000 EUR'} />
                <Picker.Item
                    label={i18n.t('300 EUR')}
                    value={'300 EUR'} />
                <Picker.Item
                    label={i18n.t('i-can-not-decide')}
                    value={'I can not decide'} />
            </Picker>
            <ErrorMessage
                text={'please-enter-value'}
                visible={depositLeverageQuestion === '' && showDepositLeverageQuestionValidationMessage} />

            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                cfd-are-traded
            </Text>
            <Picker
                style={styles.pickerStyle}
                itemStyle={styles.pickerRow}
                selectedValue={tradedUsingLeverageMagnifiesPotentialProfitsLossesText}
                onValueChange={(itemValue) => setTradedUsingLeverageMagnifiesPotentialProfitsLossesText(itemValue)}
            >
                <Picker.Item
                    label={i18n.t('please-select-dots')}
                    value={''} />
                <Picker.Item
                    label={i18n.t('true')}
                    value={'True'} />
                <Picker.Item
                    label={i18n.t('false')}
                    value={'False'} />
                <Picker.Item
                    label={i18n.t('i-can-not-decide')}
                    value={'I can not decide'} />
            </Picker>
            <ErrorMessage
                text={'please-enter-value'}
                visible={tradedUsingLeverageMagnifiesPotentialProfitsLossesText === '' && showTradedUsingLeverageMagnifiesPotentialProfitsLossesTextValidationMessage} />

            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                when-trading-CFDs
            </Text>
            <Picker
                style={styles.pickerStyle}
                itemStyle={styles.pickerRow}
                selectedValue={realDeliveryAsset}
                onValueChange={(itemValue) => setRealDeliveryAsset(itemValue)}
            >
                <Picker.Item
                    label={i18n.t('please-select-dots')}
                    value={''} />
                <Picker.Item
                    label={i18n.t('yes')}
                    value={'Yes'} />
                <Picker.Item
                    label={i18n.t('no')}
                    value={'No'} />
                <Picker.Item
                    label={i18n.t('i-can-not-decide')}
                    value={'I can not decide'} />
            </Picker>
            <ErrorMessage
                text={'please-enter-value'}
                visible={realDeliveryAsset === '' && showRealDeliveryAssetValidationMessage} />

            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                what-type-of-order
            </Text>
            <Picker
                style={styles.pickerStyle}
                itemStyle={styles.pickerRow}
                selectedValue={whatTypeOrder}
                onValueChange={(itemValue) => setWhatTypeOrder(itemValue)}
            >
                <Picker.Item
                    label={i18n.t('please-select-dots')}
                    value={''} />
                <Picker.Item
                    label={i18n.t('take-profit-order')}
                    value={'Take profit order'} />
                <Picker.Item
                    label={i18n.t('stop-loss-order')}
                    value={'Stop Loss order'} />
                <Picker.Item
                    label={i18n.t('there-is-no-such-order')}
                    value={'There is no such order'} />
                <Picker.Item
                    label={i18n.t('i-can-not-decide')}
                    value={'I can not decide'} />
            </Picker>
            <ErrorMessage
                text={'please-enter-value'}
                visible={whatTypeOrder === '' && showWhatTypeOrderValidationMessage} />

            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                my-open-position-may
            </Text>
            <Picker
                style={styles.pickerStyle}
                itemStyle={styles.pickerRow}
                selectedValue={automaticallyClosedPosition}
                onValueChange={(itemValue) => setAutomaticallyClosedPosition(itemValue)}
            >
                <Picker.Item
                    label={i18n.t('please-select-dots')}
                    value={''} />
                <Picker.Item
                    label={i18n.t('the-market-is-moving')}
                    value={'The market is moving against my position and I don`t have enough equity to meet the margin requirement'} />
                <Picker.Item
                    label={i18n.t('the-market-is-moving-in-favor-of-my-position')}
                    value={'The market is moving in favor of my position'} />
                <Picker.Item
                    label={i18n.t('i-can-not-decide')}
                    value={'I can not decide'} />
            </Picker>
            <ErrorMessage
                text={'please-enter-value'}
                visible={automaticallyClosedPosition === '' && showAutomaticallyClosedPositionValidationMessage} />

            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                when-you-place
            </Text>
            <Picker
                style={styles.pickerStyle}
                itemStyle={styles.pickerRow}
                selectedValue={whatHappenStopLossOrder}
                onValueChange={(itemValue) => setWhatHappenStopLossOrder(itemValue)}
            >
                <Picker.Item
                    label={i18n.t('please-select-dots')}
                    value={''} />
                <Picker.Item
                    label={i18n.t('my-position-will-close')}
                    value={'My position will close at the next best available price if the Stop Loss is triggered.'} />
                <Picker.Item
                    label={i18n.t('a-stop-loss-order-cannot-close-my-position')}
                    value={'A Stop Loss order cannot close my position'} />
                <Picker.Item
                    label={i18n.t('i-can-not-decide')}
                    value={'I can not decide'} />
            </Picker>
            <ErrorMessage
                text={'please-enter-value'}
                visible={whatHappenStopLossOrder === '' && showWhatHappenStopLossOrderValidationMessage} />
            <View style={styles.checkboxContainer}>
                <Checkbox
                    checked={iAmAware}
                    setChecked={setIAmAware} />
                <Text fontType="LATO_REGULAR" style={[styles.labelText, { paddingLeft: scaleSize(10) }]}>
                    i-am-aware
                </Text>
            </View>
            <ErrorMessage
                text={generalError}
                visible={generalError !== ''}
            />
            <View style={styles.buttonsContainer}>
                <View style={styles.buttonContainer} />
                <View style={styles.buttonContainer}>
                    <Button
                        onPress={() => setInvestmentKnowledge()}
                        title="next"
                    />
                </View>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        safeAreaStyle: { flex: 1, paddingHorizontal: scaleSize(Platform.OS !== 'web' ? 0 : 12), },
        checkboxContainer: { paddingRight: scaleSize(10), flexDirection: 'row', alignItems: 'center' },
        backButtonStyle: { backgroundColor: theme.tabIconDefault },
        yesNoContainer: { flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: '25%' },
        buttonsContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingVertical: scaleSize(20),
        },
        buttonContainer: {
            width: scaleSize(150),
            height: scaleSize(40),
        },
        pickerRow: {
            width: '100%',
            height: Platform.OS !== 'ios' ? scaleSize(40) : 3 * scaleSize(40),
            fontSize: scaleSize(FONT_SIZE_S),
            color: theme.text
        },
        pickerStyle: {
            width: '100%',
            height: Platform.OS !== 'ios' ? scaleSize(40) : 3 * scaleSize(40),
            fontSize: scaleSize(FONT_SIZE_M),
            backgroundColor: theme.backgroundSecondary,
            color: theme.text_pendingOrder,
            borderRadius: scaleSize(5),
            padding: 0,
            marginBottom: scaleSize(10)
        },
        textInputStyle: {
            fontSize: scaleSize(FONT_SIZE_M),
            color: theme.text_pendingOrder,
            width: '100%'
        },
        labelText: { fontSize: FONT_SIZE_XS, color: theme.text_symbol, paddingVertical: scaleSize(10) },
        bdateContainer: { flexDirection: 'row', justifyContent: 'space-between' },
        bdatePickerContainer: { width: '31%' },
        vcTitleContainer: {
            justifyContent: 'center',
            paddingVertical: scaleSize(20),
        },
        vcTitleText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        inputContainer: {
            marginBottom: scaleSize(10),
        },
        keyBoard: { width: '100%' },
        viewContainer: { justifyContent: 'space-between', height: '80%', paddingBottom: scaleSize(10) },
        scrollView: { maxHeight: '100%', height: '100%' },
        container: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingHorizontal: scaleSize(17),
            height: '100%',
            width: '100%',
        },
    });
