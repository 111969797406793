import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import type { Props } from './types';
import { useAppSelector } from '~/hooks';
import { createGlobalStyle } from 'styled-components';
import { appSettingsSelector } from '~/state/selectors';


export default function ({ value, onChange }: Props) {
        const { colorScheme } = useAppSelector(appSettingsSelector);
    

const DatePickerWrapperStylesDarkTheme = createGlobalStyle`
    .react-datetime-picker__inputGroup__input {
    color: white;
    }
`;
const DatePickerWrapperStylesWhiteTheme = createGlobalStyle`
    .react-datetime-picker__inputGroup__input {
    color: black;
    }
`;
    return (
        <><DateTimePicker
            disableClock
            value={value}
            onChange={onChange}
            format={'dd.MM.y'}
            className='react-datetime-picker__inputGroup__input' />
        {colorScheme === 'dark' ? 
            <DatePickerWrapperStylesDarkTheme />
         : 
         <DatePickerWrapperStylesWhiteTheme />
         }
        </>
    );
}