import React, { useEffect } from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import MarketWatchlist from '../features/Market/MarketWatchlist';
import { loadSymbols } from '~/state/reducers/symbolsSlice';
import { watchlistInstrumentsSelector, favoritesDataSelector } from '~/state/selectors';
import { useAppDispatch, useAppSelector } from '~/hooks';

type Props = {
    watchListId?: number;
    setActiveChart?: Function;
    activeChart?: string[];
    setOpenEditFavorites?: Function;
};

export default function WatchlistItemScreen({ watchListId, setActiveChart, activeChart, setOpenEditFavorites }: Props) {
    const favorites = useAppSelector(favoritesDataSelector);
    const { missingSymbols } = useAppSelector((state) => watchlistInstrumentsSelector(state, favorites.id));
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (missingSymbols.length) {
            dispatch(loadSymbols(missingSymbols));
        }
    }, [dispatch, missingSymbols]);
    return (
        <ScreenWrapper>
            <MarketWatchlist watchListId={watchListId} setActiveChart={setActiveChart} activeChart={activeChart} setOpenEditFavorites={setOpenEditFavorites}/>
        </ScreenWrapper>
    );
}
