import React, { useEffect, useState } from 'react';
import { FlatList, StyleSheet, Pressable, View, Platform } from 'react-native';
import { Text } from '~/components/core';
import { useAppDispatch, useAppSelector, useStyles } from '~/hooks';
import { allSymbolNamesSelector, watchlistByIdSelector } from '~/state/selectors';
import { loadAllSymbolNames } from '~/state/reducers/allSymbolNamesSlice';
import { Feather } from "~/components/core/Icons";
import { FONT_SIZE_S } from '~/constants';
import { saveWatchlist } from '~/state/reducers/watchlistSlice';
import { symbolName } from '~/api-services/CoreDataService/types';
import { Theme } from '~/theme';
import { isDesktop, scaleSize } from '~/constants/scale';
import { loadSymbols } from '~/state/reducers/symbolsSlice';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';

const RenderItem = ({
    item,
    watchlistData,
    saveFavorites,
    styles,
}: {
    item: symbolName;
    watchlistData: string[];
    saveFavorites: Function;
    styles: ReturnType<typeof stylesGenerator>;
}) => {
    const dispatch = useAppDispatch();

    async function saveFavoritesFunction() {
        if (!watchlistData.find((symbol) => symbol === item.symbol)) {
            await dispatch(loadSymbols([item.symbol]));
        }
        watchlistData.find((symbol) => symbol === item.symbol)
            ? saveFavorites(watchlistData.filter((symbol) => symbol !== item.symbol))
            : saveFavorites([item.symbol].concat(watchlistData));
    }

    return (
        <View style={[styles.container]}>
            <Pressable
                onPress={() => {
                    saveFavoritesFunction();
                }}
            >
                <View style={[styles.row, styles.rowContainer, styles.listItem]}>
                    <View style={styles.row}>

                        {watchlistData.find((symbol) => symbol === item.symbol) ? (
                            <View style={styles.deleteIcon}>
                                <Feather name={'plus'} size={scaleSize(14)} style={styles.iconButtonRemove} />
                            </View>
                        ) : (
                            <View style={styles.addIcon}>
                                <Feather name={'plus'} size={scaleSize(14)} style={styles.iconButtonRemove} />
                            </View>
                        )}

                    </View>
                    <View>
                        <Text fontType="LATO_BOLD" style={styles.instrumentName}>
                            {item.nameTranslated}
                        </Text>
                        <Text fontType="LATO_REGULAR" style={styles.descriptionText}>
                            {item.descriptionTranslated}
                        </Text>
                    </View>
                </View>
            </Pressable>

        </View >
    );
};

type PropsAF = {
    watchListId: number;
    searchTerm: string;
};

export default function AddFavoritesListSearch({ watchListId, searchTerm }: PropsAF) {
    const styles = useStyles(stylesGenerator);
    const dispatch = useAppDispatch();
    const allSymbolNames = useAppSelector((state) => allSymbolNamesSelector(state));
    const watchlist = useAppSelector((state) => watchlistByIdSelector(state, watchListId));
    const [instrumentsData, setInstrumentsData] = useState<symbolName[]>([]);
    const [watchlistData, setWatchlistData] = useState(watchlist?.instrumentCodes);

    useEffect(() => {
        if (allSymbolNames.length === 0) {
            dispatch(loadAllSymbolNames());
        }
    }, [dispatch, allSymbolNames]);

    useEffect(() => {
        setInstrumentsData(
            allSymbolNames.filter(
                (instrument) =>
                    (
                        watchlist.code === 'FAVORITES'
                        || (watchlist.code === 'FOREX' && instrument.copyTradingType === 'Currencies')
                        || (watchlist.code === 'INDEXES' && (instrument.copyTradingType === 'Indexes' || instrument.copyTradingType === 'ETFs'))
                        || (watchlist.code === 'SHARES' && instrument.copyTradingType === 'Stocks')
                        || ((watchlist.code === 'CRYPTOS' || watchlist.code === 'CRYPTO') && instrument.copyTradingType === 'CryptoCurrencies')
                        || (watchlist.code === 'COMMODITIES' && instrument.copyTradingType === 'Commodities')

                    ) &&
                    (instrument.symbol.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 ||
                        instrument.nameTranslated.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 ||
                        instrument.descriptionTranslated.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 ||
                        (searchTerm === '' && instrument.symbol !== ''))
            )
        );
    }, [searchTerm, allSymbolNames, setInstrumentsData]);

    if (!watchlist || !watchlistData) {
        return null;
    }

    function saveFavorites(data: string[]) {
        setWatchlistData(data);
        if (watchlist !== undefined) {
            dispatch(
                saveWatchlist({
                    ...watchlist,
                    instrumentCodes: data.map((symbol) => symbol),
                })
            );
        }
    }

    if (Platform.OS === 'web' && isDesktop()) {
        return (
            <ScrollbarWrapper>
                <FlatList
                    style={styles.list}
                    data={instrumentsData}
                    keyExtractor={(item) => item.symbol}
                    renderItem={(item) => (
                        <RenderItem
                            item={item.item}
                            watchlistData={watchlistData}
                            saveFavorites={saveFavorites}
                            styles={styles}
                        />
                    )}
                />
            </ScrollbarWrapper>
        );
    } else {
        return (
            <>
                <FlatList
                    style={styles.list}
                    data={instrumentsData}
                    keyExtractor={(item) => item.symbol}
                    renderItem={(item) => (
                        <RenderItem
                            item={item.item}
                            watchlistData={watchlistData}
                            saveFavorites={saveFavorites}
                            styles={styles} />
                    )} />
            </>
        );
    }
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        instrumentName: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        deleteIcon: {
            alignItems: 'center',
            marginLeft: scaleSize(10),
            marginRight: scaleSize(10),
            transform: [{ rotate: '-45deg' }],
            justifyContent: 'center',
            width: scaleSize(22),
            height: scaleSize(22),
            backgroundColor: theme.text_downPrice,
            borderRadius: scaleSize(11),
        },
        addIcon: {
            alignItems: 'center',
            marginLeft: scaleSize(10),
            marginRight: scaleSize(10),
            justifyContent: 'center',
            width: scaleSize(22),
            height: scaleSize(22),
            backgroundColor: theme.text_upPrice,
            borderRadius: scaleSize(11),
        },
        iconButtonRemove: {
            color: theme.white,
        },
        list: {
            backgroundColor: theme.background,
        },
        listItem: {
            backgroundColor: theme.backgroundSecondary,
        },
        container: {
            height: scaleSize(40),
            width: '100%',
            flex: 1,
            justifyContent: 'center',
        },
        descriptionText: {
            fontSize: FONT_SIZE_S,
            color: theme.text_pendingOrder,
        },
        row: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        rowContainer: {
            padding: scaleSize(5),
            borderRadius: scaleSize(5),
            marginTop: scaleSize(5),
            marginHorizontal: scaleSize(5),
        },
    });
