import React, { useState } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { Text } from '~/components/core';
import { DataSymbol, Position } from '~/api-services/LoadService/types';
import { useAppSelector, useStyles } from '~/hooks';
import { FONT_SIZE_XS, FONT_SIZE_S } from '~/constants';
import { Foundation } from "~/components/core/Icons";
import { isDesktop, scaleSize } from '~/constants/scale';
import { Theme } from '~/theme';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import { formatCurrency, formatNumber } from '~/lib/format';
import { appSettingsSelector } from '~/state/selectors';
import EditOpenPosition from '../EditOpenPosition';

type Props = {
    position: Position;
    dataSymbol: DataSymbol;
    currency: string;
    lastItem: boolean;
    investorUid: null | string;
};

export default function PositionItem({ position, dataSymbol, currency, lastItem, investorUid }: Props) {
    const styles = useStyles(stylesGenerator);
    const { colorScheme } = useAppSelector(appSettingsSelector);
    const [openEditPositionId, setOpenEditPositionId] = useState('');
    return (
        <>
            {investorUid === position.investorUid ?
            <Pressable
                onPress={() =>
                    position.pending
                        ? isDesktop()
                            ? setOpenEditPositionId(openEditPositionId !== '' ? '' : position.id)
                            : linkTo(paths.editPendingOrder.replace(':id', position.id))
                        : isDesktop()
                        ? setOpenEditPositionId(openEditPositionId !== '' ? '' : position.id)
                        : linkTo(paths.editOpenPosition.replace(':id', position.id))
                }
                key={position.id}
                style={[
                    styles.positionItemContainer,
                    lastItem ? styles.positionItemContainerLast : null,
                    colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
                ]}
            >
                <View
                    style={[
                        styles.positionItemSide,
                        colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
                    ]}
                >
                    <View style={[styles.rotateTextWrapper, styles.buySell]}>
                        <Text fontType="LATO_BLACK" style={position.side === 'buy' ? styles.buyText : styles.sellText}>
                            {position.side.toUpperCase()}
                        </Text>
                    </View>
                </View>
                <View
                    style={[
                        styles.positionItemPending,
                        position.limitProfit || position.stopLoss ? styles.positionItemPendingBorder : null,
                        colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
                    ]}
                >
                    {position.limitProfit ? (
                        <View
                            style={[
                                position.stopLoss ? styles.takeProfitBorder : null,
                                colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
                                styles.tpSlContainer,
                            ]}
                        >
                            <View
                                style={[
                                    styles.rotateTextWrapper,
                                    styles.pendingOrderContainer,
                                    colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
                                ]}
                            >
                                <Text fontType="LATO_BLACK" style={styles.pendingOrderText}>
                                    TP
                                </Text>
                            </View>
                        </View>
                    ) : null}

                    {position.stopLoss ? (
                        <View style={styles.tpSlContainer}>
                            <View style={[styles.rotateTextWrapper, styles.pendingOrderContainer]}>
                                <Text fontType="LATO_BLACK" style={styles.pendingOrderText}>
                                    SL
                                </Text>
                            </View>
                        </View>
                    ) : null}
                </View>
                <View style={styles.positionItemFreeSpace}>
                    <View style={styles.positionItemVolume}>
                        <View style={styles.volumeBackground}>
                            {position.volume > 0 ? (
                                <Text fontType="LATO_BOLD" style={styles.volumeText}>
                                    {position.side === 'buy' ? '+' : '-'}
                                </Text>
                            ) : null}
                            <Text fontType="LATO_BOLD" style={styles.volumeText}>
                                {formatNumber(
                                    position.volume,
                                    dataSymbol.volumePrecision ? dataSymbol.volumePrecision : 0
                                )}
                            </Text>
                        </View>
                        <View style={styles.atSeparatorMargin}>
                            <Text style={styles.atSeparatorText}>@</Text>
                        </View>
                        <View style={styles.positionItemPrice}>
                            <Text style={[styles.positionItemPriceText, styles.positionItemPriceRight]}>
                                {formatNumber(position.openPrice, dataSymbol.digits)}
                            </Text>
                            <Foundation name={'arrow-right'} size={scaleSize(10)} style={styles.text_at} />

                            <Text style={[styles.positionItemPriceText, styles.positionItemPriceLeft]}>
                                {position.side === 'buy'
                                    ? formatNumber(dataSymbol.price.bid, dataSymbol.digits)
                                    : formatNumber(dataSymbol.price.ask, dataSymbol.digits)}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.positionItemResult}>
                        {position.pending ? (
                            <Text fontType="LATO_ITALIC" style={[styles.pendingTextColor]}>
                                Pending
                            </Text>
                        ) : (
                            <Text
                                fontType="LATO_BOLD"
                                style={[
                                    styles.positionResultText,
                                    position.result >= 0
                                        ? styles.positionResultPositive
                                        : styles.positionResultNegative,
                                ]}
                            >
                                {formatCurrency(position.result, 2, currency)}
                            </Text>
                        )}
                    </View>
                </View>
            </Pressable> : null}
            {openEditPositionId ? <EditOpenPosition selectedId={openEditPositionId} /> : null}
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        borderColorDark: { borderColor: theme.background },
        tpSlContainer: { width: '100%', height: '50%', justifyContent: 'center', alignItems: 'center' },
        borderColorWhite: { borderColor: theme.border_2 },
        positionItemContainer: {
            flexDirection: 'row',
            width: '100%',
            height: scaleSize(50),
            justifyContent: 'center',
            backgroundColor: theme.backgroundSecondary,
            borderBottomWidth: 1,
            borderRightWidth: 1,
            borderLeftWidth: 1,
        },
        positionItemPriceRight: { paddingRight: scaleSize(7) },
        positionItemPriceLeft: { paddingLeft: scaleSize(7) },
        positionItemContainerLast: {
            width: '100%',
            justifyContent: 'center',
            height: scaleSize(50),
            borderBottomLeftRadius: scaleSize(5),
            borderBottomRightRadius: scaleSize(5),
        },
        pendingTextColor: {
            color: theme.text_pendingOrder,
            fontSize: FONT_SIZE_S,
        },
        positionResultPositive: {
            color: theme.text_upPrice,
        },
        positionResultNegative: {
            color: theme.text_downPrice,
        },
        buyText: {
            fontSize: FONT_SIZE_S,
            color: theme.text_upPrice,
        },
        sellText: {
            fontSize: FONT_SIZE_S,
            color: theme.text_downPrice,
        },
        withBorderColor: {
            borderColor: theme.border_2,
        },
        rotateTextWrapper: { transform: [{ rotate: '-90deg' }] },
        buySell: { width: scaleSize(50), alignItems: 'center' },
        positionItemSide: {
            width: '5%',
            borderRightWidth: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        positionItemPending: { width: '5%', justifyContent: 'center', alignItems: 'center' },
        positionItemPendingBorder: { borderRightWidth: 1 },
        positionItemVolume: { flexDirection: 'row', alignItems: 'center', paddingLeft: '1%' },
        positionItemPrice: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
        positionItemResult: { justifyContent: 'center', alignItems: 'flex-end', paddingRight: '2%' },
        positionItemPriceText: { fontSize: FONT_SIZE_S, color: theme.text_pendingOrder },
        volumeBackground: {
            backgroundColor: theme.background_message,
            borderRadius: scaleSize(2),
            flexDirection: 'row',
            paddingHorizontal: scaleSize(5),
            paddingVertical: scaleSize(3),
        },
        atSeparatorMargin: { marginHorizontal: '2%' },
        text_at: { color: theme.text_at },
        atSeparatorText: { color: theme.text_at, fontSize: FONT_SIZE_S },
        volumeText: { color: theme.white, fontSize: FONT_SIZE_XS, letterSpacing: 0.5 },
        pendingOrderContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            height: '100%',
            width: '100%',
        },
        pendingOrderText: { fontSize: FONT_SIZE_S, color: theme.text_at },
        positionItemFreeSpace: { width: '90%', flexDirection: 'row', justifyContent: 'space-between' },
        positionResultText: { fontSize: FONT_SIZE_S, letterSpacing: 0.5 },
        takeProfitBorder: { borderBottomWidth: 1 },
    });
