import { useEffect, useState } from 'react';
import CustomerInfoService from '~/api-services/CustomerInfoService';
import {BillingField, CustomerInfo, GetDirectCardDataResponse} from '~/api-services/CustomerInfoService/types';

export const useDirectCardData = (provider: string, selectedPaytikoPaymentProvider: number) => {
    const [directCardData, setDirectCardData] = useState<GetDirectCardDataResponse>();
    const [billingFields, setBillingFields] = useState<BillingField[]>([]);

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        switch (provider) {
            case 'PAYTIKODIRECTCARD':
                {
                    const { status, data } =  await CustomerInfoService.getDirectCardDataPaytiko(selectedPaytikoPaymentProvider);
                    if (status === 200 && data.status) {
                        setDirectCardData(data.returnData);
                        setBillingFields(data.returnData.billingFields);
                    }
                }
                break;
            case 'ARENAMONEYDIRECTCARD':
                {
                    const {status, data} = await CustomerInfoService.getDirectCardDataArenaMoney(selectedPaytikoPaymentProvider);
                    if (status === 200 && data.status) {
                        setDirectCardData(data.returnData);
                        setBillingFields(data.returnData.billingFields);
                    }
                }
                break;
            default:
                setDirectCardData(undefined);
        }
    }

    return {
        directCardData,
        billingFields,
        setBillingFields
    };
};
