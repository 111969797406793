import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import {
    View,
    StyleSheet,
    KeyboardAvoidingView,
    Pressable,
    Linking,
    SafeAreaView,
    Image,
    Platform,
} from 'react-native';
import { PaymentConfig } from '~/api-services/PaymentConverterService/types';
import { Button, ErrorMessage, Text, TextInput } from '~/components/core';
import { FONT_FAMILY_BOLD, FONT_SIZE_L, FONT_SIZE_S, FONT_SIZE_XS } from '~/constants';
import { isDesktop, scaleSize } from '~/constants/scale';
import { useAppSelector, useStyles } from '~/hooks';
import { useAddFunds } from '~/hooks/useAddFunds';
import { useRates } from '~/hooks/useRates';
import i18n from '~/i18n';
import { formatNumber } from '~/lib/format';
import { accountBalanceSelector } from '~/state/selectors';
import { Theme } from '~/theme';
import Header from '../More/Header';
import CopyRight from '../WithdrawFunds/CopyRight';
import { Picker } from '@react-native-picker/picker';
import { FontAwesome } from "~/components/core/Icons";
import config from '~/config';
import Checkbox from '~/components/core/Checkbox';
import { useCustomerInfo } from '~/hooks/useCustomerInfo';
import { auStates, caStates, countries, usState } from '~/constants/global';
import Loader from '~/components/core/Loader';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import DesktopScreenHeader from '../Desktop/DesktopScreenHeader';
import OrderConvertService from '~/api-services/OrderConvertService';
import { TrustPaymentsIdeal } from '~/api-services/OrderConvertService/types';


type Props = {
    provider?: string;
    currentAmount?: string;
    setActiveRightTab?: Function;
};

export default function AddFundsAlternativePayment({ provider, currentAmount, setActiveRightTab }: Props) {
    const route = useRoute();
    const selectedProvider: string = provider ? provider : (route.params as any).provider;
    const { ratesAndCurrencies } = useRates('ALTERNATIVE', selectedProvider);
    const { customerInfo } = useCustomerInfo(selectedProvider);
    const [alternativePaymentConfigData, setAlternativePaymentConfigData] = useState<PaymentConfig | undefined>(undefined);
    const styles = useStyles(stylesGenerator);
    const currency = useAppSelector(accountBalanceSelector)?.currency || '';
    const [amount, setAmount] = useState(currentAmount ? currentAmount : (route.params as any).amount);
    const { configData } = useAddFunds(amount != '' ? Number(amount) : 0);
    const [selectedCurrencyIndex, setSelectedCurrencyIndex] = useState(0);
    const [showPersonalData, setShowPersonalData] = useState(true);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [state, setState] = useState('');

    const [country, setCountry] = useState('');
    const [confirm, setConfirm] = useState(false);
    const [showAmountValidationMessage, setShowAmountValidationMessage] = useState(false);
    const [paymentResponseErrorMessage, setPaymentResponseErrorMessage] = useState('');
    const [showMinAmountValidationMessage, setShowMinAmountValidationMessage] = useState(false);
    const [showMaxAmountValidationMessage, setShowMaxAmountValidationMessage] = useState(false);
    const [showCountryValidationMessage, setShowCountryValidationMessage] = useState(false);
    const [showFirstNameValidationMessage, setShowFirstNameValidationMessage] = useState(false);
    const [showLastNameValidationMessage, setShowLastNameValidationMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alternativePaymentStep2, setAlternativePaymentStep2] = useState(false);
    const [protocol, setProtocol] = useState('');
    const { WebView } = Platform.OS === 'web' ? require('react-native-web-webview') : require('react-native-webview');
    const [trustPaymentsIdealResponse, setTrustPaymentsIdealResponse] = useState<TrustPaymentsIdeal>(null);


    useEffect(() => {
        if (protocol === '') {
            Linking.getInitialURL().then((url) => {
                if (url && url.indexOf('https') === 0) {
                    setProtocol('https');
                } else {
                    setProtocol('http');
                }
            });
        }
    }, [protocol]);

    useEffect(() => {
        if (customerInfo !== undefined) {
            setFirstName(customerInfo.firstName);
            setLastName(customerInfo.lastName);
            setCountry(customerInfo.country);
            setState(customerInfo.state);
            console.log(customerInfo.firstName)
            if (customerInfo.firstName === '' ||
                !customerInfo.firstName ||
                customerInfo.lastName === '' ||
                !customerInfo.lastName ||
                customerInfo.country === '' ||
                !customerInfo.country ||
                (customerInfo.state === '' &&
                    (customerInfo.country === 'US' ||
                        customerInfo.country === 'CA' ||
                        customerInfo.country === 'AU'))) {
                setShowPersonalData(true);
            } else {
                setShowPersonalData(false);
            }

        } else {
            setShowPersonalData(true);
        }
    }, [customerInfo, selectedProvider]);

    useEffect(() => {
        if (alternativePaymentConfigData === undefined && configData && configData.alternative) {
            for (let i = 0; i < configData.alternative.length; i++) {
                let paymentConfig: PaymentConfig = configData.alternative[i];
                if (paymentConfig.provider === selectedProvider) {
                    setAlternativePaymentConfigData(paymentConfig);
                    setLoading(false);
                }
            }
        }
    }, [alternativePaymentConfigData, configData, selectedProvider]);

    useEffect(() => {
        if (ratesAndCurrencies) {
            for (let i = 0; i < ratesAndCurrencies.rates.length; i++) {
                if (ratesAndCurrencies.rates[i].currency === currency) {
                    setSelectedCurrencyIndex(i);
                    break;
                }
            }
        }
    }, [ratesAndCurrencies]);

    function onSelchangeCurrency(selectedCurrency: string, currencyIndex: number) {
        setSelectedCurrencyIndex(currencyIndex);
    }

    function validateAmount(vAmount: string): boolean {
        if (vAmount === '') {
            setShowAmountValidationMessage(true);
            return false;
        } else {
            setShowAmountValidationMessage(false);
            if (alternativePaymentConfigData) {
                if (Number(vAmount) < alternativePaymentConfigData.minAmount) {
                    setShowMinAmountValidationMessage(true);
                    return false;
                } else {
                    setShowMinAmountValidationMessage(false);
                }
                if (Number(vAmount) > alternativePaymentConfigData.maxAmount) {
                    setShowMaxAmountValidationMessage(true);
                    return false;
                } else {
                    setShowMaxAmountValidationMessage(false);
                }
            }
        }
        return true;
    }

    function validateForm(): boolean {
        let formValid: boolean = true;
        if (firstName === '') {
            formValid = false;
            setShowPersonalData(true);
            setShowFirstNameValidationMessage(true);
        } else {
            setShowFirstNameValidationMessage(false);
        }
        if (lastName === '') {
            formValid = false;
            setShowPersonalData(true);
            setShowLastNameValidationMessage(true);
        } else {
            setShowLastNameValidationMessage(false);
        }
        if (country === '') {
            formValid = false;
            setShowPersonalData(true);
            setShowCountryValidationMessage(true);
        } else {
            setShowCountryValidationMessage(false);
        }
        if (ratesAndCurrencies === undefined) {
            formValid = false;
        }
        if (!validateAmount(amount)) {
            formValid = false;
        }
        return formValid;
    }

    async function payDeposit() {
        setPaymentResponseErrorMessage('');
        if (validateForm()) {
            setLoading(true);
            try {
                console.log(selectedProvider);
                if (selectedProvider == 'TRUSTPAYMENTSIDEAL') {
                    const { status, data } = await OrderConvertService.createOrderIdeal({
                        convertId: ratesAndCurrencies ? ratesAndCurrencies.convertId : '',
                        customer: {
                            country: country,
                            firstName: firstName,
                            lastName: lastName,
                            state: state,
                        },
                        depositAmount: Number(amount),
                        depositCurrency: currency,
                        payAmount: ratesAndCurrencies
                            ? ratesAndCurrencies.rates[selectedCurrencyIndex].rate * amount
                            : 0,
                        payCurrency: ratesAndCurrencies ? ratesAndCurrencies.rates[selectedCurrencyIndex].currency : '',
                    });
                    setLoading(false);
                    if (status === 200 && data.status) {
                        let response: TrustPaymentsIdeal = data.returnData;
                        setTrustPaymentsIdealResponse(response);
                        setAlternativePaymentStep2(true);
                        console.log(response);

                    } else {
                        setPaymentResponseErrorMessage('Error');
                    }
                }
                else {
                    setLoading(false);
                }
            } catch (error) {
                setPaymentResponseErrorMessage((error as Error).message || 'Error');
                setLoading(false);
            }
        } else {
            setPaymentResponseErrorMessage('please-fill-all-required-fields');
        }
    }

    function enableDepositButton() {
        if (!confirm) {
            return false;
        }
        return true;
    }

    function onChangeCountry(text: string) {
        setCountry(text);
        if (text === '') {
            setShowCountryValidationMessage(true);
        } else {
            setShowCountryValidationMessage(false);
        }
    }

    function onChangeFirstName(text: string) {
        setFirstName(text);
        if (text === '') {
            setShowFirstNameValidationMessage(true);
        } else {
            setShowFirstNameValidationMessage(false);
        }
    }

    function onChangeLastName(text: string) {
        setLastName(text);
        if (text === '') {
            setShowLastNameValidationMessage(true);
        } else {
            setShowLastNameValidationMessage(false);
        }
    }


    function checkConfirm() {
        setConfirm(!confirm);
    }
    function usePersonalData() {
        setShowPersonalData(!showPersonalData);
    }


    return (
        <SafeAreaView style={styles.safeArea}>
            <Loader loading={loading} />
            {!isDesktop() ? <Header /> : null}
            <DesktopScreenHeader
                goToScreenName="add-money-category"
                title="add-money"
                setActiveRightTab={setActiveRightTab}
            />
            {!alternativePaymentStep2 ? (
                <><View style={styles.titleContainer}>
                    <Text fontType="LATO_BOLD" style={styles.addFundsTitleText}>
                        {'card-payment-title-alternative'}
                    </Text>
                    <Text fontType="LATO_BOLD" style={styles.addFundsTitleText}>
                        {selectedProvider}
                    </Text>
                </View><View style={styles.logoContainer}>
                        <Image
                            source={{
                                uri: 'https://' +
                                    config.EXTERNAL_SOURCES_URL +
                                    'mobileplatform/images/payments/' +
                                    selectedProvider + '.png',
                            }}
                            style={[styles.logoStyle]} />
                    </View><ScrollbarWrapper style={styles.scrollView}>
                        <View style={styles.container}>

                            <View style={styles.viewContainer}>
                                <KeyboardAvoidingView behavior={'padding'} style={styles.scrollView}>
                                    <View style={styles.content}>
                                        {alternativePaymentConfigData ? (
                                            <View>

                                                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                    {i18n.t('deposit-amount') + ' ( ' + currency + ' )'}
                                                </Text>
                                                <View style={styles.depositAmountContainer}>
                                                    <View style={styles.depositAmountFieldContainer}>
                                                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                            {formatNumber(amount, 2) + ' ' + currency}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.depositAmountMinMaxContainer}>
                                                        <Text fontType="LATO_REGULAR" style={styles.minMaxText}>
                                                            {i18n.t('deposit-min') +
                                                                ' ' +
                                                                formatNumber(alternativePaymentConfigData.minAmount, 2) +
                                                                ' ' +
                                                                currency}
                                                        </Text>
                                                        <Text fontType="LATO_REGULAR" style={styles.minMaxText}>
                                                            {i18n.t('deposit-max') +
                                                                ' ' +
                                                                formatNumber(alternativePaymentConfigData.maxAmount, 2) +
                                                                ' ' +
                                                                currency}
                                                        </Text>
                                                    </View>
                                                </View>
                                                <ErrorMessage
                                                    text={'missing-amount-error'}
                                                    visible={showAmountValidationMessage} />
                                                <ErrorMessage
                                                    text={'amount-max-error'}
                                                    visible={showMaxAmountValidationMessage} />
                                                <ErrorMessage
                                                    text={'amount-min-error'}
                                                    visible={showMinAmountValidationMessage} />
                                                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                    {'currency-convertor'}
                                                </Text>
                                                {ratesAndCurrencies ? (
                                                    <View style={styles.currencyConvertorContainer}>
                                                        <View style={styles.currencyContainer}>
                                                            <Picker
                                                                style={styles.pickerStyle}
                                                                itemStyle={styles.pickerRow}
                                                                selectedValue={ratesAndCurrencies.rates[selectedCurrencyIndex].currency}
                                                                onValueChange={(itemValue, itemIndex) => onSelchangeCurrency(itemValue, itemIndex)}
                                                            >
                                                                {ratesAndCurrencies.rates.map((rate, index) => (
                                                                    <Picker.Item
                                                                        label={rate.currency}
                                                                        value={rate.currency}
                                                                        key={'CurrencyPicker' + index} />
                                                                ))}
                                                            </Picker>
                                                        </View>
                                                        <View style={styles.currencyConvertorValueContainer}>
                                                            <Text fontType="LATO_REGULAR" style={styles.minMaxText}>
                                                                {formatNumber(
                                                                    ratesAndCurrencies.rates[selectedCurrencyIndex].rate *
                                                                    amount,
                                                                    2
                                                                )}
                                                            </Text>
                                                            {currency !==
                                                                ratesAndCurrencies.rates[selectedCurrencyIndex].currency ? (
                                                                <Text fontType="LATO_REGULAR" style={styles.minMaxText}>
                                                                    {'1 ' +
                                                                        currency +
                                                                        ' = ' +
                                                                        ratesAndCurrencies.rates[selectedCurrencyIndex]
                                                                            .rate +
                                                                        ' ' +
                                                                        ratesAndCurrencies.rates[selectedCurrencyIndex]
                                                                            .currency}
                                                                </Text>
                                                            ) : null}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                <View style={[styles.row, styles.checkboxRow]}>
                                                    <View style={styles.checkboxContainer}>
                                                        <Checkbox
                                                            checked={!showPersonalData}
                                                            setChecked={usePersonalData} />
                                                    </View>
                                                    <View style={styles.usePersonalDataTextContainer}>
                                                        <Text
                                                            fontType="LATO_REGULAR"
                                                            style={styles.useAddressText}
                                                            numberOfLines={2}
                                                        >
                                                            {'use-personal-information-from-registration'}
                                                        </Text>
                                                    </View>
                                                    <Pressable
                                                        style={styles.useAddressIconContainer}
                                                        onPress={() => Linking.openURL(`${config.USE_ADDRESS_LINK}`)}
                                                    >
                                                        <FontAwesome
                                                            name={'question'}
                                                            size={scaleSize(8)}
                                                            style={styles.useAddressIcon} />
                                                    </Pressable>
                                                </View>
                                                {showPersonalData ? (
                                                    <View>
                                                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                            {'first-name'}
                                                        </Text>
                                                        <TextInput
                                                            autoCapitalize="none"
                                                            autoCorrect={false}
                                                            value={firstName}
                                                            onChangeText={(text) => onChangeFirstName(text)}
                                                            keyboardType="default"
                                                            placeholderTextColor={'gray'}
                                                            returnKeyType="next"
                                                            containerStyle={styles.inputContainer} />
                                                        <ErrorMessage
                                                            text={'please-enter-first-name'}
                                                            visible={showFirstNameValidationMessage} />
                                                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                            {'last-name'}
                                                        </Text>
                                                        <TextInput
                                                            autoCapitalize="none"
                                                            autoCorrect={false}
                                                            value={lastName}
                                                            onChangeText={(text) => onChangeLastName(text)}
                                                            keyboardType="default"
                                                            placeholderTextColor={'gray'}
                                                            returnKeyType="next"
                                                            containerStyle={styles.inputContainer} />
                                                        <ErrorMessage
                                                            text={'please-enter-last-name'}
                                                            visible={showLastNameValidationMessage} />
                                                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                            {'country'}
                                                        </Text>
                                                        <Picker
                                                            style={styles.pickerStyle}
                                                            itemStyle={styles.pickerRow}
                                                            selectedValue={country}
                                                            onValueChange={(itemValue) => onChangeCountry(itemValue)}
                                                        >
                                                            <Picker.Item
                                                                label={i18n.t('please-select-country')}
                                                                value={''} />
                                                            {countries
                                                                .filter(
                                                                    (item) => alternativePaymentConfigData?.availableCountries[0] ===
                                                                        'ALL' ||
                                                                        alternativePaymentConfigData?.availableCountries.find(
                                                                            (s) => s === item.value
                                                                        ) !== undefined
                                                                )
                                                                .map((countryMap, index) => (
                                                                    <Picker.Item
                                                                        label={countryMap.label}
                                                                        value={countryMap.value}
                                                                        key={'CountryPicker' + index} />
                                                                ))}
                                                        </Picker>
                                                        <ErrorMessage
                                                            text={'please-select-country'}
                                                            visible={showCountryValidationMessage} />
                                                        {country === 'US' || country === 'CA' || country === 'AU' ? (
                                                            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                                {'state'}
                                                            </Text>
                                                        ) : null}
                                                        {country === 'US' ? (
                                                            <Picker
                                                                style={styles.pickerStyle}
                                                                itemStyle={styles.pickerRow}
                                                                selectedValue={state}
                                                                onValueChange={(itemValue) => setState(itemValue)}
                                                            >
                                                                {usState.map((stateMap, index) => (
                                                                    <Picker.Item
                                                                        label={stateMap.label}
                                                                        value={stateMap.value}
                                                                        key={'CountryPicker' + index} />
                                                                ))}
                                                            </Picker>
                                                        ) : null}
                                                        {country === 'CA' ? (
                                                            <Picker
                                                                style={styles.pickerStyle}
                                                                itemStyle={styles.pickerRow}
                                                                selectedValue={state}
                                                                onValueChange={(itemValue) => setState(itemValue)}
                                                            >
                                                                {caStates.map((stateMap, index) => (
                                                                    <Picker.Item
                                                                        label={stateMap.label}
                                                                        value={stateMap.value}
                                                                        key={'CountryPicker' + index} />
                                                                ))}
                                                            </Picker>
                                                        ) : null}
                                                        {country === 'AU' ? (
                                                            <Picker
                                                                style={styles.pickerStyle}
                                                                itemStyle={styles.pickerRow}
                                                                selectedValue={state}
                                                                onValueChange={(itemValue) => setState(itemValue)}
                                                            >
                                                                {auStates.map((stateMap, index) => (
                                                                    <Picker.Item
                                                                        label={stateMap.label}
                                                                        value={stateMap.value}
                                                                        key={'CountryPicker' + index} />
                                                                ))}
                                                            </Picker>
                                                        ) : null}

                                                    </View>
                                                ) : null}
                                            </View>
                                        ) : null}
                                        <View style={[styles.row, styles.checkboxRow]}>
                                            <View style={styles.checkboxContainer}>
                                                <Checkbox checked={confirm} setChecked={checkConfirm} />
                                            </View>
                                            <Text fontType="LATO_REGULAR" style={styles.custumerServiceText}>
                                                {'deposit-confirm'}
                                            </Text>
                                        </View>
                                        <View style={styles.addFundsContainer}>
                                            <Button
                                                title="deposit-button-text"
                                                onPress={() => {
                                                    payDeposit();
                                                }}
                                                disabled={!enableDepositButton()} />
                                        </View>
                                        <ErrorMessage
                                            text={paymentResponseErrorMessage}
                                            visible={paymentResponseErrorMessage !== ''} />
                                        <View style={styles.custumerServiceContainer}>
                                            <Text fontType="LATO_REGULAR" style={styles.custumerServiceText}>
                                                {'customer-service-part1'}
                                            </Text>
                                            <View style={styles.row}>
                                                <Text fontType="LATO_REGULAR" style={styles.custumerServiceText}>
                                                    {'customer-service-part2'}
                                                </Text>
                                                <Pressable
                                                    onPress={() => Linking.openURL(`${config.CUSTOMER_SERVICE_LINK}`)}
                                                >
                                                    <Text fontType="LATO_REGULAR" style={styles.custumerServiceActiveLink}>
                                                        {'customer-service'}
                                                    </Text>
                                                </Pressable>
                                            </View>
                                        </View>
                                        <CopyRight />
                                    </View>
                                </KeyboardAvoidingView>
                            </View>
                        </View>
                    </ScrollbarWrapper></>
            ) : (
                <WebView
                    style={styles.webViewStyle}
                    cacheEnabled={true}
                    javaScriptEnabled={true}
                    domStorageEnabled={true}
                    originWhitelist={['*']}
                    source={{
                        uri:
                            protocol +
                            '://' +
                            config.EXTERNAL_SOURCES_URL +
                            'mobileplatform/TrustPayments/Ideal/index.html?formAction=' + trustPaymentsIdealResponse.formAction +
                            '&billingcountryiso2a=' + trustPaymentsIdealResponse.params.billingcountryiso2a +
                            '&billingfirstname=' + encodeURIComponent(trustPaymentsIdealResponse.params.billingfirstname) +
                            '&billinglastname=' + encodeURIComponent(trustPaymentsIdealResponse.params.billinglastname) +
                            '&currencyiso3a=' + trustPaymentsIdealResponse.params.currencyiso3a +
                            '&mainamount=' + trustPaymentsIdealResponse.params.mainamount +
                            '&orderreference=' + trustPaymentsIdealResponse.params.orderreference +
                            '&ruleidentifier=' + trustPaymentsIdealResponse.params.ruleidentifier +
                            '&sitereference=' + trustPaymentsIdealResponse.params.sitereference +
                            '&stprofile=' + trustPaymentsIdealResponse.params.stprofile +
                            '&version=' + trustPaymentsIdealResponse.params.version
                    }}
                />
            )}
        </SafeAreaView>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        logoContainer: { width: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: scaleSize(20) },
        textInputWidth: { width: '100%' },
        textInputText: {
            color: theme.desktopHeaderTextColor,
            fontFamily: FONT_FAMILY_BOLD,
            letterSpacing: 1,
            fontSize: FONT_SIZE_L,
        },
        safeArea: { flex: 1 },
        webViewStyle: { flex: 1, width: '100%', height: '100%', minHeight: '100%' },
        usePersonalDataTextContainer: { maxWidth: '85%' },
        scrollView: { maxHeight: '100%' },
        useAddressIcon: { color: theme.white },
        useAddressIconContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.tabIconSelected,
            width: scaleSize(15),
            height: scaleSize(15),
            borderRadius: scaleSize(12.5),
        },
        checkboxContainer: { paddingRight: scaleSize(10) },
        checkboxRow: { alignItems: 'center', paddingTop: scaleSize(20) },
        addFundsContainer: { paddingTop: scaleSize(20) },
        custumerServiceContainer: { paddingTop: scaleSize(20) },
        useAddressText: {
            fontSize: FONT_SIZE_XS,
            color: theme.text_positionsCount,
            textAlign: 'justify',
            paddingRight: scaleSize(10),
        },
        custumerServiceText: { fontSize: FONT_SIZE_XS, color: theme.text_positionsCount },
        custumerServiceActiveLink: { color: theme.switch_trackOn, fontSize: FONT_SIZE_XS },
        securityCodeInputContainer: { width: '80%' },
        row: { flexDirection: 'row' },
        inputContainer: { height: scaleSize(44) },
        securityIconCircleContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.white,
            width: scaleSize(15),
            height: scaleSize(15),
            borderRadius: scaleSize(12.5),
        },
        securityCodeRightContainer: {
            position: 'absolute',
            right: scaleSize(3),
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.tabIconSelected,
            width: '20%',
            height: scaleSize(44),
            borderTopRightRadius: scaleSize(5),
            borderBottomRightRadius: scaleSize(5),
        },
        questionIcon: { color: theme.tabIconSelected },
        currencyConvertorValueContainer: {
            width: '65%',
            justifyContent: 'center',
            alignItems: 'flex-start',
            paddingLeft: scaleSize(20),
            paddingVertical: scaleSize(8),
        },
        currencyConvertorContainer: { flexDirection: 'row' },
        currencyContainer: { width: '35%' },
        pickerStyle: {
            width: '100%',
            height: Platform.OS !== 'ios' ? scaleSize(44) : 3 * scaleSize(44),
            fontSize: scaleSize(FONT_SIZE_S),
            backgroundColor: theme.backgroundSecondary,
            color: theme.desktopHeaderTextColor,
            borderRadius: scaleSize(5),
            padding: 0,
        },
        pickerRow: {
            width: '100%',
            height: Platform.OS !== 'ios' ? scaleSize(44) : 3 * scaleSize(44),
            fontSize: scaleSize(FONT_SIZE_S),
            color: theme.text
        },
        headerContainer: {
            paddingHorizontal: scaleSize(17),
            paddingTop: scaleSize(20),
            height: scaleSize(40),
        },
        depositAmountFieldContainer: { width: '55%' },
        depositAmountMinMaxContainer: { width: '45%', justifyContent: 'space-between', paddingVertical: scaleSize(8) },
        depositAmountContainer: { flexDirection: 'row' },
        expDateField: { width: '33.33%' },
        expDateFieldPaddingLeft: { paddingLeft: scaleSize(10) },
        expDateFieldPaddingRight: { paddingRight: scaleSize(10) },
        expDateAndSCS: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
        minMaxText: {
            fontSize: FONT_SIZE_XS,
            color: theme.text_positionsCount,
            paddingLeft: scaleSize(10),
        },
        labelText: { fontSize: FONT_SIZE_S, color: theme.text_symbol, paddingVertical: scaleSize(10) },
        titleContainer: {
            backgroundColor: theme.tabIconSelected,
            width: scaleSize(375),
            height: scaleSize(30),
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row'
        },
        addFundsTitleText: { fontSize: FONT_SIZE_S, color: theme.white },
        content: {
            width: '100%',
            paddingTop: scaleSize(20),
            height: '100%',
        },
        logoStyle: {
            width: scaleSize(100),
            height: scaleSize(40),
        },
        container: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingHorizontal: scaleSize(17),
            height: '100%',
            width: '100%',
        },
        viewContainer: { justifyContent: 'space-between', height: '80%', paddingBottom: scaleSize(10) },
    });
