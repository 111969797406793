import axios from '../baseClient';
import {
    ConfigRequest,
    ConfigResponse,
    RatesAndCUrrenciesRequestV2,
    RatesAndCUrrenciesResponse
} from './types';

class PaymentConverterService {
    private static domain = '/api/convertrateservice';

    getConfig(configRequest: ConfigRequest) {
        return axios.post<ConfigResponse>(`${PaymentConverterService.domain}/config`, configRequest);
    }

    getRatesAndCUrrenciesV2(ratesAndCUrrenciesRequest: RatesAndCUrrenciesRequestV2) {
        return axios.post<RatesAndCUrrenciesResponse>(
            `${PaymentConverterService.domain}/ratesV2`,
            ratesAndCUrrenciesRequest
        );
    }

}

export default new PaymentConverterService();
