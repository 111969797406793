import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Pressable, Platform, ScrollView } from 'react-native';
import DraggableFlatList, { NestableDraggableFlatList, NestableScrollContainer, RenderItemParams, ScaleDecorator } from 'react-native-draggable-flatlist';
import { DataSymbol } from '~/api-services/LoadService/types';
import { Text } from '~/components/core';
import { useAppDispatch, useAppSelector, useStyles } from '~/hooks';
import { watchlistInstrumentsSelector, watchlistByIdSelector, allSymbolNamesSelector } from '~/state/selectors';
import { Feather } from "~/components/core/Icons";
import { saveWatchlist } from '~/state/reducers/watchlistSlice';
import { useRoute } from '@react-navigation/core';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import { loadAllSymbolNames } from '~/state/reducers/allSymbolNamesSlice';
import { Theme } from '~/theme';
import { FONT_SIZE_S } from '~/constants';
import { useFocusEffect } from '@react-navigation/native';
import { isDesktop, scaleSize } from '~/constants/scale';
import Loader from '~/components/core/Loader';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import { SearchHeader } from './SearchHeader';
import AddFavoritesListSearch from './AddFavoritesListSearch';

type Props = {
    item: RenderItemParams<DataSymbol>;
    watchlistData: DataSymbol[];
    setWatchlistData: Function;
    styles: ReturnType<typeof stylesGenerator>;
    setEditedList: Function;
};

const RenderItem = ({ item, watchlistData, setWatchlistData, styles, setEditedList }: Props) => {
    return (
        <ScaleDecorator>
            <Pressable onLongPress={item.drag} delayLongPress={100}>
                <View style={styles.container}>
                    <View style={styles.textContainer}>
                        <Pressable
                            style={styles.deleteIcon}
                            onPress={() => {
                                setWatchlistData(watchlistData.filter((symbol) => symbol.symbol !== item.item.symbol));
                                setEditedList(true);
                            }
                            }
                        >
                            <Feather name={'plus'} size={scaleSize(14)} style={styles.iconButtonRemove} />
                        </Pressable>
                        <Text style={styles.text} fontType="LATO_BOLD">
                            {item.item.nameTranslated}
                        </Text>
                        <Text style={styles.description} fontType="LATO_REGULAR">
                            {item.item.descriptionTranslated.length > 50
                                ? item.item.descriptionTranslated.substring(0, 50) + '...'
                                : item.item.descriptionTranslated}
                        </Text>
                    </View>
                    <View style={styles.dragIcon}>
                        <Feather name={'chevron-up'} size={scaleSize(14)} style={styles.dragButton} />
                        <Feather name={'chevron-down'} size={scaleSize(14)} style={styles.dragButton} />
                    </View>
                </View>
            </Pressable>
        </ScaleDecorator>
    );
};
type PropsEF = {
    selectedId?: number;
    setOpenEditFavorites?: Function;
};
export default function EditFavoritesList({ selectedId, setOpenEditFavorites }: PropsEF) {
    const styles = useStyles(stylesGenerator);
    const dispatch = useAppDispatch();
    const route = useRoute();
    const id = selectedId ? selectedId : parseInt((route.params as any).id, 10);
    const { watchlistInstruments } = useAppSelector((state) => watchlistInstrumentsSelector(state, id));
    const watchlist = useAppSelector((state) => watchlistByIdSelector(state, id));
    const allSymbolNames = useAppSelector((state) => allSymbolNamesSelector(state));
    const [watchlistData, setWatchlistData] = useState<DataSymbol[]>([]);
    const [isLoadWatchlist, setIsLoadWatchlist] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [editedList, setEditedList] = useState(false);
    const [startDrag, setStartDrag] = useState(false);

    useFocusEffect(
        React.useCallback(() => {
            if (isLoadWatchlist === false || searchTerm !== '') {
                setWatchlistData(watchlistInstruments);
            }
            setIsLoadWatchlist(true);
            return () => {
                setIsLoadWatchlist(false);
            };
        }, [isLoadWatchlist, watchlistInstruments, searchTerm])
    );

    useEffect(() => {
        if (allSymbolNames.length === 0) {
            dispatch(loadAllSymbolNames());
        }
    }, [dispatch, allSymbolNames]);

    if (!watchlist) {
        return null;
    }

    async function saveFavorites(data: DataSymbol[]) {
        if (watchlist !== undefined) {
            setLoading(true);
            await dispatch(
                saveWatchlist({
                    ...watchlist,
                    instrumentCodes: data.map((s) => s.symbol),
                })
            );
            setLoading(false);
            if (isDesktop()) {
                setOpenEditFavorites(false);
            }
            else {
                linkTo(paths.watchlistItem(id));
            }
        }
    }

    return (
        <GestureHandlerRootView style={styles.viewContainer}>
            <Loader loading={loading} />
            <SearchHeader setOpenEditFavorites={setOpenEditFavorites} editedList={editedList} searchTerm={searchTerm} setSearchTerm={setSearchTerm} screen={'EditFavoritesList'} watchListId={id} saveFavorites={saveFavorites} data={watchlistData} />
            {searchTerm !== '' ?
                <AddFavoritesListSearch watchListId={id} searchTerm={searchTerm} />
                :
                Platform.OS === 'web' ?

                    (
                        isDesktop() ?
                            <ScrollbarWrapper>
                                <DraggableFlatList
                                    style={{ width: '100%', height: '85%' }}
                                    data={watchlistData}
                                    onDragEnd={({ data }) => {
                                        setWatchlistData(data);
                                        setEditedList(true);
                                    }}
                                    keyExtractor={(item) => item.symbol}
                                    scrollEnabled={false}
                                    renderItem={(item) => (
                                        <RenderItem
                                            item={item}
                                            watchlistData={watchlistData}
                                            setWatchlistData={setWatchlistData}
                                            styles={styles}
                                            setEditedList={setEditedList}
                                        />
                                    )}
                                />
                            </ScrollbarWrapper>
                            :
                            <NestableScrollContainer style={styles.scrollView} scrollEnabled={true} showsVerticalScrollIndicator={false}>
                                <NestableDraggableFlatList
                                    data={watchlistData}
                                    onDragBegin={() => {
                                        setStartDrag(true);
                                        console.log('setStartDrag(true)');
                                    }}
                                    onDragEnd={({ data }) => {
                                        setWatchlistData(data);
                                        setEditedList(true);
                                        setStartDrag(false);
                                        console.log('setStartDrag(false)');
                                    }}
                                    keyExtractor={(item) => item.symbol}
                                    scrollEnabled={true}
                                    renderItem={(item) => (
                                        <RenderItem
                                            item={item}
                                            watchlistData={watchlistData}
                                            setWatchlistData={setWatchlistData}
                                            styles={styles}
                                            setEditedList={setEditedList}
                                        />
                                    )}
                                />
                            </NestableScrollContainer>
                    ) : (
                        <DraggableFlatList
                            style={{ width: '100%', height: '85%', paddingBottom: scaleSize(7) }}
                            data={watchlistData}
                            onDragEnd={({ data }) => {
                                setWatchlistData(data);
                                setEditedList(true);
                            }}
                            keyExtractor={(item) => item.symbol}
                            scrollEnabled={true}
                            renderItem={(item) => (
                                <RenderItem
                                    item={item}
                                    watchlistData={watchlistData}
                                    setWatchlistData={setWatchlistData}
                                    styles={styles}
                                    setEditedList={setEditedList}
                                />
                            )}
                        />
                    )}
        </GestureHandlerRootView>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        scrollView: { height: scaleSize(532), paddingBottom: scaleSize(7) },
        editFavoritesContainer: { width: '100%', justifyContent: 'center', alignItems: 'center' },
        viewContainer: {
            flex: 1,
            width: '100%',
            height: '100%',
        },
        inputContainer: {
            marginBottom: scaleSize(20),
        },
        addMarketButtonContainer: {
            alignItems: 'center',
            justifyContent: 'center',
            width: scaleSize(22),
            height: scaleSize(22),
            backgroundColor: theme.white,
            borderRadius: scaleSize(11),
            position: 'absolute',
            left: scaleSize(38),
        },
        iconButtonAddMarket: {
            color: theme.background_positionId,
        },
        iconButton: {
            color: theme.black,
            position: 'absolute',
            left: scaleSize(30),
        },
        dragButton: {
            color: theme.background_positionId,
        },
        iconButtonRemove: {
            color: theme.white,
        },
        container: {
            height: scaleSize(50),
            borderRadius: scaleSize(5),
            marginTop: scaleSize(7),
            marginHorizontal: scaleSize(7),
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.backgroundSecondary,
            flexDirection: 'row',
        },
        addMarketContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            padding: scaleSize(10),
            justifyContent: 'center',
            height: '10%',
        },

        textContainer: {
            width: '92%',
            flexDirection: 'row',
            alignItems: 'center',
        },
        deleteIcon: {
            alignItems: 'center',
            marginLeft: scaleSize(10),
            marginRight: scaleSize(10),
            transform: [{ rotate: '-45deg' }],
            justifyContent: 'center',
            width: scaleSize(22),
            height: scaleSize(22),
            backgroundColor: theme.text_downPrice,
            borderRadius: scaleSize(11),
        },
        dragIcon: {
            alignItems: 'flex-end',
            width: '8%',
            paddingRight: scaleSize(10),
        },
        text: {
            fontSize: FONT_SIZE_S,
            color: theme.text_symbol,
        },
        description: {
            fontSize: FONT_SIZE_S,
            color: theme.text_pendingOrder,
            paddingLeft: scaleSize(5),
        },
    });
