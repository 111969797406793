import { useEffect, useState } from 'react';
import PaymentConverterService from '~/api-services/PaymentConverterService';
import { RatesAndCUrrenciesType } from '~/api-services/PaymentConverterService/types';

export const useRates = (level: string, provider: string) => {
    const [ratesAndCurrencies, setRatesAndCurrencies] = useState<RatesAndCUrrenciesType>();

    useEffect(() => {
        (async () => {
            const { status, data } = await PaymentConverterService.getRatesAndCUrrenciesV2({
                level: level,
                provider: provider,
            });
            if (status === 200 && data.status) {
                setRatesAndCurrencies(data.returnData);
            }
        })();
    }, [level, provider]);

    return {
        ratesAndCurrencies,
    };
};
