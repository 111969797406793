import { useEffect, useRef, useState, useCallback } from 'react';
import { InitialState, NavigationContainer } from '@react-navigation/native';
import { Linking, Platform } from 'react-native';
import i18n from '~/i18n';
import { useLoadFonts } from './useLoadFonts';
import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';
import { usePushNotifications } from './usePushNotifications';
import {
    userIsLoggedInSelector,
    userCredentialsAreSetSelector,
    userLocalDataIsSetSelector,
    userAuthSelector,
    watchlistSelector,
    userAuthDataSelector,
    appSettingsLanguageSelector,
} from '~/state/selectors';
import { loadLocalUserAuthData, logoutUser } from '~/state/reducers/userAuthSlice';
import { loadUserData, loadUserKeepALiveData } from '~/state/reducers/userDataSlice';
import { loadAppSettings } from '~/state/reducers/appSettingsSlice';
import { getLinking, setWatchlistLinking } from '~/navigation/linking';
import StreamingService from '~/api-services/StreamingService';
import LocalStorage from '~/state/localStorage';
import { useIsAppActive } from './useIsAppActive';
import { useLogout } from './useLogout';
import { useNavigationTheme } from './useNavigationTheme';

type OnStateChange = NonNullable<React.ComponentProps<typeof NavigationContainer>['onStateChange']>;

export const useInitialDataLoad = () => {
    const dispatch = useAppDispatch();
    const theme = useNavigationTheme();
    const fontsLoaded = useLoadFonts();
    const isLoggedIn = useAppSelector(userIsLoggedInSelector);
    const { sessionId } = useAppSelector(userAuthDataSelector);
    const credentialsAreSet = useAppSelector(userCredentialsAreSetSelector);
    const localDataIsSet = useAppSelector(userLocalDataIsSetSelector);
    const [isLoading, setIsLoading] = useState(true);
    const language = useAppSelector(appSettingsLanguageSelector);
    const [key, setKey] = useState('NavContainer_en');
    const { isSuccess: isLoginSuccess } = useAppSelector(userAuthSelector);
    const {
        isSuccess: isWatchlistSuccess,
        isError: isWatchlistError,
        data: watchlist,
    } = useAppSelector(watchlistSelector);
    const accountDataPollRef = useRef<NodeJS.Timer>();
    const isAppActive = useIsAppActive();
    const [isPersisted, setIsPersisted] = useState(false);
    const [initialState, setInitialState] = useState<InitialState>();

    const onStateChange = useCallback<OnStateChange>((state) => {
        if (Platform.OS !== 'web') {
            return;
        }
        LocalStorage.initialNavigationState.set(state as InitialState);
    }, []);
    const logout = useLogout();

    useEffect(() => {
        if (isPersisted) {
            return;
        }
        if (Platform.OS !== 'web') {
            setIsPersisted(true);
            return;
        }
        (async () => {
            try {
                const initialUrl = await Linking.getInitialURL();
                if (
                    initialUrl.indexOf('resetPassword') < 0 &&
                    initialUrl.indexOf('forgotPassword') < 0 &&
                    initialUrl.indexOf('confirmation') < 0 &&
                    initialUrl.indexOf('loginIB') < 0 &&
                    initialUrl.indexOf('loginAsCustomer') < 0 &&
                    initialUrl.indexOf('autoLogin') < 0 &&
                    initialUrl.indexOf('loginWithToken') < 0 &&
                    initialUrl.indexOf('registration') < 0 &&
                    initialUrl.indexOf('login') < 0
                ) {
                    const initialNavigationState = await LocalStorage.initialNavigationState.get();
                    if (initialNavigationState) {
                        setInitialState(initialNavigationState);
                    }
                } else {
                    if(sessionId && sessionId !== '') {
                        logout();
                    }
                }
            } finally {
                setIsPersisted(true);
            }
        })();
    }, [isPersisted, logout]);

    useEffect(() => {
        if (isAppActive && isLoggedIn) {
            accountDataPollRef.current = setInterval(() => {
                dispatch(loadUserKeepALiveData());
            }, 3000); // refetch keepalive data every 5 seconds
            StreamingService.initConnection(sessionId);
        } else {
            accountDataPollRef.current && clearInterval(accountDataPollRef.current);
            StreamingService.closeConnection();
        }
        return () => {
            accountDataPollRef.current && clearInterval(accountDataPollRef.current);
        };
    }, [dispatch, isLoggedIn, isAppActive, sessionId]);

    useEffect(() => {
        dispatch(loadAppSettings());
        dispatch(loadLocalUserAuthData());
    }, [dispatch]);

    useEffect(() => {
        if (credentialsAreSet) {
            dispatch(loadUserData());
        }
    }, [dispatch, isLoginSuccess, credentialsAreSet]);

    useEffect(() => {
        i18n.locale = language;
        setKey(`NavContainer_${language}`);
    }, [language]);

    useEffect(() => {
        if (!localDataIsSet || !fontsLoaded) {
            return;
        }
        if (isWatchlistError) {
            dispatch(logoutUser());
            return;
        }
        if (!credentialsAreSet || isWatchlistSuccess) {
            setWatchlistLinking(watchlist.map((w) => w.id));
            isLoading && setIsLoading(false);
        }
    }, [
        dispatch,
        isLoading,
        localDataIsSet,
        credentialsAreSet,
        fontsLoaded,
        isWatchlistError,
        isWatchlistSuccess,
        watchlist,
    ]);

    usePushNotifications(isLoading);

    return {
        key,
        theme,
        isLoading,
        isLoggedIn,
        initialState,
        onStateChange,
        linking: getLinking(),
    };
};
