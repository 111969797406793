import React from 'react';
import {ErrorMessage, Text, TextInput} from "~/components/core";
import {BillingField} from "~/api-services/CustomerInfoService/types";

type Props = {
    idx: number;
    fields: BillingField[];
    onChangeBillingField: Function;
};

export default function BillingFieldComponent({ idx, fields, onChangeBillingField }: Props) {
    return (
        <>
            <Text fontType="LATO_REGULAR" noTranslate={true}>
                {fields[idx].placeholder}
            </Text>
            <TextInput
                autoCapitalize="none"
                autoCorrect={false}
                value={fields[idx].value || ''}
                onChangeText={(text) => {
                    onChangeBillingField(idx, text);
                }}
                keyboardType="default"
                placeholderTextColor={'gray'}
                returnKeyType="next"
            />
            <ErrorMessage
                text={'please-fill-all-required-fields'}
                visible={fields[idx].showValidation}
            />
        </>
    )
}
