import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Image, Pressable } from 'react-native';
import {PaymentConfig, PaytikoPaymentProvider} from '~/api-services/PaymentConverterService/types';
import { Text } from '~/components/core';
import config from '~/config';
import { FONT_SIZE_S } from '~/constants';
import { isDesktop, scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { useAddFunds } from '~/hooks/useAddFunds';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import { Theme } from '~/theme';
import DesktopScreenHeader from '../Desktop/DesktopScreenHeader';
import ResolveCountryService from '~/api-services/ResolveCountryService';
import { isEUCountry } from '~/lib/utils';
type Props = {
    provider?: string;
    setActiveRightTab?: Function;
    currentAmount?: string;
    setProvider?: Function;
    setPaytikoPaymentProvider?: Function;
};

export default function AddFundsCardPaytiko({ provider, setActiveRightTab, currentAmount, setProvider, setPaytikoPaymentProvider}: Props) {

    const styles = useStyles(stylesGenerator);
    const route = useRoute();
    const selectedProvider: string = provider ? provider : (route.params as any).provider;
    const amount = currentAmount ? currentAmount : (route.params as any).amount;
    const { configData } = useAddFunds(amount!='' ? Number(amount) : 0);
    const [enableSkrillLogo, setEnableSkrillLogo] = useState(false);

    useEffect(() => {
        (async () => {
            const { status, data } = await ResolveCountryService.resolveCountry();
            if (status === 200 && data.status) {
                let clientCountry = data.returnData;
                if (!isEUCountry(clientCountry) && clientCountry !== 'GB') {
                    setEnableSkrillLogo(true);
                }
            }

        })();
    }, []);

    function openPaymentCategory(cProvider: string, cProviderId: number) {
        if (setProvider) {
            setProvider(cProvider);
        }
        if (setPaytikoPaymentProvider) {
            setPaytikoPaymentProvider(cProviderId);
        }
        if (setActiveRightTab) {
            setActiveRightTab('add-money-card');
        }
    }

    function RenderCards() {
        if (!configData.card) {
            return null;
        }
        if (configData.card.length == 0) {
            return null;
        }
        return configData.card.map((paymentConfig: PaymentConfig, key) => (
            <RenderCard paymentConfig={paymentConfig} key={key}></RenderCard>
        ))
    }

    function RenderCard({paymentConfig}) {
        if (paymentConfig.provider === 'PAYTIKODIRECTCARD' || paymentConfig.provider === 'ARENAMONEYDIRECTCARD') {
            // ok
        } else {
            return null;
        }
        if (paymentConfig.provider !== selectedProvider) {
            return null;
        }
        if (!paymentConfig.paytikoPaymentProviders) {
            return null;
        }
        if (paymentConfig.paytikoPaymentProviders.length === 0) {
            return null;
        }
        return paymentConfig.paytikoPaymentProviders.map((paytikoPaymentProvider: PaytikoPaymentProvider, key) => (
            <RenderCardNormal paymentConfig={paymentConfig} key={key} paytikoProvider={paytikoPaymentProvider}></RenderCardNormal>
        ))
    }

    function makeWebsiteKey(): string {
        return config.WEBSITE === 'FXMERIDIAN' || config.WEBSITE === 'INVESTIGNFF' ? 'FXMERIDIAN-' : '';
    }

    function RenderCardNormal({paymentConfig, paytikoProvider}) {
        const onPress = () => {
            isDesktop() && setActiveRightTab
                ? openPaymentCategory(paymentConfig.provider, paytikoProvider.id)
                : linkTo(
                    paths.addFundsCard
                        .replace(':provider', paymentConfig.provider)
                        .replace(':paytikoProvider', paytikoProvider.id.toString())
                        .replace(':amount', amount)
                );
        };
        return <RenderCardCommon paymentConfig={paymentConfig} onPress={onPress} paytikoProvider={paytikoProvider}></RenderCardCommon>
    }

    function RenderCardCommon({paymentConfig, onPress, paytikoProvider}) {
        let imageSource;
        if (paymentConfig.provider === 'PAYTIKO') {
            imageSource = enableSkrillLogo ?
                require('../../../../assets/files/mobileplatform/Paytiko/crypto_skrill_visa.gif') :
                require('../../../../assets/files/mobileplatform/Paytiko/visa_master_crypto.gif');
        } else {
            imageSource = require('../../../../assets/files/mobileplatform/images/payments/visa_mastercard.gif');
        }
        return (
            <Pressable
                style={styles.row}
                onPress={onPress}
            >
                <View style={styles.paymentCategoryBox}>
                    <Image
                        source={imageSource}
                        style={styles.paymentCategory}
                    />
                </View>
                <Text fontType="LATO_REGULAR" style={styles.descriptionText} noTranslate={true}>
                    {paytikoProvider.title}
                </Text>
            </Pressable>
        )
    }

    function RenderConfig() {
        if (!configData) {
            return null;
        }
        return (
            <View style={styles.content}>
                <RenderCards></RenderCards>
            </View>
        )
    }

    return (
        <>
            <DesktopScreenHeader goToScreenName="add-money" title="add-money" setActiveRightTab={setActiveRightTab} />
            <View style={styles.container}>
                <RenderConfig></RenderConfig>
            </View>
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        paymentCategoryBox: {
            justifyContent: 'center',
            alignItems: 'center',
            width: scaleSize(60),
            height: scaleSize(30),
            backgroundColor: theme.white,
            borderColor: theme.border_2,
            borderRadius: scaleSize(5),
            borderWidth: scaleSize(1),
            marginRight: 10,
        },
        descriptionText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        addFundsTitleContainer: {
            paddingVertical: scaleSize(40),
            justifyContent: 'center',
            alignItems: 'center',
            borderBottomWidth: 1,
            borderBottomColor: theme.border_2,
            marginHorizontal: scaleSize(10),
        },
        addFundsTitleText: { fontSize: FONT_SIZE_S, color: theme.greenLineDesktop },
        content: { width: '100%' },
        paymentCategory: {
            width: scaleSize(50),
            height: scaleSize(18),
        },
        row: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: scaleSize(10),
            borderBottomWidth: 1,
            borderBottomColor: theme.border_2,
            marginHorizontal: scaleSize(10),
        },
        container: {
            alignItems: 'center',
            flex: 1,
            justifyContent: 'space-between',
            width: '100%',
            height: '100%',
            paddingTop: scaleSize(7),
            paddingHorizontal: scaleSize(17),
        },
    });
