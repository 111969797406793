import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import AddFundsCategory from '../features/AddFundsCategory';
import AddFundsCardPaytiko from "~/components/features/AddFundsCardPaytiko";
type Props = {
    provider?: string;
    currentAmount?: string;
    setActiveRightTab?: Function;
    setProvider?: Function;
    setPaytikoPaymentProvider?: Function;
};
export default function AddFundsCardPaytikoScreen({ provider, setActiveRightTab, setProvider, setPaytikoPaymentProvider, currentAmount }: Props) {
    return (
        <ScreenWrapper>
            <AddFundsCardPaytiko
                provider={provider}
                setActiveRightTab={setActiveRightTab}
                setProvider={setProvider}
                setPaytikoPaymentProvider={setPaytikoPaymentProvider}
                currentAmount={currentAmount}
            />
        </ScreenWrapper>
    );
}
