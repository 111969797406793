import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import AddFundsCategory from '../features/AddFundsCategory';
type Props = {
    currentAmount?: string;
    setActiveRightTab?: Function;
    setProvider?: Function;
    setPaytikoPaymentProvider?: Function;
};
export default function AddFundsCategoryScreen({ setActiveRightTab, setProvider, setPaytikoPaymentProvider, currentAmount }: Props) {
    return (
        <ScreenWrapper>
            <AddFundsCategory
                setActiveRightTab={setActiveRightTab}
                setProvider={setProvider}
                setPaytikoPaymentProvider={setPaytikoPaymentProvider}
                currentAmount={currentAmount}
            />
        </ScreenWrapper>
    );
}
