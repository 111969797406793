import React, {useState} from 'react';
import { useRoute } from '@react-navigation/native';
import { Pressable, ScrollView, StyleSheet, View } from 'react-native';
import { Text } from '~/components/core';
import { FONT_SIZE_M, FONT_SIZE_S, FONT_SIZE_XL, FONT_SIZE_XXS } from '~/constants';
import { isDesktop, scaleSize } from '~/constants/scale';
import { useAppDispatch, useAppSelector, useStyles } from '~/hooks';
import { useSubscribeQuote } from '~/hooks/useSubscribeQuote';
import { formatDate, formatNumber, formatNumberWithPercent, formatStringToNumber } from '~/lib/format';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import { dealerSelector, symbolsSelector, userDataSelector, watchlistDataSelector } from '~/state/selectors';
import { Theme } from '~/theme';
import BuySellButton from './BuySellButton';
import { Entypo } from "~/components/core/Icons";
import { saveWatchlist } from '~/state/reducers/watchlistSlice';
import RowData from './RowData';
import LiveStatistic from './LiveStatistic';
import PriceRange from './PriceRange';
import BasicChart from './BasicChart';
import { useTradingHours } from '~/hooks/useTradingHours';
import InstrumentIcon from '../Market/MarketChartCard/InstrumentIcon';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import { useInstrumentNews } from '~/hooks/useInstrumentNews';
import NewsRow from './NewsRow';

type Props = {
    selectedSymbol?: string;
    setOpenBuySellType?: Function;
};

export default function BuySellPosition({ selectedSymbol, setOpenBuySellType }: Props) {
    const route = useRoute();
    const symbol: string = selectedSymbol ? selectedSymbol : (route.params as any).symbol;
    useSubscribeQuote(symbol);
    const { newsArray } = useInstrumentNews(symbol);
    const dataSymbol = useAppSelector((state) => symbolsSelector(state)[symbol]);
    const styles = useStyles(stylesGenerator);
    const watchlist = useAppSelector(watchlistDataSelector)[0];
    const dispatch = useAppDispatch();
    const isFavoritesInstrument: boolean = watchlist.instrumentCodes.find((s) => s === symbol) === symbol;
    const averagePrice = (dataSymbol.price.bid + dataSymbol.price.ask) / 2;
    const dealer = useAppSelector(dealerSelector);
    const tradingHours = useTradingHours(dataSymbol.symbol, dealer ? dealer : '', dataSymbol.type, dataSymbol.marketId);
    const openMarket = useAppSelector(userDataSelector)?.data?.account.marketToOpeningMap[dataSymbol.marketId];
    const now = new Date().getTime();
    const isMarketOpen = openMarket === 0 || now > openMarket;
    const [scrollContent, setScrollContent] = useState({})
    const [openNews, setOpenNews] = useState('');

    function saveFavorites(data: string[]) {
        if (watchlist !== undefined) {
            dispatch(
                saveWatchlist({
                    ...watchlist,
                    instrumentCodes: data.map((s) => s),
                })
            );
        }
    }

    return (
        <ScrollbarWrapper contentContainerStyle={scrollContent} overScrollMode="never">
            <View style={styles.container}>
                <View style={styles.title}>
                    <View style={styles.symbolRow}>
                        <View style={styles.start}>
                            <InstrumentIcon iconUrl={dataSymbol.iconUrl} />
                        </View>
                        <View style={styles.center}>
                            <Text fontType="LATO_BOLD" style={styles.symbolName}>
                                {dataSymbol.nameTranslated}
                            </Text>
                        </View>
                        <View style={styles.end}>
                            <Pressable
                                onPress={() =>
                                    isFavoritesInstrument
                                        ? saveFavorites(
                                              watchlist.instrumentCodes.filter((s) => s !== dataSymbol.symbol)
                                          )
                                        : saveFavorites([dataSymbol.symbol].concat(watchlist.instrumentCodes))
                                }
                                style={[
                                    styles.starBackground,
                                    isFavoritesInstrument ? styles.starBackgroundActive : styles.starBackgroundUnactive,
                                ]}
                            >
                                <Entypo
                                    name="star"
                                    size={scaleSize(10)}
                                    style={isFavoritesInstrument ? styles.activeStar : styles.unActiveStar}
                                />
                            </Pressable>
                        </View>
                    </View>
                    <Text fontType="LATO_BOLD" style={styles.symbolDescription}>
                        {dataSymbol.descriptionTranslated}
                    </Text>
                </View>
                {!isDesktop() ? (
                    <View onTouchStart={() => {setScrollContent({flex: 1})}} onTouchEnd={() => {setScrollContent({})}}>
                        <BasicChart symbol={symbol} />
                    </View>
                ) : null}
                <View style={styles.buySellContainer}>
                    <BuySellButton
                        style={styles.sellButtonContainer}
                        label="SELL"
                        onPress={() =>
                            isDesktop()
                                ? setOpenBuySellType
                                    ? setOpenBuySellType('sell')
                                    : null
                                : linkTo(
                                      paths.marketOrder.replace(':symbol', dataSymbol.symbol).replace(':side', 'sell')
                                  )
                        }
                        price={formatNumber(dataSymbol.price.bid, dataSymbol.digits)}
                    />
                    <BuySellButton
                        style={styles.buyButtonContainer}
                        label="BUY"
                        onPress={() =>
                            isDesktop()
                                ? setOpenBuySellType
                                    ? setOpenBuySellType('buy')
                                    : null
                                : linkTo(
                                      paths.marketOrder.replace(':symbol', dataSymbol.symbol).replace(':side', 'buy')
                                  )
                        }
                        price={formatNumber(dataSymbol.price.ask, dataSymbol.digits)}
                    />
                </View>
                <PriceRange averagePrice={averagePrice} digits={dataSymbol.digits} symbol={dataSymbol.symbol} />
                <LiveStatistic symbol={dataSymbol.symbol} averagePrice={averagePrice} />
                <View style={styles.sectionContainer}>
                    <Text fontType="LATO_BOLD" style={styles.sectionTitleText}>
                        market-info
                    </Text>
                    <RowData labelKey="trading-hours" value={tradingHours.tradingHours} />
                    {dataSymbol.longDescription && dataSymbol.longDescription !== '' ? (
                        <View style={styles.descriptionContainer}>
                            <Text fontType="LATO_REGULAR" style={[styles.liveStatDetailText, styles.symbolColor]}>
                                {dataSymbol.longDescription}
                            </Text>
                        </View>
                    ) : null}
                    {newsArray.length !== 0 &&
                        newsArray.map((news, key) => (
                            <Pressable
                                style={styles.container}
                                key={key}
                                onPress={() => setOpenNews(openNews === news.id ? '' : news.id)}
                            >
                                <NewsRow news={news} isOpen={openNews === news.id} />
                            </Pressable>
                        ))}
                </View>
                <View style={styles.sectionContainer}>
                    <Text fontType="LATO_BOLD" style={styles.sectionTitleText}>
                        highlights
                    </Text>
                    <RowData
                        labelKey="target-spread"
                        value={formatNumber(dataSymbol.targetSpread, dataSymbol.digits)}
                        spacing={true}
                    />
                    <RowData
                        labelKey="minimum-quantity"
                        value={formatNumber(dataSymbol.minTradeVolume, dataSymbol.minTradeVolumePrevision)}
                        spacing={true}
                    />
                    <RowData labelKey="currency" value={dataSymbol.currency} />
                    <RowData
                        labelKey="leverage"
                        value={dataSymbol.margin ? '1:' + formatNumber(1 / dataSymbol.margin, 0) : ''}
                        spacing={true}
                    />
                    <RowData
                        labelKey="short-position-swap"
                        value={formatNumberWithPercent(Number(tradingHours.interestShortPosition) * 200 / (dataSymbol.price.bid + dataSymbol.price.ask), dataSymbol.type === 'FOREX' ? 6 : 4, true)}
                        spacing={true}
                    />
                    <RowData
                        labelKey="long-position-swap"
                        value={formatNumberWithPercent(Number(tradingHours.interestLongPosition) * 200 / (dataSymbol.price.bid + dataSymbol.price.ask), dataSymbol.type === 'FOREX' ? 6 : 4, true)}
                        spacing={true}
                    />
                    <RowData
                        labelKey="margin"
                        value={dataSymbol.margin ? formatNumberWithPercent(dataSymbol.margin * 100, 2, isMarketOpen) : ''}
                        spacing={true}
                        lastRow={isMarketOpen}
                    />
                    {isMarketOpen === false ? (
                        <RowData labelKey="next-trading-session" value={formatDate(openMarket)} lastRow={true} />
                    ) : null}
                </View>
            </View>
        </ScrollbarWrapper>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        descriptionContainer: { paddingTop: scaleSize(10) },
        start: { justifyContent: 'flex-start', alignItems: 'center', paddingLeft: scaleSize(10) },
        center: { justifyContent: 'center' },
        end: { justifyContent: 'center', paddingRight: scaleSize(10), alignItems: 'center' },
        starBackgroundActive: { backgroundColor: theme.tabIconSelected },
        starBackgroundUnactive: { backgroundColor: theme.tabIconDefault },
        starBackground: {
            width: scaleSize(20),
            height: scaleSize(20),
            borderRadius: scaleSize(10),

            justifyContent: 'center',
            alignItems: 'center',
        },
        activeStar: { color: theme.white },
        unActiveStar: { color: theme.switch_tumbOn },
        symbolColor: { color: theme.text_symbol },
        liveStatDetailText: { fontSize: FONT_SIZE_XXS },
        sectionTitleText: { fontSize: FONT_SIZE_M, color: theme.text_symbol },
        sectionContainer: { marginHorizontal: scaleSize(10), marginTop: scaleSize(20) },
        symbolName: { fontSize: FONT_SIZE_XL, color: theme.text_symbol },
        symbolDescription: { fontSize: FONT_SIZE_S, color: theme.instrumentDescription },
        symbolRow: { flexDirection: 'row', justifyContent: 'space-between', width: '100%' },
        title: {
            justifyContent: 'center',
            alignItems: 'center',
            width: isDesktop() ? '98.13%' : '100%',
            backgroundColor: theme.backgroundSecondary,
            paddingTop: scaleSize(20),
            paddingBottom: scaleSize(10),
            paddingHorizontal: scaleSize(7),
        },
        container: { width: '100%' },
        buyButtonContainer: { backgroundColor: theme.text_upPrice },
        sellButtonContainer: { backgroundColor: theme.text_downPrice },
        buySellContainer: {
            height: scaleSize(70),
            marginHorizontal: scaleSize(7),
            marginTop: scaleSize(7),
            borderRadius: scaleSize(5),
            backgroundColor: theme.backgroundSecondary,
            flexDirection: 'row',
        },
    });
