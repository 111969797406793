import React, { useState } from 'react';
import { Pressable, StyleSheet, View, useWindowDimensions } from 'react-native';

import { spacing, FONT_SIZE_L } from '~/constants';
import { useAppSelector, useStyles } from '~/hooks';
import { appSettingsSelector, accountBalanceSelector, userAuthDataSelector } from '~/state/selectors';
import { Text } from '~/components/core';
import AccountBalanceDialog from '../../AccountBalanceDialog';
import { Theme } from '~/theme';
import { scaleSize } from '~/constants/scale';
import { formatCurrency, formatNumberWithPercent } from '~/lib/format';
import { Entypo, Ionicons } from "~/components/core/Icons";
import Wallet from './Wallet';
import { useRoute } from '@react-navigation/native';

export default function MarketHeader() {
    const route: any = useRoute();
    const styles = useStyles(stylesGenerator, route);
    const { selectedBalance, hideBalance } = useAppSelector(appSettingsSelector);
    const balanceValues = useAppSelector(accountBalanceSelector);
    const [accountBalanceVisible, setAccountBalanceVisible] = useState(false);
    const [walletVisible, setWalletVisible] = useState(false);
    const dimensions = useWindowDimensions();
    const { oldVersion } = useAppSelector(userAuthDataSelector);

    const balanceValue =
        selectedBalance === 'balance'
            ? formatCurrency(balanceValues?.accountBalance, 2, balanceValues?.currency)
            : selectedBalance === 'equity'
            ? formatCurrency(balanceValues?.equity, 2, balanceValues?.currency)
            : selectedBalance === 'margin'
            ? formatCurrency(balanceValues?.usedMargin, 2, balanceValues?.currency)
            : selectedBalance === 'marginLevel'
            ? balanceValues?.level === 100000 ? '∞' : formatNumberWithPercent(balanceValues?.level, 2, false)
            : selectedBalance === 'freeMargin'
            ? balanceValues?.equity && balanceValues?.usedMargin
                ? formatCurrency(balanceValues?.equity - balanceValues?.usedMargin, 2, balanceValues?.currency)
                : formatCurrency(0, 2, balanceValues?.currency)
            : formatCurrency(balanceValues?.potentialProfitLoss, 2, balanceValues?.currency);

    return (
        <>
            <View style={[styles.container]}>
                <Pressable onPress={() => setAccountBalanceVisible(true)} style={styles.profileContainer}>
                    <Ionicons name="person-circle-outline" style={styles.profileIconColor} size={scaleSize(22)} />
                </Pressable>
                {!hideBalance && balanceValues && (
                    <View style={styles.balanceContainer}>
                        <Pressable onPress={() => setAccountBalanceVisible(true)}>
                            <Text fontType="LATO_BOLD" style={styles.balanceText}>
                                {balanceValue}
                            </Text>
                        </Pressable>
                    </View>
                )}

                <Pressable onPress={() => setWalletVisible(!walletVisible)} style={styles.toolbarContainer}>
                    <View style={styles.walletZone} >
                        <Entypo size={scaleSize(20)} name="dots-three-vertical" style={styles.walletMenuColor} />
                    </View>
                </Pressable>

                <Wallet isModalVisible={walletVisible} setVisible={setWalletVisible} />
            </View>
            { oldVersion && (
            <View style={styles.balanceContainer}>
                <Text fontType="LATO_BOLD" style={styles.updateAppText}>
                    Please, update the application!
                </Text>
            </View>
            )}
            {accountBalanceVisible ? (
                <Pressable
                    style={[styles.blurScreen, { height: dimensions.height }]}
                    onPress={() => setAccountBalanceVisible(false)}
                />
            ) : null}
            <AccountBalanceDialog
                isModalVisible={accountBalanceVisible}
                balanceValues={balanceValues}
                setAccountBalanceVisible={setAccountBalanceVisible}
            />
        </>
    );
}

const stylesGenerator = (theme: Theme, route: any) =>
    StyleSheet.create({
        blurScreen: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            backgroundColor: theme.background,
            opacity: 0.6,
        },
        walletZone: { width: '100%', height: scaleSize(22), justifyContent: 'center', alignItems: 'flex-end' },
        walletMenuColor: {
            color: theme.background_positionsCount,
        },
        profileContainer: {
            backgroundColor: theme.tabIconSelected,
            height: scaleSize(24),
            width: scaleSize(24),
            borderRadius: scaleSize(12),
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: scaleSize(6),
        },
        profileIconColor: {
            color: theme.white,
        },
        container: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingHorizontal: scaleSize(spacing.MEDIUM),
            backgroundColor: theme.backgroundSecondary,
            paddingTop: scaleSize(spacing.MEDIUM),
        },
        balanceContainer: {
            flex: 1,
        },
        balanceText: {
            fontSize: FONT_SIZE_L,
            color: theme.text_balance,
        },
        toolbarContainer: {
            flex: 1,
            justifyContent: 'flex-end',
        },
        switchButton: {
            marginLeft: spacing.MEDIUM,
        },
        updateAppText: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingHorizontal: scaleSize(spacing.MEDIUM),
            backgroundColor: theme.backgroundSecondary,
            paddingTop: scaleSize(spacing.MEDIUM),
            fontSize: FONT_SIZE_L,
            color: theme.text_downPrice,
        },
    });
