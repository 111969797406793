import React from 'react';
import { StyleSheet, View, Pressable } from 'react-native';
import { Text } from '~/components/core';
import { FONT_SIZE_L, FONT_SIZE_XL } from '~/constants';
import { isDesktop, scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { Entypo } from "~/components/core/Icons";

type Props = {
    side: string;
    setOpenBuySellType?: Function;
};

export default function DesktopBackHeader({ side, setOpenBuySellType }: Props) {
    const styles = useStyles(stylesGenerator);
    if (isDesktop()) {
        return (
            <View style={styles.title}>
                <Pressable
                    onPress={() => {
                        setOpenBuySellType ? setOpenBuySellType('') : null;
                    }}
                    style={styles.backContainer}
                >
                    <Entypo size={scaleSize(20)} name="chevron-left" style={styles.backArrow} />
                </Pressable>
                <View style={styles.headerTitle}>
                    <Text fontType="LATO_BOLD" style={styles.headerText}>
                        {side === 'buy' ? 'buy-section' : 'sell-section'}
                    </Text>
                </View>
            </View>
        );
    } else {
        return null;
    }
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        headerTitle: { width: '82.13%', justifyContent: 'center', alignItems: 'center' },
        headerText: { color: theme.tabIconDefault, fontSize: FONT_SIZE_L },
        backArrow: { color: theme.tabIconDefault },
        backContainer: {
            width: '8%',
            height: scaleSize(20),
            paddingLeft: scaleSize(8),
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        title: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '98.13%',
            flexDirection: 'row',
            backgroundColor: theme.backgroundSecondary,
            height: scaleSize(30),
        },
        side: { fontSize: FONT_SIZE_XL, letterSpacing: 0, color: theme.white },
        symbol: { fontSize: FONT_SIZE_XL, color: theme.text_symbol, letterSpacing: 0 },
    });
