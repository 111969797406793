import React, { useEffect, useState } from 'react';
import { View, StyleSheet, KeyboardAvoidingView, Platform } from 'react-native';

import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { CustomerDataResponse } from '~/api-services/VerificationService/types';
import { Button, ErrorMessage, Switch, Text, TextInput } from '~/components/core';
import { FONT_SIZE_M, FONT_SIZE_S, FONT_SIZE_XS } from '~/constants';
import { Picker } from '@react-native-picker/picker';
import { generateBDateDayOptions, generateBDateYearOptions, generateMonthOptions } from '../AddFundsCard/AddFundsUtil';
import i18n from '~/i18n';
import { countries } from '../Authentication/RegistrationForm/countries';
import ResolveCountryService from '~/api-services/ResolveCountryService';
import { AuthDataService } from '~/api-services';
import { countries as globalCountries } from '~/constants/global';
import VerificationService from '~/api-services/VerificationService';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import { SafeAreaView } from 'react-native-safe-area-context';

type Props = {
    customerData: CustomerDataResponse;
    goToNextStep: Function;
    setLoading: Function;
};

export default function PersonalInformation({ customerData, goToNextStep, setLoading }: Props) {
    const styles = useStyles(stylesGenerator);
    const [generalError, setGeneralError] = useState('');
    const [showFirstNameValidationMessage, setShowFirstNameValidationMessage] = useState(false);
    const [showLastNameValidationMessage, setShowLastNameValidationMessage] = useState(false);
    const [showInvalidDOBValidationMessage, setShowInvalidDOBValidationMessage] = useState(false);
    const [showDOBValidationMessage, setShowDOBValidationMessage] = useState(false);
    const [showPhoneValidationMessage, setShowPhoneValidationMessage] = useState(false);
    const [showAddressValidationMessage, setShowAddressValidationMessage] = useState(false);
    const [showPostalValidationMessage, setShowPostalValidationMessage] = useState(false);
    const [showCitylValidationMessage, setShowCitylValidationMessage] = useState(false);
    const [showCountryValidationMessage, setShowCountryValidationMessage] = useState(false);
    const [showCitizenshipValidationMessage, setShowCitizenshipValidationMessage] = useState(false);
    const [showPliticallyExposedPersonDescriptionValidationMessage, setShowPliticallyExposedPersonDescriptionValidationMessage] = useState(false);
    const [showConnectedPliticallyExposedPersonDescriptionValidationMessage, setShowConnectedPliticallyExposedPersonDescriptionValidationMessage] = useState(false);

    const [pendingRequest, setPendingRequest] = useState(false);

    const [profileFirstName, setProfileFirstName] = useState('');
    const [profileLastName, setProfileLastName] = useState('');
    const [birthdayDay, setBirthdayDay] = useState('');
    const [birthdayMonth, setBirthdayMonth] = useState('');
    const [birthdayYear, setBirthdayYear] = useState('');
    const [dob, setDob] = useState('');
    const [profilePhone, setProfilePhone] = useState('');
    const [phonePlaceholder, setPhonePlaceholder] = useState('registration-input-enter-phone');
    const [countriesList, setCountriesList] = useState<countries[]>([]);
    const [phonePrefix, setPhonePrefix] = useState('');
    const [wrongPhone, setWrongPhone] = useState(false);

    const [profileAddress, setProfileAddress] = useState('');
    const [profileZipCode, setProfileZipCode] = useState('');
    const [profileCity, setProfileCity] = useState('');
    const [registrationCountry, setRegistrationCountry] = useState('');
    const [registrationCitizenship, setRegistrationCitizenship] = useState('');
    const [politicallyExposedPerson, setPoliticallyExposedPerson] = useState(false);
    const [politicallyExposedPersonDescription, setPoliticallyExposedPersonDescription] = useState('');
    const [connectedWithPoliticallyExposedPerson, setConnectedWithPoliticallyExposedPerson] = useState(false);
    const [connectedWithPoliticallyExposedPersonDescription, setConnectedWithPoliticallyExposedPersonDescription] = useState('');


    useEffect(() => {
        (async () => {
            if (phonePrefix === '' && countriesList.length === 0) {
                const { status, data } = await ResolveCountryService.resolveCountry();
                if (status === 200 && data.status) {
                    let cList: countries[] = ResolveCountryService.getCountriesList();
                    setCountriesList(cList);
                    let currentCountry: countries | undefined = cList.find(
                        (country) => country.abbreviation === data.returnData
                    );
                    if (currentCountry) {
                        setPhonePrefix('+' + currentCountry.phonePrefix);
                        setPhonePlaceholder('+' + currentCountry.phonePrefix + ' | Phone Number');
                    }
                }
            }
        })();
    }, [countriesList, phonePrefix]);


    useEffect(() => {
        if (customerData) {
            if (customerData.firstname) {
                setProfileFirstName(customerData.firstname);
            }
            if (customerData.lastname) {
                setProfileLastName(customerData.lastname);
            }
            if (customerData.birthday != null) {
                var birthday = new Date(customerData.birthday);

                var year: string = birthday.getFullYear().toString();
                console.log(year)
                var month: string = (birthday.getMonth() + 1).toString();
                if (month.length < 2) {
                    month = '0' + month;
                }
                var day: string = birthday.getDate().toString();
                if (day.length < 2) {
                    day = '0' + day;
                }
                setBirthdayYear(year);
                setBirthdayMonth(month);
                setBirthdayDay(day);
                setDob(year + '-' + month + '-' + day);
            }
            if (customerData.phone != undefined) {
                if (customerData.phone.phone) {
                    setProfilePhone(customerData.phone.phone);
                    onChangePhoneNumber(customerData.phone.phone);
                }
            }
            if (customerData.address != undefined) {
                if (customerData.address.details) {
                    setProfileAddress(customerData.address.details);
                }
                if (customerData.address.postalCode) {
                    setProfileZipCode(customerData.address.postalCode);
                }
                if (customerData.address.city) {
                    setProfileCity(customerData.address.city);
                }
                if (customerData.address.country.code) {
                    setRegistrationCountry(customerData.address.country.code);
                }
            }
            if (customerData.nationality) {
                setRegistrationCitizenship(customerData.nationality);
            }
            if (customerData.politicallyExposedPerson) {
                setPoliticallyExposedPerson(customerData.politicallyExposedPerson);
                if (customerData.politicallyExposedPersonDescription) {
                    setPoliticallyExposedPersonDescription(customerData.politicallyExposedPersonDescription);
                }
            }
            if (customerData.connectedWithPoliticallyExposedPerson) {
                setConnectedWithPoliticallyExposedPerson(customerData.connectedWithPoliticallyExposedPerson);
                if (customerData.connectedWithPoliticallyExposedPersonDescription) {
                    setConnectedWithPoliticallyExposedPersonDescription(customerData.connectedWithPoliticallyExposedPersonDescription);
                }
            }
        }
    }, [customerData]);

    function onChangeBDateYear(text: string) {
        setBirthdayYear(text);
        let bdate = text + '-' + birthdayMonth + '-' + birthdayDay;
        setDob(bdate);
    }

    function onChangeBDateMonth(text: string) {
        setBirthdayMonth(text);
        let bdate = birthdayYear + '-' + text + '-' + birthdayDay;
        setDob(bdate);
    }
    function onChangeBDateDay(text: string) {
        setBirthdayDay(text);
        let bdate = birthdayYear + '-' + birthdayMonth + '-' + text;
        setDob(bdate);
    }

    function validateDob(): boolean {
        if (birthdayDay === '' && birthdayMonth === '' && birthdayYear === '') {
            setShowDOBValidationMessage(true);
            setShowInvalidDOBValidationMessage(false);
            return false;
        }
        else if (birthdayDay === '' || birthdayMonth === '' || birthdayYear === '') {
            setShowDOBValidationMessage(false);
            setShowInvalidDOBValidationMessage(true);
            return false;
        }
        else {
            setShowDOBValidationMessage(false);
            setShowInvalidDOBValidationMessage(false);
        }
        return true;
    }

    function validateForm(): boolean {
        let formValid: boolean = true;
        setGeneralError('');
        setShowFirstNameValidationMessage(false);
        setShowLastNameValidationMessage(false);
        setShowPhoneValidationMessage(false);
        setShowAddressValidationMessage(false);
        setShowPostalValidationMessage(false);
        setShowDOBValidationMessage(false);
        setShowInvalidDOBValidationMessage(false);
        setShowCitylValidationMessage(false);
        setShowCountryValidationMessage(false);
        setShowCitizenshipValidationMessage(false);
        setShowPliticallyExposedPersonDescriptionValidationMessage(false);
        setShowConnectedPliticallyExposedPersonDescriptionValidationMessage(false);

        if (profileFirstName === '') {
            formValid = false;
            setShowFirstNameValidationMessage(true);
        }
        if (profileLastName === '') {
            formValid = false;
            setShowLastNameValidationMessage(true);
        }
        if (profilePhone === '') {
            formValid = false;
            setShowPhoneValidationMessage(true);
        }
        if (profileAddress === '') {
            formValid = false;
            setShowAddressValidationMessage(true);
        }
        if (profileZipCode === '') {
            formValid = false;
            setShowPostalValidationMessage(true);
        }
        if (!validateDob()) {
            formValid = false;
        }
        if (profileCity === '') {
            formValid = false;
            setShowCitylValidationMessage(true);
        }
        if (registrationCountry === '') {
            formValid = false;
            setShowCountryValidationMessage(true);
        }
        if (registrationCitizenship === '') {
            formValid = false;
            setShowCitizenshipValidationMessage(true);
        }
        if (politicallyExposedPerson && politicallyExposedPersonDescription === '') {
            formValid = false;
            setShowPliticallyExposedPersonDescriptionValidationMessage(true);
        }
        if (connectedWithPoliticallyExposedPerson && connectedWithPoliticallyExposedPersonDescription === '') {
            formValid = false;
            setShowConnectedPliticallyExposedPersonDescriptionValidationMessage(true);
        }
        return formValid;
    }

    async function onChangePhoneNumber(text: string) {
        setPendingRequest(true);
        var newText = '';
        var numbers = '+0123456789';
        if (text.length < 1) {
            setProfilePhone('');
        }
        for (var i = 0; i < text.length; i++) {
            if (numbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
        }
        setProfilePhone(newText);
        for (i = 2; i < 5; i++) {
            let currentCountry: countries | undefined = countriesList.find(
                (country) => country.phonePrefix === newText.substring(1, i)
            );
            if (currentCountry) {
                setPhonePrefix('+' + currentCountry.phonePrefix);
                break;
            }
        }
        const { status, data } = await AuthDataService.validateProfilePhone({
            phone: newText,
        });
        if (status === 200) {
            setWrongPhone(!data.returnData);
        } else {
        }
        setPendingRequest(false);
    }


    async function setPersonalInformation() {
        if (validateForm()) {
            setLoading(true);
            const { status, data } = await VerificationService.setPersonalInformation(
                {
                    firstname: profileFirstName,
                    lastname: profileLastName,
                    phone: profilePhone,
                    addressDetails: profileAddress,
                    postalCode: profileZipCode,
                    city: profileCity,
                    countryCode: registrationCountry,
                    nationality: registrationCitizenship,
                    politicallyExposedPerson: politicallyExposedPerson,
                    politicallyExposedPersonDescription: politicallyExposedPersonDescription,
                    birthday: dob,
                    connectedWithPoliticallyExposedPerson: connectedWithPoliticallyExposedPerson,
                    connectedWithPoliticallyExposedPersonDescription: connectedWithPoliticallyExposedPersonDescription
                }
            );
            if (status === 200) {
                if (data.status === true) {
                    goToNextStep();
                }
                else {
                    if (data.errorCode) {
                        if (data.errorCode === 'ArgumentRequiredException') {
                            if (data.errorContext && data.errorContext.field) {
                                if (data.errorContext.field) {
                                    setGeneralError(i18n.t('please-fill-all-required-fields') + ': ' + data.errorContext.field);
                                }
                                else {
                                    setGeneralError(data.errorCode);
                                }
                            }
                            else {
                                setGeneralError(data.errorCode);
                            }
                        }
                        else {
                            setGeneralError(data.errorCode);
                        }
                    }
                }
            }
            else {
                setGeneralError('Error');
            }
            setLoading(false);
        }
    }

    return (
            <View style={styles.safeAreaStyle}>
                <View style={styles.vcTitleContainer}>
                    <Text fontType="LATO_BOLD" style={styles.vcTitleText}>
                        personal-information
                    </Text>
                </View>
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {i18n.t('first-name') + '*'}
                </Text>
                <TextInput
                    containerStyle={styles.inputContainer}
                    style={styles.textInputStyle}
                    placeholder="registration-input-enter-first-name"
                    autoCapitalize="none"
                    autoFocus={false}
                    value={profileFirstName}
                    onChangeText={(text) => setProfileFirstName(text)}
                    onBlur={() => setShowFirstNameValidationMessage(true)}
                    returnKeyType='next' />
                <ErrorMessage
                    text={'registration-first-name-missing'}
                    visible={profileFirstName === '' && showFirstNameValidationMessage} />
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {i18n.t('last-name') + '*'}
                </Text>
                <TextInput
                    containerStyle={styles.inputContainer}
                    style={styles.textInputStyle}
                    placeholder="registration-input-enter-last-name"
                    autoCapitalize="none"
                    autoFocus={false}
                    value={profileLastName}
                    onChangeText={(text) => setProfileLastName(text)}
                    onBlur={() => setShowLastNameValidationMessage(true)}
                    returnKeyType='next' />
                <ErrorMessage
                    text={'registration-last-name-missing'}
                    visible={profileLastName === '' && showLastNameValidationMessage} />
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {i18n.t('date-of-birth') + '*'}
                </Text>
                <View style={styles.bdateContainer}>
                    <View style={styles.bdatePickerContainer}>
                        <Picker
                            style={styles.pickerStyle}
                            itemStyle={styles.pickerRow}
                            selectedValue={birthdayYear}
                            onValueChange={(itemValue) => onChangeBDateYear(itemValue)}
                        >
                            <Picker.Item label={i18n.t('year')} value={''} />
                            {generateBDateYearOptions().map((item, index) => (
                                <Picker.Item
                                    label={item.label}
                                    value={item.value}
                                    key={'BDateYearPicker' + index}
                                />
                            ))}
                        </Picker>
                    </View>
                    <View style={styles.bdatePickerContainer}>
                        <Picker
                            style={styles.pickerStyle}
                            itemStyle={styles.pickerRow}
                            selectedValue={birthdayMonth}
                            onValueChange={(itemValue) => onChangeBDateMonth(itemValue)}
                        >
                            <Picker.Item label={i18n.t('month')} value={''} />
                            {generateMonthOptions().map((item, index) => (
                                <Picker.Item
                                    label={item.label}
                                    value={item.value}
                                    key={'BDateMonthPicker' + index}
                                />
                            ))}
                        </Picker>
                    </View>
                    <View style={styles.bdatePickerContainer}>
                        <Picker
                            style={styles.pickerStyle}
                            itemStyle={styles.pickerRow}
                            selectedValue={birthdayDay}
                            onValueChange={(itemValue) => onChangeBDateDay(itemValue)}
                        >
                            <Picker.Item label={i18n.t('day')} value={''} />
                            {generateBDateDayOptions().map((item, index) => (
                                <Picker.Item
                                    label={item.label}
                                    value={item.value}
                                    key={'BDateDayPicker' + index}
                                />
                            ))}
                        </Picker>
                    </View>
                </View>
                <ErrorMessage
                    text={'please-enter-date-ofbirth'}
                    visible={showDOBValidationMessage}
                />
                <ErrorMessage
                    text={'invalid-birth-date'}
                    visible={showInvalidDOBValidationMessage}
                />
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {i18n.t('phone') + '*'}
                </Text>
                <TextInput
                    containerStyle={styles.inputContainer}
                    style={styles.textInputStyle}
                    placeholder={phonePlaceholder}
                    autoCapitalize="none"
                    autoFocus={false}
                    value={profilePhone}
                    onChangeText={(text) => onChangePhoneNumber(text)}
                    onBlur={() => setShowPhoneValidationMessage(true)}
                    returnKeyType='next' />
                <ErrorMessage
                    text={'wrong-phone-message'}
                    visible={wrongPhone || (profilePhone === phonePrefix && showPhoneValidationMessage)} />
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {i18n.t('address') + '*'}
                </Text>
                <TextInput
                    containerStyle={styles.inputContainer}
                    style={styles.textInputStyle}
                    placeholder="please-enter-address"
                    autoCapitalize="none"
                    autoFocus={false}
                    value={profileAddress}
                    onChangeText={(text) => setProfileAddress(text)}
                    onBlur={() => setShowAddressValidationMessage(true)}
                    returnKeyType='next' />
                <ErrorMessage
                    text={'please-enter-address'}
                    visible={profileAddress === '' && showAddressValidationMessage} />
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {'postal-code'}
                </Text>
                <TextInput
                    containerStyle={styles.inputContainer}
                    style={styles.textInputStyle}
                    placeholder="please-enter-postal"
                    autoCapitalize="none"
                    autoFocus={false}
                    value={profileZipCode}
                    onChangeText={(text) => setProfileZipCode(text)}
                    onBlur={() => setShowPostalValidationMessage(true)}
                    returnKeyType='next' />
                <ErrorMessage
                    text={'please-enter-postal'}
                    visible={profileZipCode === '' && showPostalValidationMessage} />
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {i18n.t('city') + '*'}
                </Text>
                <TextInput
                    containerStyle={styles.inputContainer}
                    style={styles.textInputStyle}

                    placeholder="please-enter-city"
                    autoCapitalize="none"
                    autoFocus={false}
                    value={profileCity}
                    onChangeText={(text) => setProfileCity(text)}
                    onBlur={() => setShowCitylValidationMessage(true)}
                    returnKeyType='next' />
                <ErrorMessage
                    text={'please-enter-city'}
                    visible={profileCity === '' && showCitylValidationMessage} />
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {i18n.t('country') + '*'}
                </Text>
                <Picker
                    style={styles.pickerStyle}
                    itemStyle={styles.pickerRow}
                    selectedValue={registrationCountry}
                    onValueChange={(itemValue) => setRegistrationCountry(itemValue)}
                >
                    <Picker.Item
                        label={i18n.t('please-select-country')}
                        value={''}
                    />
                    {globalCountries.map((countryMap, index) => (
                        <Picker.Item
                            label={countryMap.label}
                            value={countryMap.value}
                            key={'CountryPicker' + index}
                        />
                    ))}
                </Picker>
                <ErrorMessage
                    text={'please-select-country'}
                    visible={registrationCountry === '' && showCountryValidationMessage}
                />
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {i18n.t('citizenship') + '*'}
                </Text>
                <Picker
                    style={styles.pickerStyle}
                    itemStyle={styles.pickerRow}
                    selectedValue={registrationCitizenship}
                    onValueChange={(itemValue) => setRegistrationCitizenship(itemValue)}
                >
                    <Picker.Item
                        label={i18n.t('please-select-citizenship')}
                        value={''}
                    />
                    {globalCountries.map((countryMap, index) => (
                        <Picker.Item
                            label={countryMap.label}
                            value={countryMap.value}
                            key={'CitizenshipPicker' + index}
                        />
                    ))}
                </Picker>
                <ErrorMessage
                    text={'please-select-citizenship'}
                    visible={registrationCitizenship === '' && showCitizenshipValidationMessage}
                />
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {i18n.t('are-political') + '*'}
                </Text>
                <View style={styles.yesNoContainer}>
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        {'no'}
                    </Text>
                    <Switch
                        value={politicallyExposedPerson}
                        onValueChange={() => {
                            setPoliticallyExposedPerson(!politicallyExposedPerson);
                        }}
                    />
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        {'yes'}
                    </Text>
                </View>
                {politicallyExposedPerson ?
                    <>
                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                            {i18n.t('public-function-description') + '*'}
                        </Text>
                        <TextInput
                            containerStyle={styles.inputContainer}
                            style={styles.textInputStyle}

                            placeholder="please-enter-public-function-description"
                            autoCapitalize="none"
                            autoFocus={false}
                            value={politicallyExposedPersonDescription}
                            onChangeText={(text) => setPoliticallyExposedPersonDescription(text)}
                            onBlur={() => setShowPliticallyExposedPersonDescriptionValidationMessage(true)}
                            returnKeyType='next' />
                        <ErrorMessage
                            text={'please-enter-public-function-description'}
                            visible={politicallyExposedPersonDescription === '' && showPliticallyExposedPersonDescriptionValidationMessage} />
                    </>
                    : null}
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {i18n.t('are-political-connected') + '*'}
                </Text>
                <View style={styles.yesNoContainer}>
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        {'no'}
                    </Text>
                    <Switch
                        value={connectedWithPoliticallyExposedPerson}
                        onValueChange={() => {
                            setConnectedWithPoliticallyExposedPerson(!connectedWithPoliticallyExposedPerson);
                        }}
                    />
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        {'yes'}
                    </Text>
                </View>
                {connectedWithPoliticallyExposedPerson ?
                    <>
                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                            {i18n.t('public-function-description') + '*'}
                        </Text>
                        <TextInput
                            containerStyle={styles.inputContainer}
                            style={styles.textInputStyle}

                            placeholder="please-enter-public-function-description"
                            autoCapitalize="none"
                            autoFocus={false}
                            value={connectedWithPoliticallyExposedPersonDescription}
                            onChangeText={(text) => setConnectedWithPoliticallyExposedPersonDescription(text)}
                            onBlur={() => setShowConnectedPliticallyExposedPersonDescriptionValidationMessage(true)}
                            returnKeyType='next' />
                        <ErrorMessage
                            text={'please-enter-public-function-description'}
                            visible={connectedWithPoliticallyExposedPersonDescription === '' && showConnectedPliticallyExposedPersonDescriptionValidationMessage} />
                    </>
                    : null}
                <ErrorMessage
                    text={generalError}
                    visible={generalError !== ''}
                />
                <View style={styles.buttonsContainer}>
                    <View style={styles.buttonContainer}>
                        <Button
                            onPress={setPersonalInformation}
                            title="next"
                            disabled={pendingRequest || wrongPhone}
                        />
                    </View>
                </View>
            </View>

    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        safeAreaStyle: { flex: 1, paddingHorizontal: scaleSize(Platform.OS !== 'web' ? 0 : 12), },
        viewContainer: { justifyContent: 'space-between', height: '80%', paddingBottom: scaleSize(10) },
        scrollView: { maxHeight: '100%', height: '100%' },
        container: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingHorizontal: scaleSize(17),
            height: '100%',
            width: '100%',
        },
        safeArea: { flex: 1 },
        yesNoContainer: { flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: '25%' },
        buttonsContainer: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingVertical: scaleSize(20),
        },
        buttonContainer: {
            paddingHorizontal: scaleSize(10),
            width: scaleSize(150),
            height: scaleSize(40),
        },
        pickerRow: {
            width: '100%',
            height: Platform.OS !== 'ios' ? scaleSize(40) : 3 * scaleSize(40),
            fontSize: scaleSize(FONT_SIZE_S),
            color: theme.text
        },
        pickerStyle: {
            width: '100%',
            height: Platform.OS !== 'ios' ? scaleSize(40) : 3 * scaleSize(40),
            fontSize: scaleSize(FONT_SIZE_M),
            backgroundColor: theme.backgroundSecondary,
            color: theme.text_pendingOrder,
            borderRadius: scaleSize(5),
            padding: 0,
            marginBottom: scaleSize(10)
        },
        textInputStyle: {
            fontSize: scaleSize(FONT_SIZE_M),
            color: theme.text_pendingOrder,
            width: '100%'
        },
        labelText: { fontSize: FONT_SIZE_XS, color: theme.text_symbol, paddingVertical: scaleSize(10) },
        bdateContainer: { flexDirection: 'row', justifyContent: 'space-between' },
        bdatePickerContainer: { width: '31%' },
        vcTitleContainer: {
            justifyContent: 'center',
            paddingVertical: scaleSize(20),
        },
        vcTitleText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        inputContainer: {
            marginBottom: scaleSize(10),
        },
        keyBoard: { width: '100%' },

    });
