import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Platform, Pressable, Image } from 'react-native';
import { useAppDispatch, useAppSelector, useStyles } from '~/hooks';
import { Button, Switch, Text } from '~/components/core';
import { scaleSize, isDesktop } from '~/constants/scale';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import Header from '../More/Header';
import DesktopScreenHeader from '../Desktop/DesktopScreenHeader';
import { appSettingsSelector } from '~/state/selectors';
import { Entypo, MaterialIcons } from "~/components/core/Icons";
import { FONT_SIZE_S } from '~/constants';
import { Theme } from '~/theme';
import LanguagePicker from '../Desktop/LanguagePicker';
import { saveAppSettings } from '~/state/reducers/appSettingsSlice';

type Props = {
    setActiveRightTab?: Function;
};
export default function Settings({ setActiveRightTab }: Props) {
    const styles = useStyles(stylesGenerator);
    const { language, bigChartScreen } = useAppSelector(appSettingsSelector);
    const [openLanguageMenu, setOpenLanguageMenu] = useState(false);
    const dispatch = useAppDispatch();
    const [chartTypeSwith, setChartTypeSwith] = useState<true | false | undefined>(true);
    const [loadSettings, setLoadSettings] = useState(false);

    useEffect(() => {
            if (!loadSettings) {
                setChartTypeSwith(bigChartScreen);
            }
        }, [loadSettings, bigChartScreen]);


    return (
        <>
            {!isDesktop() ? <Header /> : null}
            <DesktopScreenHeader goToScreenName="more" title="settings" setActiveRightTab={setActiveRightTab} />
            <View style={styles.container}>

                <View style={styles.viewContainer}>
                    <Pressable style={styles.languageContainer} onPress={() => setOpenLanguageMenu(!openLanguageMenu)}>
                        {language === 'de' ? (
                            <Image
                                source={require('../../../../assets/images/flags/de.png')}
                                style={styles.languageFlag}
                            />
                        ) : language === 'fr' ? (
                            <Image
                                source={require('../../../../assets/images/flags/fr.png')}
                                style={styles.languageFlag}
                            />
                        ) : language === 'ru' ? (
                            <Image
                                source={require('../../../../assets/images/flags/ru.png')}
                                style={styles.languageFlag}
                            />
                        ) : language === 'es' ? (
                            <Image
                                source={require('../../../../assets/images/flags/es.png')}
                                style={styles.languageFlag}
                            />
                        ) : (
                            <Image
                                source={require('../../../../assets/images/flags/en.png')}
                                style={styles.languageFlag}
                            />
                        )}
                        <Text fontType="LATO_REGULAR" style={styles.languageText}>
                            {'language-' + language}
                        </Text>
                        <Entypo name="chevron-down" size={14} style={styles.languagePicker} />
                    </Pressable>
                    { false && isDesktop() ?
                    (<View style={styles.buttonContainer}>
                        <View style={styles.switchContainer}>
                            <View style={styles.pressableArea}>
                                <View style={styles.textPadding}>
                                    <Text fontType="LATO_REGULAR" style={[styles.swithText]}>
                                        chart-view
                                    </Text>
                                </View>
                                <View style={styles.textPadding}>
                                <MaterialIcons
                                    name="window"
                                    size={scaleSize(14)}
                                    style={styles.chartTypeIcon}
                                />
                                </View>
                                <Switch
                                    value={chartTypeSwith}
                                    style={styles.switchButton}
                                    onValueChange={(value) => {
                                        setChartTypeSwith(value );
                                        dispatch(saveAppSettings({ bigChartScreen: value }));
                                    }}
                                />
                                <View style={styles.textPadding}>
                                <MaterialIcons
                                    name="crop-square"
                                    size={scaleSize(14)}
                                    style={styles.chartTypeIcon}
                                />
                                </View>
                            </View>
                        </View>
                    </View>) : null }
                    {openLanguageMenu ?
                        <LanguagePicker setOpenLanguageMenu={setOpenLanguageMenu} /> : null
                    }
                    <View style={styles.buttonContainer}>
                        <Button
                            title="change-password"
                            onPress={() =>
                                isDesktop() && setActiveRightTab
                                    ? setActiveRightTab('change-password')
                                    : linkTo(paths.changePassword)
                            }
                        />
                    </View>
                    {Platform.OS !== 'web' ? (
                        <View style={styles.buttonContainer}>
                            <Button
                                title="notifications-label"
                                onPress={() =>
                                    isDesktop() && setActiveRightTab
                                        ? setActiveRightTab('notifications-settings')
                                        : linkTo(paths.notificationsSettings)
                                }
                            />
                        </View>
                    ) : null}

                </View>
            </View>
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        chartTypeIcon: { color: theme.desktopHeaderTextColor },
        swithText: { fontSize: FONT_SIZE_S, justifyContent: 'flex-start' },
        textPadding: { paddingHorizontal: 10 },
        pressableArea: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 40,
        },
        switchContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        switchButton: {
            justifyContent: 'flex-end',
        },
        languagePicker: { color: theme.languagePicker, marginLeft: 5 },
        languageText: { fontSize: FONT_SIZE_S, color: theme.desktopHeaderTextColor },
        languageFlag: { width: 30, height: 20, marginRight: 10 },
        languageContainer: { paddingVertical: scaleSize(10), flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
        buttonContainer: { paddingBottom: scaleSize(10) },
        container: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingHorizontal: scaleSize(17),
            height: '100%',
        },
        viewContainer: { height: '90%', justifyContent: 'center' },
    });
