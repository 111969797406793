import React, { useState } from 'react';
import { View, StyleSheet, Pressable, Linking } from 'react-native';
import CopyTradingService from '~/api-services/CopyTradingService';
import { Button, Text } from '~/components/core';
import Checkbox from '~/components/core/Checkbox';
import config from '~/config';
import { FONT_SIZE_S, FONT_SIZE_XL, FONT_SIZE_XS, FONT_SIZE_XXXS, FONT_SIZE_XXXXL } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { FontAwesome, Fontisto, Ionicons, Feather } from "~/components/core/Icons";
import t from '~/i18n';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';

type Props = {
    setConfirmation: Function;
    followInvestorUID: string;
    addFollower: Function;
};

export default function ConfirmationCopyTrading({ setConfirmation, followInvestorUID, addFollower }: Props) {
    const styles = useStyles(stylesGenerator);
    const [confirmationChecked, setConfirmationChecked] = useState(false);

    async function confirm() {
        await CopyTradingService.confirm();
        const { status, data } = await CopyTradingService.confirm();
        if (status === 200 && data.status) {
            await addFollower(followInvestorUID, '');
            setConfirmation(false);
        }
    }
    return (
        <ScrollbarWrapper style={styles.container}>
            <View style={styles.headContainer}>
                <Text fontType="LATO_ITALIC_BOLD" style={styles.headText}>
                    {t.t('copy-trading-confirmation-screen-head-title-1')}
                </Text>
                <Text fontType="LATO_ITALIC_BOLD" style={styles.headText}>
                    {t.t('copy-trading-confirmation-screen-head-title-2')}
                </Text>
            </View>
            <View style={styles.headContainer2}>
                <View style={styles.head2RowContainer}>
                    <Feather name={'check-circle'} size={scaleSize(22)} style={styles.headIcon} />
                    <Text fontType="LATO_REGULAR" style={styles.headRowText}>
                        copy-trading-confirmation-text1
                    </Text>
                </View>
                <View style={styles.head2RowContainer}>
                    <FontAwesome name={'id-card-o'} size={scaleSize(20)} style={styles.headIcon} />
                    <Text fontType="LATO_REGULAR" style={styles.headRowText}>
                        copy-trading-confirmation-text2
                    </Text>
                </View>
                <View style={styles.head2RowContainer}>
                    <Fontisto name={'line-chart'} size={scaleSize(20)} style={styles.headIcon} />
                    <Text fontType="LATO_REGULAR" style={styles.headRowText}>
                        copy-trading-confirmation-text3
                    </Text>
                </View>
            </View>
            <View style={styles.secureIconContainer}>
                <View style={styles.secureLine} />
                <View style={styles.helfBackground} />
                <View style={styles.secureIconBackground}>
                    <View style={styles.secureIconRing}>
                        <Ionicons name={'shield-checkmark'} size={scaleSize(30)} style={styles.secureIcon} />
                    </View>
                </View>
            </View>
            <View style={styles.contentContainer}>
                <View style={styles.pleaseConfirm}>
                    <Text fontType="LATO_ITALIC" style={styles.confirmSectionTitle}>
                        please-confirm-ct-agreement
                    </Text>
                </View>
                <View style={styles.checkboxContainer}>
                    <Checkbox
                        checked={confirmationChecked}
                        setChecked={() => {
                            setConfirmationChecked(!confirmationChecked);
                        }}
                    />
                    <View>
                        <View style={styles.checkboxContainerRow1}>
                            <Text fontType="LATO_REGULAR" style={styles.checkboxtext}>
                                confirm-ct-agreement-1
                            </Text>
                            <Pressable onPress={() => Linking.openURL(`${config.COPY_TRADING_AGREEMENT}`)}>
                                <Text fontType="LATO_REGULAR" style={styles.checkboxtextGreen}>
                                    confirm-ct-agreement-2
                                </Text>
                            </Pressable>
                        </View>
                        <Pressable
                            onPress={() => Linking.openURL(`${config.COPY_TRADING_AGREEMENT}`)}
                            style={styles.linkPart3}
                        >
                            <Text fontType="LATO_REGULAR" style={styles.checkboxtextGreen}>
                                confirm-ct-agreement-3
                            </Text>
                        </Pressable>
                    </View>
                </View>
                <Button
                    disabled={!confirmationChecked}
                    onPress={() => {
                        confirm();
                    }}
                    title={'position-confirm'}
                    titleSize={FONT_SIZE_XXXXL}
                />
                <View style={[styles.closeTradeContainer]}>
                    <Pressable
                        style={() => [
                            [
                                styles.background_closeTradeButton,
                                styles.closeTradeButtonWidth,
                                styles.background_closeTradeButtonUnPressed,
                            ],
                        ]}
                        onPress={() => {
                            setConfirmation(false);
                        }}
                    >
                        <Text fontType="LATO_BOLD" style={styles.background_closeTradeButtonText}>
                            cancel
                        </Text>
                    </Pressable>
                </View>
                <View style={styles.footerTextContainer}>
                    <Text fontType="LATO_ITALIC" style={styles.footerText}>
                        copy-trading-confirmation-footer-text1
                    </Text>
                    <Text fontType="LATO_ITALIC" style={styles.footerText}>
                        copy-trading-confirmation-footer-text2
                    </Text>
                </View>
            </View>
        </ScrollbarWrapper>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        closeTradeButtonWidth: { width: scaleSize(100) },
        background_closeTradeButtonUnPressed: {
            backgroundColor: theme.background_closeTradeButtonPressed,
        },
        closeTradeContainer: {
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: scaleSize(20),
        },
        background_closeTradeButtonText: {
            color: theme.background_closeTradeButton,
        },
        background_closeTradeButton: {
            justifyContent: 'center',
            alignItems: 'center',
            height: scaleSize(25),
            borderRadius: scaleSize(2),
            borderColor: theme.background_closeTradeButton,
            borderWidth: 1,
        },
        linkPart3: { paddingLeft: scaleSize(10) },
        checkboxContainerRow1: { flexDirection: 'row' },
        checkboxtextGreen: { fontSize: FONT_SIZE_XS, color: theme.tabIconSelected },
        footerText: {
            fontSize: FONT_SIZE_XXXS,
            color: theme.wallet_text,
        },
        footerTextContainer: { paddingVertical: scaleSize(12), justifyContent: 'center', alignItems: 'center' },
        checkboxtext: { fontSize: FONT_SIZE_XS, color: theme.wallet_text, paddingLeft: scaleSize(10) },
        confirmSectionTitle: { fontSize: FONT_SIZE_S, color: theme.wallet_text },
        pleaseConfirm: {
            paddingTop: scaleSize(20),
            paddingBottom: scaleSize(15),
            justifyContent: 'center',
            alignItems: 'center',
        },
        contentContainer: { paddingHorizontal: scaleSize(25), backgroundColor: theme.background },
        headRowText: {
            paddingLeft: scaleSize(10),
            fontSize: FONT_SIZE_XS,
            color: theme.text_positionsCount,
        },
        headIcon: { color: theme.text_positionsCount },
        head2RowContainer: { flexDirection: 'row', height: '33.33%', alignItems: 'center' },
        secureLine: {
            width: '100%',
            height: 1,
            backgroundColor: theme.switch_trackOn,
            top: scaleSize(25),
        },
        helfBackground: {
            backgroundColor: theme.background_closeTradeButtonPressed,
            height: scaleSize(25),
            position: 'absolute',
            width: '100%',
            top: 0,
        },
        secureIconRing: {
            width: scaleSize(44),
            height: scaleSize(44),
            borderWidth: 1,
            borderColor: theme.white,
            borderRadius: scaleSize(22),
            justifyContent: 'center',
            alignItems: 'center',
        },
        secureIconBackground: {
            width: scaleSize(50),
            height: scaleSize(50),
            backgroundColor: theme.switch_trackOn,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: scaleSize(25),
        },
        secureIconContainer: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        secureIcon: { color: theme.white },
        headText: { fontSize: FONT_SIZE_XL, color: theme.white },
        headContainer: {
            width: '100%',
            backgroundColor: theme.switch_trackOn,
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: scaleSize(20),
            paddingHorizontal: scaleSize(15),
            textAlign: 'center',
        },
        headContainer2: {
            height: scaleSize(140),
            backgroundColor: theme.background_closeTradeButtonPressed,
            paddingHorizontal: scaleSize(28),
        },
        container: {
            flex: 1,
            height: '100%',
            justifyContent: 'flex-start',
            alignSelf: 'center',
            width: '100%',
        },
        checkboxContainer: {
            paddingBottom: scaleSize(20),
            justifyContent: 'flex-start',
            flexDirection: 'row',
        },
    });
