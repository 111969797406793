import React, { useState, useEffect } from 'react';
import { View, StyleSheet, KeyboardAvoidingView, Platform } from 'react-native';

import { isDesktop, scaleSize } from '~/constants/scale';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useStyles } from '~/hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Theme } from '~/theme';
import { CustomerDataResponse } from '~/api-services/VerificationService/types';
import { Button, ErrorMessage, Switch, Text, TextInput } from '~/components/core';
import { FONT_SIZE_M, FONT_SIZE_S, FONT_SIZE_XS } from '~/constants';
import VerificationService from '~/api-services/VerificationService';
import i18n from '~/i18n';
import Checkbox from '~/components/core/Checkbox';
import { Picker } from '@react-native-picker/picker';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';

type Props = {
    customerData: CustomerDataResponse;
    goToNextStep: Function;
    goToPrevStep: Function;
    tradingExperianceAgreement: boolean;
    setTradingExperianceAgreement: Function;
    setLoading: Function;
    tradingExperianceStep: number;
    setTradingExperianceStep: Function;
};

export default function TradingExperiance({ customerData, goToNextStep, goToPrevStep, tradingExperianceAgreement, setTradingExperianceAgreement, setLoading, tradingExperianceStep, setTradingExperianceStep }: Props) {
    const styles = useStyles(stylesGenerator);

    const [generalError, setGeneralError] = useState('');


    //Step2 Begin
    const [levelOfEducationAndQualification, setLevelOfEducationAndQualification] = useState('');
    const [financialInstrumentsTraded, setFinancialInstrumentsTraded] = useState('');//New
    const [financialInstrumentsTradedDescription, setFinancialInstrumentsTradedDescription] = useState('');//New
    const [howTradedInstruments, setHowTradedInstruments] = useState('');//New
    const [believeInKnowledge, setBelieveInKnowledge] = useState(false);//New
    const [howCharacterizeKnowledge, setHowCharacterizeKnowledge] = useState('');//New
    const [experienceWithForexLeveragedCFDs, setExperienceWithForexLeveragedCFDs] = useState('');
    const [whereGatherTradingExperienceForexLeveragedCFDs, setWhereGatherTradingExperienceForexLeveragedCFDs] = useState('');

    const [showLevelOfEducationAndQualificationValidationMessage, setShowLevelOfEducationAndQualificationValidationMessage] = useState(false);
    const [showFinancialInstrumentsTradedValidationMessage, setShowFinancialInstrumentsTradedValidationMessage] = useState(false);
    const [showHowTradedInstrumentsValidationMessage, setShowHowTradedInstrumentsValidationMessage] = useState(false);
    const [showHowCharacterizeKnowledgeValidationMessage, setShowHowCharacterizeKnowledgeValidationMessage] = useState(false);
    const [showExperienceWithForexLeveragedCFDsValidationMessage, setShowExperienceWithForexLeveragedCFDsValidationMessage] = useState(false);
    const [showWhereGatherTradingExperienceForexLeveragedCFDsValidationMessage, setShowWhereGatherTradingExperienceForexLeveragedCFDsValidationMessage] = useState(false);
    //Step2 End

    //Step3 Begin
    const [howManyTransactions, setHowManyTransactions] = useState('');//New
    const [approximateValueInEUR, setApproximateValueInEUR] = useState('');//New
    const [experienceWithSharesBonds, setExperienceWithSharesBonds] = useState('');
    const [whereGatherTradingExperienceSharesBonds, setWhereGatherTradingExperienceSharesBonds] = useState('');
    const [optionsFuturesETDsExperience, setOptionsFuturesETDsExperience] = useState('');//New
    const [whereGatherTradingExperienceOptionsFutures, setWhereGatherTradingExperienceOptionsFutures] = useState('');
    const [over3YearAmountRealAccount, setOver3YearAmountRealAccount] = useState('');//New
    const [purposeOfInvestment, setPurposeOfInvestment] = useState('');
    const [purposeOfInvestmentDescription, setPurposeOfInvestmentDescription] = useState('');

    const [showHowManyTransactionsValidationMessage, setShowHowManyTransactionsValidationMessage] = useState(false);
    const [showApproximateValueInEURValidationMessage, setShowApproximateValueInEURValidationMessage] = useState(false);
    const [showExperienceWithSharesBondsValidationMessage, setShowExperienceWithSharesBondsValidationMessage] = useState(false);
    const [showWhereGatherTradingExperienceSharesBondsValidationMessage, setShowWhereGatherTradingExperienceSharesBondsValidationMessage] = useState(false);
    const [showOptionsFuturesETDsExperienceValidationMessage, setShowOptionsFuturesETDsExperienceValidationMessage] = useState(false);
    const [showWhereGatherTradingExperienceOptionsFuturesValidationMessage, setShowWhereGatherTradingExperienceOptionsFuturesValidationMessage] = useState(false);
    const [showOver3YearAmountRealAccountValidationMessage, setShowOver3YearAmountRealAccountValidationMessage] = useState(false);
    const [showPurposeOfInvestmentValidationMessage, setShowPurposeOfInvestmentValidationMessage] = useState(false);
    const [showPurposeOfInvestmentDescriptionValidationMessage, setShowPurposeOfInvestmentDescriptionValidationMessage] = useState(false);
    //Step3 End



    useEffect(() => {
        if (customerData) {
            if (customerData.tradingExperianceAgreement) {
                setTradingExperianceAgreement(customerData.tradingExperianceAgreement)
                setTradingExperianceStep(2);
            }
            if (customerData.levelOfEducationAndQualification) {
                setLevelOfEducationAndQualification(customerData.levelOfEducationAndQualification);
            }
            if (customerData.financialInstrumentsTraded) {
                setFinancialInstrumentsTraded(customerData.financialInstrumentsTraded);
            }
            if (customerData.financialInstrumentsTradedDescription) {
                setFinancialInstrumentsTradedDescription(customerData.financialInstrumentsTradedDescription);
            }
            if (customerData.howTradedInstruments) {
                setHowTradedInstruments(customerData.howTradedInstruments);
            }
            if (customerData.believeInKnowledge) {
                setBelieveInKnowledge(customerData.believeInKnowledge);
            }
            if (customerData.howCharacterizeKnowledge) {
                setHowCharacterizeKnowledge(customerData.howCharacterizeKnowledge);
            }
            if (customerData.experienceWithForexLeveragedCFDs) {
                setExperienceWithForexLeveragedCFDs(customerData.experienceWithForexLeveragedCFDs);
            }
            if (customerData.whereGatherTradingExperienceForexLeveragedCFDs) {
                setWhereGatherTradingExperienceForexLeveragedCFDs(customerData.whereGatherTradingExperienceForexLeveragedCFDs);
            }
            if (customerData.howManyTransactions) {
                setHowManyTransactions(customerData.howManyTransactions);
            }
            if (customerData.approximateValueInEUR) {
                setApproximateValueInEUR(customerData.approximateValueInEUR);
            }
            if (customerData.experienceWithSharesBonds) {
                setExperienceWithSharesBonds(customerData.experienceWithSharesBonds);
            }
            if (customerData.whereGatherTradingExperienceSharesBonds) {
                setWhereGatherTradingExperienceSharesBonds(customerData.whereGatherTradingExperienceSharesBonds);
            }
            if (customerData.optionsFuturesETDsExperience) {
                setOptionsFuturesETDsExperience(customerData.optionsFuturesETDsExperience);
            }
            if (customerData.whereGatherTradingExperienceOptionsFutures) {
                setWhereGatherTradingExperienceOptionsFutures(customerData.whereGatherTradingExperienceOptionsFutures);
            }
            if (customerData.over3YearAmountRealAccount) {
                setOver3YearAmountRealAccount(customerData.over3YearAmountRealAccount);
            }
            if (customerData.purposeOfInvestment) {
                setPurposeOfInvestment(customerData.purposeOfInvestment);
            }
            if (customerData.purposeOfInvestmentDescription) {
                setPurposeOfInvestmentDescription(customerData.purposeOfInvestmentDescription);
            }


            if (
                (customerData.levelOfEducationAndQualification && customerData.levelOfEducationAndQualification !== '')
                && (customerData.financialInstrumentsTraded && customerData.financialInstrumentsTraded !== '')
                && (customerData.financialInstrumentsTraded !== 'Other (*Please specify)' || customerData.financialInstrumentsTradedDescription !== '')
                && (customerData.howTradedInstruments && customerData.howTradedInstruments !== '')
                && (customerData.believeInKnowledge !== null && customerData.believeInKnowledge !== undefined)
                && (customerData.howCharacterizeKnowledge && customerData.howCharacterizeKnowledge !== '')
                && (customerData.experienceWithForexLeveragedCFDs && customerData.experienceWithForexLeveragedCFDs !== '')
                && (customerData.whereGatherTradingExperienceForexLeveragedCFDs && customerData.whereGatherTradingExperienceForexLeveragedCFDs !== '')

            ) {
                setTradingExperianceStep(3);
            }
        }
    }, [customerData]);

    function validateForm(): boolean {
        let formValid: boolean = true;
        setGeneralError('');
        setShowLevelOfEducationAndQualificationValidationMessage(false);
        setShowFinancialInstrumentsTradedValidationMessage(false);
        setShowHowTradedInstrumentsValidationMessage(false);
        setShowHowCharacterizeKnowledgeValidationMessage(false);
        setShowExperienceWithForexLeveragedCFDsValidationMessage(false);
        setShowWhereGatherTradingExperienceForexLeveragedCFDsValidationMessage(false);
        setShowHowManyTransactionsValidationMessage(false);
        setShowApproximateValueInEURValidationMessage(false);
        setShowExperienceWithSharesBondsValidationMessage(false);
        setShowWhereGatherTradingExperienceSharesBondsValidationMessage(false);
        setShowOptionsFuturesETDsExperienceValidationMessage(false);
        setShowWhereGatherTradingExperienceOptionsFuturesValidationMessage(false);
        setShowOver3YearAmountRealAccountValidationMessage(false);
        setShowPurposeOfInvestmentValidationMessage(false);
        setShowPurposeOfInvestmentDescriptionValidationMessage(false);



        if (tradingExperianceStep === 1 && !tradingExperianceAgreement) {
            formValid = false;
        }
        if (tradingExperianceStep === 2) {
            if (levelOfEducationAndQualification === '') {
                formValid = false;
                setShowLevelOfEducationAndQualificationValidationMessage(true);
            }
            if (financialInstrumentsTraded === '') {
                formValid = false;
                setShowFinancialInstrumentsTradedValidationMessage(true);
            }
            else if (financialInstrumentsTraded === 'Other (*Please specify)' && financialInstrumentsTradedDescription === '') {
                formValid = false;
                setShowFinancialInstrumentsTradedValidationMessage(true);
            }
            if (howTradedInstruments === '') {
                formValid = false;
                setShowHowTradedInstrumentsValidationMessage(true);
            }
            if (howCharacterizeKnowledge === '') {
                formValid = false;
                setShowHowCharacterizeKnowledgeValidationMessage(true);
            }
            if (experienceWithForexLeveragedCFDs === '') {
                formValid = false;
                setShowExperienceWithForexLeveragedCFDsValidationMessage(true);
            }
            if (whereGatherTradingExperienceForexLeveragedCFDs === '') {
                formValid = false;
                setShowWhereGatherTradingExperienceForexLeveragedCFDsValidationMessage(true);
            }
        }
        if (tradingExperianceStep === 3) {
            if (howManyTransactions === '') {
                formValid = false;
                setShowHowManyTransactionsValidationMessage(true);
            }
            if (approximateValueInEUR === '') {
                formValid = false;
                setShowApproximateValueInEURValidationMessage(true);
            }
            if (experienceWithSharesBonds === '') {
                formValid = false;
                setShowExperienceWithSharesBondsValidationMessage(true);
            }
            if (whereGatherTradingExperienceSharesBonds === '') {
                formValid = false;
                setShowWhereGatherTradingExperienceSharesBondsValidationMessage(true);
            }
            if (optionsFuturesETDsExperience === '') {
                formValid = false;
                setShowOptionsFuturesETDsExperienceValidationMessage(true);
            }
            if (whereGatherTradingExperienceOptionsFutures === '') {
                formValid = false;
                setShowWhereGatherTradingExperienceOptionsFuturesValidationMessage(true);
            }
            if (over3YearAmountRealAccount === '') {
                formValid = false;
                setShowOver3YearAmountRealAccountValidationMessage(true);
            }
            if (purposeOfInvestment === '') {
                formValid = false;
                setShowPurposeOfInvestmentValidationMessage(true);
            }
            if (purposeOfInvestment === 'Other' && purposeOfInvestmentDescription == '') {
                formValid = false;
                setShowPurposeOfInvestmentDescriptionValidationMessage(true);
            }
        }
        return formValid;
    }

    async function setTradingExperiance() {
        if (validateForm()) {
            setLoading(true);
            if (tradingExperianceStep === 1) {
                const { status, data } = await VerificationService.setTradingExperiance1(
                    {
                        tradingExperianceAgreement: tradingExperianceAgreement
                    }
                );
                if (status === 200) {
                    if (data.status === true) {
                        goToNextStep();
                    }
                    else {
                        if (data.errorCode) {
                            if (data.errorCode === 'ArgumentRequiredException') {
                                if (data.errorContext && data.errorContext.field) {
                                    if (data.errorContext.field) {
                                        setGeneralError(i18n.t('please-fill-all-required-fields') + ': ' + data.errorContext.field);
                                    }
                                    else {
                                        setGeneralError(data.errorCode);
                                    }
                                }
                                else {
                                    setGeneralError(data.errorCode);
                                }
                            }
                            else {
                                setGeneralError(data.errorCode);
                            }
                        }
                    }
                }
                else {
                    setLoading(false);
                    setGeneralError('Error');
                }
            }
            if (tradingExperianceStep === 2) {
                goToNextStep();
                const { status, data } = await VerificationService.setTradingExperiance2(
                    {
                        levelOfEducationAndQualification: levelOfEducationAndQualification,
                        financialInstrumentsTraded: financialInstrumentsTraded,
                        financialInstrumentsTradedDescription: financialInstrumentsTradedDescription,
                        howTradedInstruments: howTradedInstruments,
                        believeInKnowledge: believeInKnowledge,
                        howCharacterizeKnowledge: howCharacterizeKnowledge,
                        experienceWithForexLeveragedCFDs: experienceWithForexLeveragedCFDs,
                        whereGatherTradingExperienceForexLeveragedCFDs: whereGatherTradingExperienceForexLeveragedCFDs
                    }
                );
                if (status === 200) {
                    if (data.status === true) {
                        goToNextStep();
                    }
                    else {
                        if (data.errorCode) {
                            if (data.errorCode === 'ArgumentRequiredException') {
                                if (data.errorContext && data.errorContext.field) {
                                    if (data.errorContext.field) {
                                        setGeneralError(i18n.t('please-fill-all-required-fields') + ': ' + data.errorContext.field);
                                    }
                                    else {
                                        setGeneralError(data.errorCode);
                                    }
                                }
                                else {
                                    setGeneralError(data.errorCode);
                                }
                            }
                            else {
                                setGeneralError(data.errorCode);
                            }
                        }
                    }
                }
                else {
                    setLoading(false);
                    setGeneralError('Error');
                }
            }
            if (tradingExperianceStep === 3) {
                const { status, data } = await VerificationService.setTradingExperiance3(
                    {
                        howManyTransactions: howManyTransactions,
                        approximateValueInEUR: approximateValueInEUR,
                        experienceWithSharesBonds: experienceWithSharesBonds,
                        whereGatherTradingExperienceSharesBonds: whereGatherTradingExperienceSharesBonds,
                        optionsFuturesETDsExperience: optionsFuturesETDsExperience,
                        whereGatherTradingExperienceOptionsFutures: whereGatherTradingExperienceOptionsFutures,
                        over3YearAmountRealAccount: over3YearAmountRealAccount,
                        purposeOfInvestment: purposeOfInvestment,
                        purposeOfInvestmentDescription: purposeOfInvestmentDescription
                    }
                );
                if (status === 200) {
                    if (data.status === true) {
                        goToNextStep();
                    }
                    else {
                        if (data.errorCode) {
                            if (data.errorCode === 'ArgumentRequiredException') {
                                if (data.errorContext && data.errorContext.field) {
                                    if (data.errorContext.field) {
                                        setGeneralError(i18n.t('please-fill-all-required-fields') + ': ' + data.errorContext.field);
                                    }
                                    else {
                                        setGeneralError(data.errorCode);
                                    }
                                }
                                else {
                                    setGeneralError(data.errorCode);
                                }
                            }
                            else {
                                setGeneralError(data.errorCode);
                            }
                        }
                    }
                }
                else {
                    setLoading(false);
                    setGeneralError('Error');
                }
                //    goToNextStep();
            }
            setLoading(false);
        }
    }



    return (
        <View style={styles.safeAreaStyle}>
            <View style={styles.vcTitleContainer}>
                <Text fontType="LATO_BOLD" style={styles.vcTitleText}>
                    trading-experience
                </Text>
            </View>
            {tradingExperianceStep === 1 ?
                <>
                    <Text fontType="LATO_BOLD" style={styles.labelText}>
                        {i18n.t('product-suitability-assessment') + '*'}
                    </Text>
                    <Text fontType="LATO_BOLD" style={styles.labelText}>
                        {i18n.t('product-suitability-assessment-text') + '*'}
                    </Text>
                    <View style={styles.checkboxContainer}>
                        <Checkbox
                            checked={tradingExperianceAgreement}
                            setChecked={setTradingExperianceAgreement}
                            disabled={tradingExperianceAgreement}
                        />
                        <Text fontType="LATO_BOLD" style={[styles.labelText, { paddingLeft: scaleSize(10) }]}>
                            trading-experience-agreement
                        </Text>
                    </View>
                </>
                : null}
            {tradingExperianceStep === 2 ?
                <>
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        level-of-education-and-qualification-label
                    </Text>
                    <Picker
                        style={styles.pickerStyle}
                        itemStyle={styles.pickerRow}
                        selectedValue={levelOfEducationAndQualification}
                        onValueChange={(itemValue) => setLevelOfEducationAndQualification(itemValue)}
                    >
                        <Picker.Item
                            label={i18n.t('please-select-dots')}
                            value={''} />
                        <Picker.Item
                            label={i18n.t('no-formal-education')}
                            value={'No formal education'} />
                        <Picker.Item
                            label={i18n.t('primary-education')}
                            value={'Primary education'} />
                        <Picker.Item
                            label={i18n.t('secondary-education-or-high-school')}
                            value={'Secondary education or high school'} />
                        <Picker.Item
                            label={i18n.t("bachelors-degree")}
                            value={"Bachelor's degree"} />
                        <Picker.Item
                            label={i18n.t("masters-degree")}
                            value={"Master's degree"} />
                        <Picker.Item
                            label={i18n.t('doctorate-mba-or-higher')}
                            value={'Doctorate, MBA or higher'} />
                    </Picker>
                    <ErrorMessage
                        text={'please-enter-value'}
                        visible={levelOfEducationAndQualification === '' && showLevelOfEducationAndQualificationValidationMessage} />
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        financial-instruments-traded
                    </Text>
                    <Picker
                        style={styles.pickerStyle}
                        itemStyle={styles.pickerRow}
                        selectedValue={financialInstrumentsTraded}
                        onValueChange={(itemValue) => setFinancialInstrumentsTraded(itemValue)}
                    >
                        <Picker.Item
                            label={i18n.t('please-select-dots')}
                            value={''} />
                        <Picker.Item
                            label={i18n.t('forex-contracts-for-difference-cfd')}
                            value={'Forex/Contracts for Difference (CFD)'} />
                        <Picker.Item
                            label={i18n.t('shares-vc')}
                            value={'Shares'} />
                        <Picker.Item
                            label={i18n.t('bonds')}
                            value={'Bonds'} />
                        <Picker.Item
                            label={i18n.t('exchange-traded-funds-etfs')}
                            value={'Exchange Traded Funds (ETFs)'} />
                        <Picker.Item
                            label={i18n.t('options')}
                            value={'Options'} />
                        <Picker.Item
                            label={i18n.t('futures')}
                            value={'Futures'} />
                        <Picker.Item
                            label={i18n.t('exchange-traded-derivatives-etds')}
                            value={'Exchange Traded Derivatives (ETDs)'} />
                        <Picker.Item
                            label={i18n.t('i-have-not-traded-with-financial-instruments')}
                            value={'I have not traded with financial instruments'} />
                        <Picker.Item
                            label={i18n.t('other-please-specify')}
                            value={'Other (*Please specify)'} />
                    </Picker>
                    {financialInstrumentsTraded === 'Other (*Please specify)' ?
                        <TextInput
                            containerStyle={styles.inputContainer}
                            style={styles.textInputStyle}
                            autoCapitalize="none"
                            placeholder='please-specify'
                            autoFocus={false}
                            value={financialInstrumentsTradedDescription}
                            onChangeText={(text) => setFinancialInstrumentsTradedDescription(text)}
                            onBlur={() => setShowFinancialInstrumentsTradedValidationMessage(true)}
                            returnKeyType='next' />
                        : null
                    }
                    <ErrorMessage
                        text={'please-enter-value'}
                        visible={(financialInstrumentsTraded === '' || (financialInstrumentsTraded === 'Other (*Please specify)' && financialInstrumentsTradedDescription === '')) && showFinancialInstrumentsTradedValidationMessage} />
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        how-traded-instruments
                    </Text>
                    <Picker
                        style={styles.pickerStyle}
                        itemStyle={styles.pickerRow}
                        selectedValue={howTradedInstruments}
                        onValueChange={(itemValue) => setHowTradedInstruments(itemValue)}
                    >
                        <Picker.Item
                            label={i18n.t('please-select-dots')}
                            value={''} />
                        <Picker.Item
                            label={i18n.t('through-a-demo-account')}
                            value={'Through a demo account'} />
                        <Picker.Item
                            label={i18n.t('through-a-real-account')}
                            value={'Through a real account'} />
                        <Picker.Item
                            label={i18n.t('through-demo-and-real-account')}
                            value={'Through demo and real account'} />
                        <Picker.Item
                            label={i18n.t("i-have-not-made-any-trades")}
                            value={"I have not made any trades either through a demo or a real account"} />
                    </Picker>
                    <ErrorMessage
                        text={'please-enter-value'}
                        visible={howTradedInstruments === '' && showHowTradedInstrumentsValidationMessage} />
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        believe-in-knowledge
                    </Text>
                    <View style={styles.yesNoContainer}>
                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                            {'no'}
                        </Text>
                        <Switch
                            value={believeInKnowledge}
                            onValueChange={() => {
                                setBelieveInKnowledge(!believeInKnowledge);
                            }}
                        />
                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                            {'yes'}
                        </Text>
                    </View>
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        how-characterize-knowledge
                    </Text>
                    <Picker
                        style={styles.pickerStyle}
                        itemStyle={styles.pickerRow}
                        selectedValue={howCharacterizeKnowledge}
                        onValueChange={(itemValue) => setHowCharacterizeKnowledge(itemValue)}
                    >
                        <Picker.Item
                            label={i18n.t('please-select-dots')}
                            value={''} />
                        <Picker.Item
                            label={i18n.t('limited')}
                            value={'Limited'} />
                        <Picker.Item
                            label={i18n.t('good')}
                            value={'Good'} />
                        <Picker.Item
                            label={i18n.t('very-good')}
                            value={'Very good'} />
                        <Picker.Item
                            label={i18n.t("excellent")}
                            value={"Excellent"} />
                    </Picker>
                    <ErrorMessage
                        text={'please-enter-value'}
                        visible={howCharacterizeKnowledge === '' && showHowCharacterizeKnowledgeValidationMessage} />
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        experience-forex-cfd
                    </Text>
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        forex-cfd
                    </Text>
                    <Picker
                        style={styles.pickerStyle}
                        itemStyle={styles.pickerRow}
                        selectedValue={experienceWithForexLeveragedCFDs}
                        onValueChange={(itemValue) => setExperienceWithForexLeveragedCFDs(itemValue)}
                    >
                        <Picker.Item
                            label={i18n.t('please-select-dots')}
                            value={''} />
                        <Picker.Item
                            label={i18n.t('none')}
                            value={'None'} />
                        <Picker.Item
                            label={i18n.t('less-than') + ' 1 ' + i18n.t('year')}
                            value={'Less than 1 year'} />
                        <Picker.Item
                            label={i18n.t('1 to 3 years')}
                            value={'1 to 3 years'} />
                        <Picker.Item
                            label={i18n.t("over") + ' 3 ' + i18n.t("years")}
                            value={"Over 3 years"} />
                    </Picker>
                    <ErrorMessage
                        text={'please-enter-value'}
                        visible={experienceWithForexLeveragedCFDs === '' && showExperienceWithForexLeveragedCFDsValidationMessage} />
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        where-gather-trading-experience-forex-leveraged-CFDs
                    </Text>
                    <Picker
                        style={styles.pickerStyle}
                        itemStyle={styles.pickerRow}
                        selectedValue={whereGatherTradingExperienceForexLeveragedCFDs}
                        onValueChange={(itemValue) => setWhereGatherTradingExperienceForexLeveragedCFDs(itemValue)}
                    >
                        <Picker.Item
                            label={i18n.t('please-select-dots')}
                            value={''} />
                        <Picker.Item
                            label={i18n.t('from-professional-qualifications-work-position')}
                            value={'From professional qualifications and/or work in a position requiring such knowledge'} />
                        <Picker.Item
                            label={i18n.t('my-knowledge-is-from-work-experience')}
                            value={'My knowledge is from work experience or professional activities'} />
                        <Picker.Item
                            label={i18n.t('from-training-materials-courses-seminars')}
                            value={'From training materials, courses, seminars, webinars, consultations'} />
                        <Picker.Item
                            label={i18n.t('my-knowledge-is-from-trading-experience')}
                            value={'My knowledge is from trading experience'} />
                        <Picker.Item
                            label={i18n.t("i-dont-have-any-relevant-knowledge-of-forex-cfd")}
                            value={"I don't have any relevant knowledge of Forex/CFD"} />
                    </Picker>
                    <ErrorMessage
                        text={'please-enter-value'}
                        visible={whereGatherTradingExperienceForexLeveragedCFDs === '' && showWhereGatherTradingExperienceForexLeveragedCFDsValidationMessage} />
                </>
                : null}
            {tradingExperianceStep === 3 ?
                <>
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        how-many-transactions
                    </Text>
                    <Picker
                        style={styles.pickerStyle}
                        itemStyle={styles.pickerRow}
                        selectedValue={howManyTransactions}
                        onValueChange={(itemValue) => setHowManyTransactions(itemValue)}
                    >
                        <Picker.Item
                            label={i18n.t('please-select-dots')}
                            value={''} />
                        <Picker.Item
                            label={i18n.t('so-far-i-have-not-entered-into-transactions-with-the-specified-securities')}
                            value={'So far, I have not entered into transactions with the specified securities'} />
                        <Picker.Item
                            label={i18n.t('1 - 10')}
                            value={'1 - 10'} />
                        <Picker.Item
                            label={i18n.t('11 - 50')}
                            value={'11 - 50'} />
                        <Picker.Item
                            label={i18n.t('over') + ' 50'}
                            value={'over 50'} />
                    </Picker>
                    <ErrorMessage
                        text={'please-enter-value'}
                        visible={howManyTransactions === '' && showHowManyTransactionsValidationMessage} />

                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        approximate-value-in-EUR
                    </Text>
                    <Picker
                        style={styles.pickerStyle}
                        itemStyle={styles.pickerRow}
                        selectedValue={approximateValueInEUR}
                        onValueChange={(itemValue) => setApproximateValueInEUR(itemValue)}
                    >
                        <Picker.Item
                            label={i18n.t('please-select-dots')}
                            value={''} />
                        <Picker.Item
                            label={i18n.t('none')}
                            value={'None'} />
                        <Picker.Item
                            label={i18n.t('1,000€ - 15,000€')}
                            value={'1,000€ - 15,000€'} />
                        <Picker.Item
                            label={i18n.t('15,000€ - 50,000€')}
                            value={'15,000€ - 50,000€'} />
                        <Picker.Item
                            label={i18n.t('over') + ' 50,000€'}
                            value={'over 50,000€'} />
                    </Picker>
                    <ErrorMessage
                        text={'please-enter-value'}
                        visible={approximateValueInEUR === '' && showApproximateValueInEURValidationMessage} />

                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        experience-forex-cfd
                    </Text>
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        shares-bonds-etfs
                    </Text>
                    <Picker
                        style={styles.pickerStyle}
                        itemStyle={styles.pickerRow}
                        selectedValue={experienceWithSharesBonds}
                        onValueChange={(itemValue) => setExperienceWithSharesBonds(itemValue)}
                    >
                        <Picker.Item
                            label={i18n.t('please-select-dots')}
                            value={''} />
                        <Picker.Item
                            label={i18n.t('none')}
                            value={'None'} />
                        <Picker.Item
                            label={i18n.t('less-than') + ' 1 ' + i18n.t('year')}
                            value={'Less than 1 year'} />
                        <Picker.Item
                            label={i18n.t('1 to 3 years')}
                            value={'1 to 3 years'} />
                        <Picker.Item
                            label={i18n.t("Over") + ' 3 ' + i18n.t("years")}
                            value={"Over 3 years"} />
                    </Picker>
                    <ErrorMessage
                        text={'please-enter-value'}
                        visible={experienceWithSharesBonds === '' && showExperienceWithSharesBondsValidationMessage} />

                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        where-did-you-gather-your-trading-experience
                    </Text>
                    <Picker
                        style={styles.pickerStyle}
                        itemStyle={styles.pickerRow}
                        selectedValue={whereGatherTradingExperienceSharesBonds}
                        onValueChange={(itemValue) => setWhereGatherTradingExperienceSharesBonds(itemValue)}
                    >
                        <Picker.Item
                            label={i18n.t('please-select-dots')}
                            value={''} />
                        <Picker.Item
                            label={i18n.t('from-professional-qualifications-work-position')}
                            value={'From professional qualifications and/or work in a position requiring such knowledge'} />
                        <Picker.Item
                            label={i18n.t('my-knowledge-is-from-work-experience')}
                            value={'My knowledge is from work experience or professional activities'} />
                        <Picker.Item
                            label={i18n.t('from-training-materials-courses-seminars')}
                            value={'From training materials, courses, seminars, webinars, consultations'} />
                        <Picker.Item
                            label={i18n.t('my-knowledge-is-from-trading-experience')}
                            value={'My knowledge is from trading experience'} />
                        <Picker.Item
                            label={i18n.t("i-dont-have-any-relevant-knowledge-of-Shares-Bonds-etfs")}
                            value={"I don't have any relevant knowledge of Shares/Bonds/ETFs"} />
                    </Picker>
                    <ErrorMessage
                        text={'please-enter-value'}
                        visible={whereGatherTradingExperienceSharesBonds === '' && showWhereGatherTradingExperienceSharesBondsValidationMessage} />

                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        experience-forex-cfd
                    </Text>
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        Options/Futures/ETDs
                    </Text>
                    <Picker
                        style={styles.pickerStyle}
                        itemStyle={styles.pickerRow}
                        selectedValue={optionsFuturesETDsExperience}
                        onValueChange={(itemValue) => setOptionsFuturesETDsExperience(itemValue)}
                    >
                        <Picker.Item
                            label={i18n.t('please-select-dots')}
                            value={''} />
                        <Picker.Item
                            label={i18n.t('none')}
                            value={'None'} />
                        <Picker.Item
                            label={i18n.t('less-than') + ' 1 ' + i18n.t('year')}
                            value={'Less than 1 year'} />
                        <Picker.Item
                            label={i18n.t('1 to 3 years')}
                            value={'1 to 3 years'} />
                        <Picker.Item
                            label={i18n.t("over") + ' 3 ' + i18n.t("years")}
                            value={"Over 3 years"} />
                    </Picker>
                    <ErrorMessage
                        text={'please-enter-value'}
                        visible={optionsFuturesETDsExperience === '' && showOptionsFuturesETDsExperienceValidationMessage} />

                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        where-did-you-gather-your-trading-experience-and-or-knowledge-of-trading-of-options-futures-etds
                    </Text>
                    <Picker
                        style={styles.pickerStyle}
                        itemStyle={styles.pickerRow}
                        selectedValue={whereGatherTradingExperienceOptionsFutures}
                        onValueChange={(itemValue) => setWhereGatherTradingExperienceOptionsFutures(itemValue)}
                    >
                        <Picker.Item
                            label={i18n.t('please-select-dots')}
                            value={''} />
                        <Picker.Item
                            label={i18n.t('from-professional-qualifications-work-position')}
                            value={'From professional qualifications and/or work in a position requiring such knowledge'} />
                        <Picker.Item
                            label={i18n.t('my-knowledge-is-from-work-experience')}
                            value={'My knowledge is from work experience or professional activities'} />
                        <Picker.Item
                            label={i18n.t('from-training-materials-courses-seminars')}
                            value={'From training materials, courses, seminars, webinars, consultations'} />
                        <Picker.Item
                            label={i18n.t('my-knowledge-is-from-trading-experience')}
                            value={'My knowledge is from trading experience'} />
                        <Picker.Item
                            label={i18n.t("i-dont-have-any-relevant-knowledge-of-options-futures-etds")}
                            value={"I don't have any relevant knowledge of Options/Futures/ETDs"} />
                    </Picker>
                    <ErrorMessage
                        text={'please-enter-value'}
                        visible={whereGatherTradingExperienceOptionsFutures === '' && showWhereGatherTradingExperienceOptionsFuturesValidationMessage} />
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        over-3-average-amouns-real-trading
                    </Text>
                    <Picker
                        style={styles.pickerStyle}
                        itemStyle={styles.pickerRow}
                        selectedValue={over3YearAmountRealAccount}
                        onValueChange={(itemValue) => setOver3YearAmountRealAccount(itemValue)}
                    >
                        <Picker.Item
                            label={i18n.t('please-select-dots')}
                            value={''} />
                        <Picker.Item
                            label={i18n.t('less-than') + ' 1,000€'}
                            value={'less than 1,000€'} />
                        <Picker.Item
                            label={i18n.t('1,000€ - 5,000€')}
                            value={'1,000€ - 5,000€'} />
                        <Picker.Item
                            label={i18n.t('5,000€- 25,000€')}
                            value={'5,000€- 25,000€'} />
                        <Picker.Item
                            label={i18n.t('25,000€+')}
                            value={'25,000€+'} />
                        <Picker.Item
                            label={i18n.t("i-do-not-have-a-real-trading-account")}
                            value={"I do not have a real trading account"} />
                    </Picker>
                    <ErrorMessage
                        text={'please-enter-value'}
                        visible={over3YearAmountRealAccount === '' && showOver3YearAmountRealAccountValidationMessage} />
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        select-the-purpose-of-your-investment
                    </Text>
                    <Picker
                        style={styles.pickerStyle}
                        itemStyle={styles.pickerRow}
                        selectedValue={purposeOfInvestment}
                        onValueChange={(itemValue) => setPurposeOfInvestment(itemValue)}
                    >
                        <Picker.Item
                            label={i18n.t('please-select-dots')}
                            value={''} />
                        <Picker.Item
                            label={i18n.t('capital-growth')}
                            value={'Capital growth'} />
                        <Picker.Item
                            label={i18n.t('hedging')}
                            value={'Hedging'} />
                        <Picker.Item
                            label={i18n.t('speculation')}
                            value={'Speculation'} />
                        <Picker.Item
                            label={i18n.t('other')}
                            value={'Other'} />
                    </Picker>
                    <ErrorMessage
                        text={'please-enter-value'}
                        visible={purposeOfInvestment === '' && showPurposeOfInvestmentValidationMessage} />

                    {purposeOfInvestment === 'Other' ?
                        <>
                            <TextInput
                                containerStyle={styles.inputContainer}
                                style={styles.textInputStyle}
                                autoCapitalize="none"
                                placeholder='please-specify'
                                autoFocus={false}
                                value={purposeOfInvestmentDescription}
                                onChangeText={(text) => setPurposeOfInvestmentDescription(text)}
                                onBlur={() => setShowPurposeOfInvestmentDescriptionValidationMessage(true)}
                                returnKeyType='next' />
                            <ErrorMessage
                                text={'please-enter-value'}
                                visible={purposeOfInvestmentDescription === '' && showPurposeOfInvestmentDescriptionValidationMessage} />
                        </> : null}
                </>
                : null}
            <ErrorMessage
                text={generalError}
                visible={generalError !== ''}
            />
            <View style={styles.buttonsContainer}>
                <View style={styles.buttonContainer}>
                    <Button
                        onPress={() => goToPrevStep()}
                        title="back"
                        backgroundColor={styles.backButtonStyle}
                    />

                </View>
                <View style={styles.buttonContainer}>
                    <Button
                        onPress={() => setTradingExperiance()}
                        title="next"
                        disabled={!tradingExperianceAgreement}
                    />
                </View>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        safeAreaStyle: { flex: 1, paddingHorizontal: scaleSize(Platform.OS !== 'web' ? 0 : 12), },
        checkboxContainer: { paddingRight: scaleSize(10), flexDirection: 'row', alignItems: 'center' },
        backButtonStyle: { backgroundColor: theme.tabIconDefault },
        yesNoContainer: { flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: '25%' },
        buttonsContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingVertical: scaleSize(20),
        },
        buttonContainer: {
            width: scaleSize(150),
            height: scaleSize(40),
        },
        pickerRow: {
            width: '100%',
            height: Platform.OS !== 'ios' ? scaleSize(40) : 3 * scaleSize(40),
            fontSize: scaleSize(FONT_SIZE_S),
            color: theme.text
        },
        pickerStyle: {
            width: '100%',
            height: Platform.OS !== 'ios' ? scaleSize(40) : 3 * scaleSize(40),
            fontSize: scaleSize(FONT_SIZE_M),
            backgroundColor: theme.backgroundSecondary,
            color: theme.text_pendingOrder,
            borderRadius: scaleSize(5),
            padding: 0,
            marginBottom: scaleSize(10)
        },
        textInputStyle: {
            fontSize: scaleSize(FONT_SIZE_M),
            color: theme.text_pendingOrder,
            width: '100%'
        },
        labelText: { fontSize: FONT_SIZE_XS, color: theme.text_symbol, paddingVertical: scaleSize(10) },
        bdateContainer: { flexDirection: 'row', justifyContent: 'space-between' },
        bdatePickerContainer: { width: '31%' },
        vcTitleContainer: {
            justifyContent: 'center',
            paddingVertical: scaleSize(20),
        },
        vcTitleText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        inputContainer: {
            marginBottom: scaleSize(10),
        },
        keyBoard: { width: '100%' },
        viewContainer: { justifyContent: 'space-between', height: '80%', paddingBottom: scaleSize(10) },
        scrollView: { maxHeight: '100%', height: '100%' },
        container: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingHorizontal: scaleSize(17),
            height: '100%',
            width: '100%',
        },
    });
