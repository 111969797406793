import axios from '../baseClient';
import {CustomerInfoServiceResponse, GetDirectCardDataResponse, GetDirectCardDataResponseAPI} from './types';

class CustomerInfoService {
    private static emerchantDomain = '/api/emerchantpayservice';
    private static trustpaymentsDomain = '/api/trustpaymentsservice';
    private static paytikoDomain = '/api/paytikorequester';
    private static arenamoneyDomain = '/api/arenamoneyrequester';

    getCustomerInfoTrustpayments() {
        return axios.post<CustomerInfoServiceResponse>(
            `${CustomerInfoService.trustpaymentsDomain}/getCustomerInfo`,
            {}
        );
    }

    getCustomerInfoEMerchant() {
        return axios.post<CustomerInfoServiceResponse>(`${CustomerInfoService.emerchantDomain}/getCustomerInfo`, {});
    }

    getCustomerInfoIdeal() {
        return axios.post<CustomerInfoServiceResponse>(
            `${CustomerInfoService.trustpaymentsDomain}/getCustomerInfoIdeal`,
            {}
        );
    }

    getCustomerInfoPaytikoPayForm() {
        return axios.post<CustomerInfoServiceResponse>(
            `${CustomerInfoService.paytikoDomain}/getCustomerInfo`,
            {}
        );
    }

    getCustomerInfoArenaMoneyPayForm() {
        return axios.post<CustomerInfoServiceResponse>(
            `${CustomerInfoService.arenamoneyDomain}/getCustomerInfo`,
            {}
        );
    }

    getDirectCardDataArenaMoney(paymentProcessorId: number) {
        return axios.post<GetDirectCardDataResponseAPI>(
            `${CustomerInfoService.arenamoneyDomain}/getDirectCardData`,
            {
                paymentProcessorId:paymentProcessorId,
            }
        );
    }

    getDirectCardDataPaytiko(paymentProcessorId: number) {
        return axios.post<GetDirectCardDataResponseAPI>(
            `${CustomerInfoService.paytikoDomain}/getDirectCardData`,
            {
                paymentProcessorId:paymentProcessorId,
            }
        );
    }

}

export default new CustomerInfoService();
