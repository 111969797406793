import axios from '../baseClient';
import {
    OrderConvertResponse,
    OrderConvertRequest,
    TrustPaymentsResponse,
    TrustPaymentsRequest,
    TrustPaymentsIdealRequest,
    TrustPaymentsIdealResponse,
    OrderConvertPaytikoRequest,
    OrderConvertPaytikoResponse,
    BestexchRequest,
    BestexchResponse,
    OrderConvertPaytikoDirectCardRequest, OrderConvertPaytikoResponseAPI
} from './types';

class OrderConvertService {
    private static emerchantDomain = '/api/emerchantpayservice';
    private static trustPaymentsDomain = '/api/trustpaymentsservice';
    private static paytikoDomain = '/api/paytikorequester';
    private static arenamoneyDomain = '/api/arenamoneyrequester';
    private static bestexchDomain = '/api/bestexchrequester';

    createOrderEmerchantPay(orderConvertRequest: OrderConvertRequest) {
        return axios.post<OrderConvertResponse>(
            `${OrderConvertService.emerchantDomain}/createOrderConvertV2`,
            orderConvertRequest
        );
    }

    createOrderTrustPayments(trustPaymentsRequest: TrustPaymentsRequest) {
        return axios.post<TrustPaymentsResponse>(
            `${OrderConvertService.trustPaymentsDomain}/createOrder`,
            trustPaymentsRequest
        );
    }

    createOrderIdeal(trustPaymentsIdealRequest: TrustPaymentsIdealRequest) {
        return axios.post<TrustPaymentsIdealResponse>(
            `${OrderConvertService.trustPaymentsDomain}/createOrderIdeal`,
            trustPaymentsIdealRequest
        );
    }

    createOrderPaytiko(paytikoPaymentsRequest: OrderConvertPaytikoRequest) {
        return axios.post<OrderConvertPaytikoResponse>(
            `${OrderConvertService.paytikoDomain}/createOrder`,
            paytikoPaymentsRequest
        );
    }

    createOrderPaytikoDirectCard(paytikoPaymentsRequest: OrderConvertPaytikoDirectCardRequest) {
        return axios.post<OrderConvertPaytikoResponseAPI>(
            `${OrderConvertService.paytikoDomain}/createOrderDirectCard`,
            paytikoPaymentsRequest
        );
    }

    createOrderArenaMoney(paytikoPaymentsRequest: OrderConvertPaytikoRequest) {
        return axios.post<OrderConvertPaytikoResponse>(
            `${OrderConvertService.arenamoneyDomain}/createOrder`,
            paytikoPaymentsRequest
        );
    }

    createOrderArenaMoneyDirectCard(paytikoPaymentsRequest: OrderConvertPaytikoDirectCardRequest) {
        return axios.post<OrderConvertPaytikoResponseAPI>(
            `${OrderConvertService.arenamoneyDomain}/createOrderDirectCard`,
            paytikoPaymentsRequest
        );
    }

    createOrderBestexch(bestexchRequest: BestexchRequest) {
        return axios.post<BestexchResponse>(
            `${OrderConvertService.bestexchDomain}/createOrder`,
            bestexchRequest
        );
    }
}

export default new OrderConvertService();
