import React, { useEffect, useState } from 'react';
import { KeyboardAvoidingView, Linking, Platform, Pressable, Image, StyleSheet, View } from 'react-native';
import paths from '~/navigation/paths';
import { linkTo } from '~/navigation';
import { Button, TextInput, ErrorMessage, Text } from '~/components/core';
import Loader from '~/components/core/Loader';
import { isEUCountry, validateEmail, validatePasswordStrength } from '~/lib/utils';
import t from '~/i18n';
import { AuthDataService } from '~/api-services';
import { FONT_SIZE_XXXL, FONT_SIZE_L, FONT_SIZE_S, FONT_SIZE_XS, FONT_SIZE_M } from '~/constants';
import { useStyles, useAppDispatch, useAppSelector } from '~/hooks';
import { Ionicons } from "~/components/core/Icons";
import { Theme } from '~/theme';
import config from '~/config';
import { isDesktop, scaleSize, scaleSizeMobile } from '~/constants/scale';
import Checkbox from '~/components/core/Checkbox';
import ResolveCountryService from '~/api-services/ResolveCountryService';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Picker } from '@react-native-picker/picker';
import { saveAppSettings } from '~/state/reducers/appSettingsSlice';
import { loginUser } from '~/state/reducers/userAuthSlice';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import { appSettingsSelector } from '~/state/selectors';
import * as qs from 'query-string';
import { useBrowserLanguage } from '~/hooks/useBrowserLanguage';
import { countries } from '../RegistrationForm/countries';
import { SvgUri } from 'react-native-svg';


function getValidationErrorMessage(
    name: string,
    email: string,
    password: string,
    passwordConfirm: string,
    phone: string,
    privacyPolicyChecked: boolean,
    phonePrefix: string,
    country: string,
) {
    if (!name) {
        return t.t('registration-name-missing');
    }
    if (name.indexOf(' ') < 1 && name.indexOf(' ') > name.length - 2) {
        return t.t('registration-last-name-missing');
    }
    if (!email) {
        return t.t('registration-email-missing');
    }
    if (!validateEmail(email)) {
        return t.t('registration-email-invalid');
    }
    if (!password || password === '') {
        return t.t('registration-password-missing');
    }
    if (!passwordConfirm || passwordConfirm === '') {
        return t.t('registration-password-confirm-missing');
    }
    if (password !== passwordConfirm) {
        return t.t('registration-password-confirm-missing');
    }
    if (!phone || phone === '' || phone === phonePrefix) {
        return t.t('registration-phone-missing');
    }
    if (!privacyPolicyChecked) {
        return t.t('privacy-policy-checkbox-error');
    }
    if (!country) {
        return t.t('please-select-country');
    }
    return '';
}

export default function RegistrationFormInvesting() {
    const [currency, setCurrency] = useState('EUR');
    const { colorScheme } = useAppSelector(appSettingsSelector);
    const [dealer, setDealer] = useState(config.DEFAULT_DEALER);
    const [changeCurrency, setChangeCurrency] = useState(false);
    const [selectedCurrencyIndex, setSelectedCurrencyIndex] = useState(0);
    const [selectedDealerIndex, setSelectedDealerIndex] = useState(0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordVisibility, setPasswordVisibility] = useState(true);
    const [passwordConfirmVisibility, setPasswordConfirmVisibility] = useState(true);
    const [phone, setPhone] = useState('');
    const [phonePlaceholder, setPhonePlaceholder] = useState('registration-input-enter-phone');
    const [loading, setLoading] = useState(false);
    const [signupError, setSignupError] = useState('');
    const [isRegistrationSuccess, setIsRegistrationSuccess] = useState(false);
    const maxWidth: number = 600;
    const styles = useStyles(stylesGenerator, { maxWidth });
    const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
    const [duplicateUsername, setDuplicateUsername] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [pendingRequest, setPendingRequest] = useState(false);
    const [phonePrefix, setPhonePrefix] = useState('');
    const [wrongPhone, setWrongPhone] = useState(false);
    const [countriesList, setCountriesList] = useState<countries[]>([]);
    const [changeDealer, setChangeDealer] = useState(false);
    const [showNameValidationMessage, setShowNameValidationMessage] = useState(false);
    const [showEmailValidationMessage, setShowEmailValidationMessage] = useState(false);
    const [showPasswordValidationMessage, setShowPasswordValidationMessage] = useState(false);
    const [showPasswordConfirmValidationMessage, setShowPasswordConfirmValidationMessage] = useState(false);
    const [showPhoneValidationMessage, setShowPhoneValidationMessage] = useState(false);
    const [showPrivacyValidationMessage, setShowPrivacyValidationMessage] = useState(false);
    const [dealers, setDealers] = useState([]);
    const [clientCountry, setClientCountry] = useState<string>(undefined);
    const { language } = useAppSelector(appSettingsSelector);
    const [title, setTitle] = useState('');
    const [subID, setSubID] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [showDealerOption, setShowDealerOption] = useState(false);
    const [affiliateSourceNewId, setAffiliateSourceNewId] = useState<string>(undefined);
    let cList: countries[] = ResolveCountryService.getCountriesList();
    const [selectedCountryIndex, setSelectedCountryIndex] = useState(0);
    const [showCountryValidationMessage, setShowCountryValidationMessage] = useState(false);
    useBrowserLanguage();

    let currencies = [
        { label: 'EUR', value: 'EUR' },
        { label: 'USD', value: 'USD' },
        { label: 'GBP', value: 'GBP' },
    ];

    const dispatch = useAppDispatch();

    function getCookie(name: string): string {
        if (name) {
            const nameLenPlus = (name.length + 1);
            return document.cookie
                .split(';')
                .map(c => c.trim())
                .filter(cookie => {
                    return cookie.substring(0, nameLenPlus) === `${name}=`;
                })
                .map(cookie => {
                    return decodeURIComponent(cookie.substring(nameLenPlus));
                })[0] || null;
        }
        else {
            return undefined;
        }
    }

    function setAffiliateNewParams() {
        if (Platform.OS === 'web') {
            let affCookie = getCookie(config.AFFILIATE_NEW_COOKIE_NAME);
            if (affCookie) {
                setAffiliateSourceNewId(affCookie);
            }
        }
    }

    function setAffiliateParams() {
        if (Platform.OS === 'web') {
            let params = qs.parse(location.search);
            if (params && params.subID) {
                if (Array.isArray(params.subID)) {
                    setSubID(params.subId[0]);
                } else {
                    setSubID(params.subID)
                }
            }
            if (params && params.apiKey) {
                if (Array.isArray(params.apiKey)) {
                    setApiKey(params.apiKey[0]);
                } else {
                    setApiKey(params.apiKey)
                }
            }
        }
        return false;
    }

    useEffect(() => {
        var messageContext: any = {};
        messageContext.brand = config.BRAND_NAME.toUpperCase();
        setTitle(t.t('register-screen-head-title', messageContext));
        setAffiliateParams();
        setAffiliateNewParams();
    }, []);

    useEffect(() => {
        let dealersList = [];
        dealersList.push({ label: t.t('dealer-meridian'), value: 'MERIDIAN' });

        setDealer('MERIDIAN');

        setDealers(dealersList);

        if (clientCountry) {
            setShowDealerOption(false);
            if (isEUCountry(clientCountry) || clientCountry === 'GB') {
            }
            else {
                //Default Currency = USD No (EU And UK)
                setCurrency('USD');
                setSelectedCurrencyIndex(1);
            }
        }
    }, [clientCountry]);

    useEffect(() => {
        (async () => {
            if (phonePrefix === '' && countriesList.length === 0) {
                const { status, data } = await ResolveCountryService.resolveCountry();
                if (status === 200 && data.status) {
                    let cList: countries[] = ResolveCountryService.getCountriesList();
                    setCountriesList(cList);
                    setClientCountry(data.returnData);
                    let currentCountry: countries | undefined = cList.find(
                        (country) => country.abbreviation === data.returnData
                    );
                    if (currentCountry) {
                        setPhonePrefix('+' + currentCountry.phonePrefix);
                        setPhone('+' + currentCountry.phonePrefix);
                        setPhonePlaceholder('+' + currentCountry.phonePrefix + ' | Phone Number');
                    }
                }
            }
        })();
    }, [countriesList, phonePrefix]);

    async function onHandleSignup() {
        if (loading) {
            return;
        }
        setShowNameValidationMessage(true);
        setShowCountryValidationMessage(true);
        setShowEmailValidationMessage(true);
        setShowPasswordValidationMessage(true);
        setShowPasswordConfirmValidationMessage(true);
        setShowPhoneValidationMessage(true);
        setShowPrivacyValidationMessage(true);
        const validationError = getValidationErrorMessage(
            name,
            email,
            password,
            passwordConfirm,
            phone,
            privacyPolicyChecked,
            phonePrefix,
            clientCountry,
        );
        if (validationError !== '') {
            setSignupError(validationError);
            return;
        }
        setLoading(true);
        try {
            if (Platform.OS === 'web' && subID !== '' && apiKey !== '') {
                const { status, data } = await AuthDataService.registrationAffiliate({
                    firstName: name.substring(0, name.indexOf(' ')),
                    lastName: name.substring(name.indexOf(' ') + 1, name.length),
                    email,
                    password,
                    phone,
                    country: clientCountry,
                    currency: currency,
                    dealer: dealer,
                    language: language.toUpperCase(),
                    referralAccountId: '',
                    type: 'REAL',
                }, { subId: subID, apiKey: apiKey });
                if (status !== 200 || !data.status) {
                    throw new Error(data.errorCode || 'Error');
                }
            }
            else {
                const { status, data } = await AuthDataService.registration({
                    firstName: name.substring(0, name.indexOf(' ')),
                    lastName: name.substring(name.indexOf(' ') + 1, name.length),
                    email,
                    password,
                    phone,
                    country: clientCountry,
                    currency: currency,
                    dealer: dealer,
                    language: language.toUpperCase(),
                    referralAccountId: '',
                    type: 'REAL',
                    affiliateSourceNewId: affiliateSourceNewId,
                });
                if (status !== 200 || !data.status) {
                    throw new Error(data.errorCode || 'Error');
                }
            }
            dispatch(
                saveAppSettings({
                    lastRememberMe: true,
                    lastEmail: email,
                    lastPassword: password,
                })
            );
            setIsRegistrationSuccess(true);
            setTimeout(() => {
                dispatch(loginUser({ email, password, rememberMe: true }));
            }, 3000);
        } catch (error) {
            setSignupError(t.t((error as Error).message) || 'Error');
        }
        setLoading(false);
    }

    async function onChangeEmail(text: string) {
        setPendingRequest(true);
        setEmail(text);
        setDuplicateUsername(false);
        setInvalidEmail(false);
        console.log('validateEmail(text)', validateEmail(text));
        if (!validateEmail(text)) {
            setInvalidEmail(true);
            return;
        }
        const { status, data } = await AuthDataService.validateUsername({ email: text, website: config.WEBSITE });
        if (status === 200) {
            if (data.errorCode === 'AlreadyInUseException') {
                setDuplicateUsername(true);
            } else if (data.errorCode === 'InvalidArgumentException') {
                setInvalidEmail(true);
            }
        }
        setPendingRequest(false);
    }

    async function onChangePhoneNumber(text: string) {
        setPendingRequest(true);
        var newText = '';
        var numbers = '+0123456789';
        if (text.length < 1) {
            setPhone('');
        }
        for (var i = 0; i < text.length; i++) {
            if (numbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
        }
        setPhone(newText);
        let phoneCountryPrefix = phonePrefix;
        for (i = 2; i < 5; i++) {
            let currentCountry: countries | undefined = countriesList.find(
                (country) => country.phonePrefix === newText.substring(1, i)
            );
            if (currentCountry) {
                setPhonePrefix('+' + currentCountry.phonePrefix);
                phoneCountryPrefix = '+' + currentCountry.phonePrefix;
                break;
            }
        }
        const { status, data } = await AuthDataService.validatePhone({
            phone: newText.replace(phonePrefix, ''),
            phoneCountryPrefix: phoneCountryPrefix,
        });
        if (status === 200) {
            setWrongPhone(!data.returnData);
        } else {
        }
        setPendingRequest(false);
    }

    function onSelchangeDealer(selectedDealer: string, dealerIndex: number) {
        setDealer(selectedDealer);
        setSelectedDealerIndex(dealerIndex);
        setChangeDealer(false);
    }

    function onSelchangeCountry(selectedCountry: string, countryIndex: number) {
        setClientCountry(selectedCountry);
        setSelectedCountryIndex(countryIndex);
    }

    function onSelchangeCurrency(selectedCurrency: string, currencyIndex: number) {
        setCurrency(selectedCurrency);
        setSelectedCurrencyIndex(currencyIndex);
        setChangeCurrency(false);
    }

    return isRegistrationSuccess ? (
        <View style={styles.registrationSuccess}>
            <Text style={styles.successTextStyle} maxWidth={maxWidth}>
                registration-successful
            </Text>
            <Button title={'auth-button-login'} onPress={() => linkTo(paths.login, 'REPLACE')} maxWidth={maxWidth} />
        </View>
    ) : (
        <KeyboardAvoidingView behavior='height' style={styles.keyBoard}>
            <ScrollbarWrapper style={styles.container}>
                <SafeAreaView style={styles.safeAreaStyle}>
                    <Loader loading={loading} />
                    <View style={styles.headContainer}>
                        <Text fontType="LATO_ITALIC_BOLD" style={styles.headText} maxWidth={maxWidth}>
                            {title}
                        </Text>
                    </View>
                    <View style={styles.headContainer2}>
                        <View style={styles.head2RowContainer}>
                            {Platform.OS === 'web' ? (
                                <Image
                                    source={{
                                        uri:
                                            'https://' +
                                            config.EXTERNAL_SOURCES_URL +
                                            'mobileplatform/Investing/investing_partner_' +
                                            colorScheme +
                                            '.svg'
                                    }}
                                    style={styles.logoStyle}
                                    resizeMode="contain"
                                />
                            ) : (
                                <SvgUri
                                    width={'60%'}
                                    style={styles.logoStyle}
                                    uri={
                                        'https://' +
                                        config.EXTERNAL_SOURCES_URL +
                                        'mobileplatform/Investing/investing_partner_' +
                                        colorScheme +
                                        '.svg?1'
                                    }
                                />
                            )}
                        </View>
                    </View>
                    <View style={styles.secureIconContainer}>
                        <View style={styles.secureLine} />
                        <View style={styles.helfBackground} />
                        <View style={styles.secureIconBackground}>
                            <View style={styles.secureIconRing}>
                                <Ionicons
                                    name={'shield-checkmark'}
                                    size={scaleSize(30, maxWidth)}
                                    style={styles.secureIcon}
                                />
                            </View>
                        </View>
                    </View>
                    <View style={styles.contentContainer}>
                        <View style={styles.createAccountTitleContainer}>
                            <Text fontType="LATO_ITALIC" style={styles.createAccountTitleText} maxWidth={maxWidth}>
                                create-account-content-title
                            </Text>
                        </View>
                        {dealers && dealers.length > 0 && !changeDealer && showDealerOption ? (
                            <Pressable onPress={() => setChangeDealer(true)}>
                                <TextInput
                                    containerStyle={styles.inputContainer}
                                    autoCapitalize="none"
                                    autoFocus={false}
                                    value={dealers[selectedDealerIndex].label}
                                    ioniconsLeftIcon={'settings-outline'}
                                    entypoRightIcon={'select-arrows'}
                                    onRightIconPress={() => setChangeDealer(true)}
                                    onFocus={() => setChangeDealer(true)}
                                    maxWidth={maxWidth}
                                    returnKeyType='next'
                                />
                            </Pressable>
                        ) : null}
                        {dealers && dealers.length > 0 && changeDealer && showDealerOption ? (
                            <View style={styles.inputContainer}>
                                <Picker
                                    style={styles.pickerStyle}
                                    itemStyle={styles.pickerRow}
                                    selectedValue={dealer}
                                    onValueChange={(itemValue, itemIndex) => onSelchangeDealer(itemValue, itemIndex)}
                                >
                                    {dealers.map((cdealer, index) => (
                                        <Picker.Item
                                            label={cdealer.label}
                                            value={cdealer.value}
                                            key={'DealerPicker' + index}
                                        />
                                    ))}
                                </Picker>
                            </View>
                        ) : null}
                        <TextInput
                            containerStyle={styles.inputContainer}
                            antDesignLeftIcon="user"
                            placeholder="registration-input-enter-name"
                            autoCapitalize="none"
                            autoFocus={false}
                            value={name}
                            onChangeText={(text) => setName(text)}
                            onBlur={() => setShowNameValidationMessage(true)}
                            maxWidth={maxWidth}
                            returnKeyType='next' />
                        <ErrorMessage
                            text={'registration-name-missing'}
                            visible={name === '' && showNameValidationMessage} />
                        <ErrorMessage
                            text={'registration-last-name-missing'}
                            visible={name !== '' && (name.indexOf(' ') < 0 || name.indexOf(' ') > name.length - 2)} />
                        <TextInput
                            containerStyle={styles.inputContainer}
                            leftIcon="email"
                            placeholder="registration-input-enter-email"
                            autoCapitalize="none"
                            keyboardType="email-address"
                            textContentType="emailAddress"
                            autoFocus={false}
                            value={email}
                            onChangeText={(text) => onChangeEmail(text)}
                            onBlur={() => setShowEmailValidationMessage(true)}
                            maxWidth={maxWidth}
                            returnKeyType='next' />
                        <ErrorMessage
                            text={'registration-email-missing'}
                            visible={email === '' && showEmailValidationMessage} />
                        {invalidEmail && email !== '' ? (
                            <>
                                <View style={styles.errorContainer}>
                                    <Text fontType="LATO_REGULAR" style={styles.duplicateUsernameError} maxWidth={maxWidth}>
                                        invalid-email
                                    </Text>
                                </View>
                            </>
                        ) : null}
                        {duplicateUsername ? (
                            <>
                                <View style={styles.errorContainer}>
                                    <Text fontType="LATO_REGULAR" style={styles.duplicateUsernameError} maxWidth={maxWidth}>
                                        duplicate-username
                                    </Text>
                                </View>
                                <View style={styles.errorContainer}>
                                    <Pressable
                                        onPress={() => {
                                            linkTo(paths.login);
                                        }}
                                    >
                                        <Text
                                            fontType="LATO_BOLD"
                                            style={styles.duplicateUsernameError}
                                            maxWidth={maxWidth}
                                        >
                                            go-to-login
                                        </Text>
                                    </Pressable>
                                    <Text fontType="LATO_REGULAR" style={styles.duplicateUsernameError} maxWidth={maxWidth}>
                                        go-to-login-suffix
                                    </Text>
                                </View>
                            </>
                        ) : null}

                        <TextInput
                            containerStyle={styles.inputContainer}
                            leftIcon="lock"
                            placeholder="registration-input-enter-password"
                            autoCapitalize="none"
                            autoCorrect={false}
                            secureTextEntry={passwordVisibility}
                            textContentType="password"
                            returnKeyType='next'
                            entypoRightIcon={passwordVisibility ? 'eye' : 'eye-with-line'}
                            value={password}
                            onChangeText={(text) => {
                                setPassword(text);
                                setShowPasswordValidationMessage(true);
                            }}
                            onRightIconPress={() => setPasswordVisibility(!passwordVisibility)}
                            onBlur={() => setShowPasswordValidationMessage(true)}
                            maxWidth={maxWidth} />
                        <ErrorMessage
                            text={'registration-password-missing'}
                            visible={password === '' && showPasswordValidationMessage} />
                        <ErrorMessage
                            text={'signup-password-strength-info'}
                            visible={password !== '' && !validatePasswordStrength(password) && showPasswordValidationMessage} />

                        <TextInput
                            containerStyle={styles.inputContainer}
                            leftIcon="lock"
                            placeholder="registration-input-confirm-password"
                            autoCapitalize="none"
                            autoCorrect={false}
                            secureTextEntry={passwordConfirmVisibility}
                            textContentType="password"
                            entypoRightIcon={passwordConfirmVisibility ? 'eye' : 'eye-with-line'}
                            value={passwordConfirm}
                            onChangeText={(text) => setPasswordConfirm(text)}
                            onRightIconPress={() => setPasswordConfirmVisibility(!passwordConfirmVisibility)}
                            onBlur={() => setShowPasswordConfirmValidationMessage(true)}
                            maxWidth={maxWidth}
                            returnKeyType='next' />
                        <ErrorMessage
                            text={'registration-password-confirm-missing'}
                            visible={(passwordConfirm === '' || password !== passwordConfirm) &&
                                showPasswordConfirmValidationMessage} />
                        <View style={styles.inputContainer}>
                            <Picker
                                style={styles.pickerStyle}
                                itemStyle={styles.pickerRow}
                                selectedValue={clientCountry}
                                onValueChange={(itemValue, itemIndex) => { onSelchangeCountry(itemValue, itemIndex); setShowCountryValidationMessage(true); }}
                            >
                                {cList.map((country, index) => (
                                    <Picker.Item
                                        label={country.name}
                                        value={country.abbreviation}
                                        key={'CountryPicker' + index}
                                    />
                                ))}
                            </Picker>
                        </View>
                        <ErrorMessage
                            text={'please-select-country'}
                            visible={(!clientCountry) &&
                                showCountryValidationMessage} />
                        <TextInput
                            containerStyle={styles.inputContainer}
                            leftIcon="phone"
                            placeholder={phonePlaceholder}
                            autoCapitalize="none"
                            autoFocus={false}
                            value={phone}
                            onChangeText={(text) => onChangePhoneNumber(text)}
                            onBlur={() => setShowPhoneValidationMessage(true)}
                            maxWidth={maxWidth}
                            returnKeyType='next' />
                        <ErrorMessage
                            text={'wrong-phone-message'}
                            visible={wrongPhone || (phone === phonePrefix && showPhoneValidationMessage)} />
                        {!changeCurrency ? (
                            <Pressable onPress={() => setChangeCurrency(true)}>
                                <TextInput
                                    containerStyle={styles.inputContainer}
                                    autoCapitalize="none"
                                    autoFocus={false}
                                    value={currencies[selectedCurrencyIndex].label}
                                    ioniconsLeftIcon={'settings-outline'}
                                    entypoRightIcon={'select-arrows'}
                                    onRightIconPress={() => setChangeCurrency(true)}
                                    onFocus={() => setChangeCurrency(true)}
                                    maxWidth={maxWidth}
                                />
                            </Pressable>
                        ) : null}
                        {changeCurrency ? (
                            <View style={styles.inputContainer}>
                                <Picker
                                    style={styles.pickerStyle}
                                    itemStyle={styles.pickerRow}
                                    selectedValue={currency}
                                    onValueChange={(itemValue, itemIndex) => onSelchangeCurrency(itemValue, itemIndex)}
                                >
                                    {currencies.map((ccurrency, index) => (
                                        <Picker.Item
                                            label={ccurrency.label}
                                            value={ccurrency.value}
                                            key={'CurrencyPicker' + index}
                                        />
                                    ))}
                                </Picker>
                            </View>
                        ) : null}
                        <View style={styles.privacyPolicyContainer}>
                            <Checkbox
                                checked={privacyPolicyChecked}
                                setChecked={() => {
                                    setPrivacyPolicyChecked(!privacyPolicyChecked);
                                    setShowPrivacyValidationMessage(true);
                                }} />
                            <Pressable
                                onPress={() => Linking.openURL(
                                    dealer === 'EVER'
                                        ? `${config.LEGAL_DOCUMENTS_EVER_URL}` :
                                        dealer === 'MAURITIUS' ? ` ${config.LEGAL_DOCUMENTS_MAURITIUS_URL}`
                                            : `${config.LEGAL_DOCUMENTS_URL}`
                                )}
                                style={styles.privacyPolicyLinkContainer}
                            >
                                <Text fontType="LATO_REGULAR" style={styles.privacyPolicyText} maxWidth={maxWidth}>
                                    privacy-policy-text
                                </Text>
                            </Pressable>
                        </View>
                        <ErrorMessage
                            text={'privacy-policy-checkbox-error'}
                            visible={showPrivacyValidationMessage && !privacyPolicyChecked} />
                        <Button
                            onPress={onHandleSignup}
                            title="registration-button-signup"
                            disabled={duplicateUsername || pendingRequest || wrongPhone || invalidEmail}
                            maxWidth={maxWidth}
                        />
                        <ErrorMessage text={signupError} visible={!!signupError} />
                        <Pressable
                            onPress={() => {
                                linkTo(paths.login);
                            }}
                            style={styles.loginLinkContainer}
                        >
                            <Text fontType="LATO_REGULAR" style={styles.goToLoginText} maxWidth={maxWidth}>
                                login-screen-link-text
                            </Text>
                        </Pressable>
                    </View>
                </SafeAreaView>
            </ScrollbarWrapper>
        </KeyboardAvoidingView>
    );
}

const stylesGenerator = (theme: Theme, custom?: { maxWidth: number }) =>
    StyleSheet.create({
        logoContainer: { height: '50%', justifyContent: 'center', alignItems: 'center' },
        logoStyle: { width: '100%', height: '100%', maxWidth: scaleSizeMobile(custom?.maxWidth), maxHeight: scaleSizeMobile(custom?.maxWidth) },
        keyBoard: { width: '100%', height: '100%' },
        pickerStyle: {
            width: '100%',
            height: Platform.OS !== 'ios' ? scaleSize(44, custom?.maxWidth) : 3 * scaleSize(44, custom?.maxWidth),
            fontSize: scaleSize(FONT_SIZE_S, custom?.maxWidth),
            backgroundColor: theme.border,
            color: theme.text_pendingOrder,
            borderRadius: scaleSize(5, custom?.maxWidth),
            padding: 0,
        },
        pickerRow: {
            width: '100%',
            height: Platform.OS !== 'ios' ? scaleSize(44, custom?.maxWidth) : 3 * scaleSize(44, custom?.maxWidth),
            fontSize: scaleSize(FONT_SIZE_S, custom?.maxWidth),
            color: theme.text
        },
        errorContainer: {
            width: '100%',
            paddingHorizontal: scaleSize(17, custom?.maxWidth),
            flexDirection: 'row',
            paddingBottom: scaleSize(15, custom?.maxWidth),
        },
        duplicateUsernameError: { fontSize: FONT_SIZE_M, color: theme.alert },
        privacyPolicyLinkContainer: { width: '100%', paddingRight: scaleSize(25, custom?.maxWidth) },
        goToLoginText: { color: theme.switch_trackOn, fontSize: FONT_SIZE_S },
        privacyPolicyText: {
            color: theme.switch_trackOn,
            fontSize: FONT_SIZE_XS,
            paddingLeft: scaleSize(5, custom?.maxWidth),
        },
        privacyPolicyContainer: {
            flexDirection: 'row',
            paddingBottom: scaleSize(20, custom?.maxWidth),
            alignItems: 'center',
        },
        createAccountTitleContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: scaleSize(20, custom?.maxWidth),
        },
        createAccountTitleText: { fontSize: FONT_SIZE_S, color: theme.tabIconSelected },
        secureLine: {
            width: '100%',
            height: 1,
            backgroundColor: theme.switch_trackOn,
            top: scaleSize(25, custom?.maxWidth),
        },
        helfBackground: {
            backgroundColor: theme.background_closeTradeButtonPressed,
            height: scaleSize(25, custom?.maxWidth),
            position: 'absolute',
            width: '100%',
            top: 0,
        },
        secureIconRing: {
            width: scaleSize(44, custom?.maxWidth),
            height: scaleSize(44, custom?.maxWidth),
            borderWidth: 1,
            borderColor: theme.white,
            borderRadius: scaleSize(22, custom?.maxWidth),
            justifyContent: 'center',
            alignItems: 'center',
        },
        secureIconBackground: {
            width: scaleSize(50, custom?.maxWidth),
            height: scaleSize(50, custom?.maxWidth),
            backgroundColor: theme.switch_trackOn,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: scaleSize(25, custom?.maxWidth),
        },
        secureIconContainer: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        secureIcon: { color: theme.white },
        headRowText: {
            paddingLeft: scaleSize(10, custom?.maxWidth),
            fontSize: FONT_SIZE_XS,
            color: theme.text_positionsCount,
        },
        loginLinkText: { color: theme.switch_trackOn, fontSize: FONT_SIZE_XS },
        loginLinkContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            paddingVertical: scaleSize(20, custom?.maxWidth),
        },
        headIcon: { color: theme.text_positionsCount },
        head2RowContainer: { flexDirection: 'row', height: '100%', alignItems: 'center' },
        headContainer2: {
            height: scaleSize(140, custom?.maxWidth),
            backgroundColor: theme.background_closeTradeButtonPressed,
            paddingHorizontal: scaleSize(28, custom?.maxWidth),
        },
        contentContainer: { paddingHorizontal: scaleSize(25, custom?.maxWidth), backgroundColor: theme.background },
        headText: { fontSize: FONT_SIZE_L, color: theme.white },
        headContainer: {
            width: '100%',
            backgroundColor: theme.switch_trackOn,
            justifyContent: 'center',
            alignItems: 'center',
            height: scaleSize(75, custom?.maxWidth),
            paddingHorizontal: scaleSize(15, custom?.maxWidth),
            flexDirection: 'row',
            textAlign: 'center',
        },
        safeAreaStyle: { flex: 1 },
        container: {
            flex: 1,
            height: '100%',
            alignSelf: 'center',
            maxWidth: custom?.maxWidth,
            paddingHorizontal: scaleSize(Platform.OS !== 'web' ? 0 : 12, custom?.maxWidth),
            width: isDesktop() ? scaleSize(390) : '100%',
        },
        title: {
            fontSize: FONT_SIZE_XXXL,
            fontWeight: '600',
            paddingBottom: scaleSize(24, custom?.maxWidth),
        },
        inputContainer: {
            marginBottom: scaleSize(20, custom?.maxWidth),
        },
        successTextStyle: {
            color: theme.text_pendingOrder,
            textAlign: 'center',
            fontSize: FONT_SIZE_L,
            padding: scaleSize(30, custom?.maxWidth),
        },
        registrationSuccess: {
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
    });
