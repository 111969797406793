import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Image, Pressable } from 'react-native';
import { PaymentConfig } from '~/api-services/PaymentConverterService/types';
import { Text } from '~/components/core';
import config from '~/config';
import { FONT_SIZE_S } from '~/constants';
import { isDesktop, scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { useAddFunds } from '~/hooks/useAddFunds';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import { Theme } from '~/theme';
import DesktopScreenHeader from '../Desktop/DesktopScreenHeader';
import ResolveCountryService from '~/api-services/ResolveCountryService';
import { isEUCountry } from '~/lib/utils';
type Props = {
    setActiveRightTab?: Function;
    currentAmount?: string;
    setProvider?: Function;
};

export default function AddFundsAlternative({ setActiveRightTab, currentAmount, setProvider }: Props) {
    const route = useRoute();
    const amount = currentAmount ? currentAmount : (route.params as any).amount;
    const { configData } = useAddFunds(amount != '' ? Number(amount) : 0);
    const styles = useStyles(stylesGenerator);
    const [enableSkrillLogo, setEnableSkrillLogo] = useState(false);

    useEffect(() => {
        (async () => {
                const { status, data } = await ResolveCountryService.resolveCountry();
                if (status === 200 && data.status) {
                    let clientCountry = data.returnData;
                    if (!isEUCountry(clientCountry) && clientCountry !== 'GB') {
                        setEnableSkrillLogo(true);
                    }
                }
            
        })();
    }, []);


    function openPaymentCategory(cProvider: string) {
        if (setProvider) {
            setProvider(cProvider);
        }
        if (setActiveRightTab) {
            setActiveRightTab('add-money-alternative-payment');
        }
    }

    function openPaymentCategoryCard(cProvider: string) {
        if (setProvider) {
            setProvider(cProvider);
        }
        if (setActiveRightTab) {
            setActiveRightTab('add-money-card');
        }
    }

    return (
        <>
            <DesktopScreenHeader goToScreenName="add-money" title="add-money" setActiveRightTab={setActiveRightTab} />
            <View style={styles.container}>
                {configData ? (
                    <View style={styles.content}>
                        <View style={styles.row} />
                        {configData.alternative && configData.alternative.length > 0
                            ? configData.alternative.map((paymentConfig: PaymentConfig, key) => (
                                <Pressable
                                    key={key}
                                    style={styles.row}
                                    onPress={() => {
                                        if (paymentConfig.provider === 'PAYTIKO' || paymentConfig.provider === 'ARENAMONEY') {
                                            isDesktop() && setActiveRightTab
                                                ? openPaymentCategoryCard(paymentConfig.provider)
                                                : linkTo(
                                                    paths.addFundsCard
                                                        .replace(':provider', paymentConfig.provider)
                                                        .replace(':paytikoProvider', '-1')
                                                        .replace(':amount', amount)
                                                );
                                        }
                                        else {
                                            isDesktop() && setActiveRightTab
                                                ? openPaymentCategory(paymentConfig.provider)
                                                : linkTo(
                                                    paths.addFundsAlternativePayment
                                                        .replace(':provider', paymentConfig.provider)
                                                        .replace(':method', 'method')
                                                        .replace(':amount', amount)
                                                );
                                        }
                                    }}
                                >
                                    <View style={styles.paymentCategoryBox}>
                                        {paymentConfig.provider === 'PAYTIKO' || paymentConfig.provider === 'ARENAMONEY' ?
                                        <Image
                                              source={
                                                paymentConfig.provider === 'PAYTIKO' ? 
                                                ( enableSkrillLogo ? 
                                                    require('../../../../assets/files/mobileplatform/Paytiko/crypto_skrill_visa.gif') : 
                                                    require('../../../../assets/files/mobileplatform/Paytiko/visa_master_crypto.gif'))
                                                : require('../../../../assets/files/mobileplatform/images/payments/visa_mastercard.gif')
                                            }
                                              style={styles.paymentCategory}
                                          /> :
                                        <Image
                                            source={{
                                                uri: 'https://' +
                                                    config.EXTERNAL_SOURCES_URL +
                                                    'mobileplatform/images/payments/' +
                                                    paymentConfig.provider + '.png',
                                            }}
                                            style={[styles.paymentCategory]}
                                        /> }
                                    </View>
                                    {config.WEBSITE === 'FXMERIDIAN' || config.WEBSITE === 'INVESTIGNFF' ? (
                                        <Text fontType="LATO_REGULAR" style={styles.descriptionText}>
                                            {'FXMERIDIAN-alternative-' + paymentConfig.provider}
                                        </Text>
                                    ) : (
                                        <Text fontType="LATO_REGULAR" style={styles.descriptionText}>
                                            {'alternative-' + paymentConfig.provider}
                                        </Text>
                                    )}
                                </Pressable>
                            ))
                            : null}
                    </View>
                ) : null}
            </View>
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        paymentCategoryBox: {
            justifyContent: 'center',
            alignItems: 'center',
            width: scaleSize(60),
            height: scaleSize(30),
            backgroundColor: theme.white,
            borderColor: theme.border_2,
            borderRadius: scaleSize(5),
            borderWidth: scaleSize(1),
            marginRight: 10,
        },
        descriptionText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        addFundsTitleContainer: {
            paddingVertical: scaleSize(40),
            justifyContent: 'center',
            alignItems: 'center',
            borderBottomWidth: 1,
            borderBottomColor: theme.border_2,
            marginHorizontal: scaleSize(10),
        },
        addFundsTitleText: { fontSize: FONT_SIZE_S, color: theme.greenLineDesktop },
        content: { width: '100%' },
        paymentCategory: {
            width: scaleSize(50),
            height: scaleSize(18),
        },
        row: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: scaleSize(10),
            borderBottomWidth: 1,
            borderBottomColor: theme.border_2,
            marginHorizontal: scaleSize(10),
        },
        container: {
            alignItems: 'center',
            flex: 1,
            justifyContent: 'space-between',
            width: '100%',
            height: '100%',
            paddingTop: scaleSize(7),
            paddingHorizontal: scaleSize(17),
        },
    });
