import React, { useEffect, useMemo, useRef, useState } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { Switch, Text, TextInput } from '~/components/core';
import { FONT_SIZE_XS, FONT_SIZE_L } from '~/constants';
import { formatCurrency, formatNumber } from '~/lib/format';
import { scaleSize } from '~/constants/scale';
import { useAppSelector, useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { Feather } from "~/components/core/Icons";
import { DataSymbol } from '~/api-services/LoadService/types';
import { ExchangeRates } from '~/api-services/ExchangeService/types';
import { accountBalanceSelector } from '~/state/selectors';

type Props = {
    label:
        | 'buy-when-rate-is'
        | 'sell-when-rate-is'
        | 'close-at-profit'
        | 'close-at-loss'
        | 'position-take-profit'
        | 'position-stop-loss';
    value: number;
    setValue: Function;
    marketPrice: number;
    setIsActiveConfirmButton?: Function;
    dataSymbol: DataSymbol;
    side: string;
    volume: number;
    isOpen: boolean;
    setIsOpen: Function;
    showEstresult?: boolean;
    min?: number;
    max?: number;
    pending?: boolean;
    exchangeRates: ExchangeRates | undefined;
};

export default function PendingOrderComponent({
    label,
    value,
    setValue,
    setIsActiveConfirmButton,
    marketPrice,
    dataSymbol,
    side,
    volume,
    isOpen,
    setIsOpen,
    showEstresult = true,
    min,
    max,
    pending,
    exchangeRates,
}: Props) {
    const styles = useStyles(stylesGenerator);
    const [longPressedSign, setLongPressedSign] = useState<'+' | '-' | undefined>();
    const timeout = useRef<NodeJS.Timeout>();
    const step = useMemo(() => Math.pow(10, -dataSymbol.digits), [dataSymbol.digits]);
    const currency = useAppSelector(accountBalanceSelector)?.currency || '';

    const [showPriceInput, setShowPriceInput] = useState(false);
    const [priceText, setPriceText] = useState(formatNumber(value, dataSymbol.digits));

    useEffect(() => {
        timeout.current && clearInterval(timeout.current);
        if (!longPressedSign) {
            return;
        }
        let cnt = 1;
        const s = longPressedSign === '+' ? step : longPressedSign === '-' ? -step : 0;
        timeout.current = setInterval(() => changeValue(value + s * cnt++), 10);
        return () => {
            timeout.current && clearInterval(timeout.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSymbol.digits, longPressedSign, setValue, step]);
    function changeValue(newValue: number) {
        if (max && newValue > max) {
            setValue(max);
        } else if (min && newValue < min) {
            setValue(min);
        } else {
            setValue(newValue);
        }
    }

    function onChangePrice(text: string) {
        var newText = '';
        var numbers = '0123456789.';
        text = text.replace(',', '.');
        for (var i = 0; i < text.length; i++) {
            if (numbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
        }
        setPriceText(newText);
        if (newText !== '') {
            changeValue(Number(newText));
        }
    }

    return (
        <>
            <View style={styles.pendingOrderRow}>
                <View style={pending ? styles.center : styles.left}>
                    <Text fontType="LATO_BOLD" style={styles.pendingOrderText}>
                        {label}
                    </Text>
                </View>
                {!pending ? (
                    <View style={styles.right}>
                        <Switch
                            value={isOpen}
                            onValueChange={() => {
                                setIsOpen(!isOpen);
                                if (setIsActiveConfirmButton) {
                                    setIsActiveConfirmButton(true);
                                }
                            }}
                        />
                    </View>
                ) : null}
            </View>
            {isOpen ? (
                <>
                    <View style={styles.pendingOrderButtonsRow}>
                        <Pressable
                            onPress={() => {
                                if (setIsActiveConfirmButton) {
                                    setIsActiveConfirmButton(true);
                                }
                                setValue(value - step);
                                setShowPriceInput(false);
                            }}
                            onPressIn={() => {
                                setLongPressedSign('-');
                                setShowPriceInput(false);
                            }}
                            onPressOut={() => {
                                setLongPressedSign(undefined);
                                setShowPriceInput(false);
                            }}
                            style={(args) => [
                                {
                                    opacity: args.pressed ? 0.5 : 1,
                                },
                            ]}
                        >
                            <View style={styles.roundButton}>
                                <Feather color="white" name={'minus'} size={scaleSize(10)} />
                            </View>
                        </Pressable>
                        <View style={styles.pendingPriceContainer}>
                            <Pressable
                                style={styles.pendingPriceContainer}
                                onPress={() => {
                                    setPriceText(formatNumber(value, dataSymbol.digits));
                                    setShowPriceInput(true);
                                }}
                            >
                                {showPriceInput ? (
                                    <View style={styles.inputContainer}>
                                        <TextInput
                                            autoCapitalize="none"
                                            autoCorrect={false}
                                            value={priceText}
                                            onChangeText={(text) => {
                                                onChangePrice(text);
                                                if (setIsActiveConfirmButton) {
                                                    setIsActiveConfirmButton(true);
                                                }
                                            }}
                                            keyboardType="decimal-pad"
                                            maxLength={10}
                                            onSubmitEditing={() => {
                                                setShowPriceInput(false);
                                                onChangePrice(priceText);
                                                if (setIsActiveConfirmButton) {
                                                    setIsActiveConfirmButton(true);
                                                }
                                            }}
                                            onKeyPress={() => 
                                            {
                                                if (setIsActiveConfirmButton) {
                                                    setIsActiveConfirmButton(true);
                                                }
                                            }
                                            }
                                            returnKeyType='done'
                                        />
                                    </View>
                                ) : (
                                    <Text fontType="LATO_REGULAR" style={styles.pendingPriceText}>
                                        {formatNumber(value, dataSymbol.digits)}
                                    </Text>
                                )}
                            </Pressable>
                            {showEstresult ? (
                                <View style={styles.estPendingContainer}>
                                    <Text fontType="LATO_REGULAR" style={styles.estPendingText}>
                                        {(marketPrice > value && side === 'sell') ||
                                        (value > marketPrice && side === 'buy')
                                            ? 'position-est-profit'
                                            : 'position-est-loss'}
                                    </Text>
                                    <Text fontType="LATO_REGULAR" style={styles.estPendingText}>
                                        {': '}
                                    </Text>
                                    <Text
                                        fontType="LATO_REGULAR"
                                        style={[
                                            styles.estPendingText,
                                            (marketPrice > value && side === 'sell') ||
                                            (value > marketPrice && side === 'buy')
                                                ? styles.green
                                                : styles.red,
                                        ]}
                                    >
                                        {exchangeRates
                                            ? formatCurrency(
                                                  ((side === 'buy' ? value - marketPrice : marketPrice - value) *
                                                      volume) /
                                                      (label === 'close-at-profit'
                                                          ? exchangeRates.profitExchangeRate
                                                          : exchangeRates.lossExchangeRate),
                                                  2,
                                                  currency
                                              )
                                            : ''}
                                    </Text>
                                </View>
                            ) : null}
                        </View>
                        <Pressable
                            onPress={() => {
                                if (setIsActiveConfirmButton) {
                                    setIsActiveConfirmButton(true);
                                }
                                setValue(value + step);
                                setShowPriceInput(false);
                            }}
                            onPressIn={() => {
                                setLongPressedSign('+');
                                setShowPriceInput(false);
                            }}
                            onPressOut={() => {
                                setLongPressedSign(undefined);
                                setShowPriceInput(false);
                            }}
                            style={(args) => [
                                {
                                    opacity: args.pressed ? 0.5 : 1,
                                },
                            ]}
                        >
                            <View style={styles.roundButton}>
                                <Feather color="white" name={'plus'} size={scaleSize(10)} />
                            </View>
                        </Pressable>
                    </View>
                    <View style={styles.separator} />
                    <View style={styles.pendingOrderInfoRow}>
                        <View style={styles.pendingOrderInfoTextContainer}>
                            <Text fontType="LATO_REGULAR" style={styles.pendingOrderInfoText}>
                                {'position-distance'}
                            </Text>
                            <Text fontType="LATO_REGULAR" style={styles.pendingOrderInfoText}>
                                {':'}
                            </Text>
                            <Text
                                fontType="LATO_REGULAR"
                                style={[styles.pendingOrderInfoText, styles.pendingOrderInfoTextSpace]}
                            >
                                {formatNumber(value - marketPrice, dataSymbol.digits)}
                            </Text>
                        </View>
                        <View style={styles.pendingOrderInfoTextContainer}>
                            <Text fontType="LATO_REGULAR" style={styles.pendingOrderInfoText}>
                                {'position-current-price'}
                            </Text>
                            <Text fontType="LATO_REGULAR" style={styles.pendingOrderInfoText}>
                                {':'}
                            </Text>
                            <Text
                                fontType="LATO_REGULAR"
                                style={[styles.pendingOrderInfoText, styles.pendingOrderInfoTextSpace]}
                            >
                                {formatCurrency(marketPrice, dataSymbol.digits, dataSymbol.currency)}
                            </Text>
                        </View>
                    </View>
                </>
            ) : null}
            <View style={styles.separator} />
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        inputContainer: {
            width: scaleSize(120),
            marginBottom: scaleSize(5),
        },
        pendingOrderText: { fontSize: FONT_SIZE_XS, color: theme.text_symbol },
        pendingPriceText: {
            fontSize: FONT_SIZE_L,
            letterSpacing: 0.5,
            color: theme.text_symbol,
        },
        pendingOrderButtonsRow: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: '16%',
            paddingBottom: scaleSize(22),
        },
        pendingPriceContainer: { justifyContent: 'center', alignItems: 'center' },
        estPendingContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
        green: { color: theme.text_upPrice },
        red: { color: theme.text_downPrice },
        estPendingText: {
            fontSize: FONT_SIZE_XS,
            letterSpacing: 0.5,
        },
        roundButton: {
            width: scaleSize(40),
            height: scaleSize(40),
            borderRadius: scaleSize(20),
            backgroundColor: theme.background_buttonInactive,
            justifyContent: 'center',
            alignItems: 'center',
        },
        pendingOrderInfoRow: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: scaleSize(17),
            height: scaleSize(26),
        },
        center: {
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%',
        },
        left: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
        },
        right: {
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'row',
        },
        pendingOrderInfoTextContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
        pendingOrderInfoText: { color: theme.text_pendingOrder, fontSize: FONT_SIZE_XS },
        pendingOrderInfoTextSpace: { marginLeft: scaleSize(10) },
        pendingOrderRow: {
            height: scaleSize(46),
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            paddingHorizontal: scaleSize(17),
        },
        separator: {
            marginHorizontal: scaleSize(17),
            height: scaleSize(1),
            backgroundColor: theme.text_pendingOrder,
        },
    });
