import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { Button, Text } from '~/components/core';
import { isDesktop, scaleSize } from '~/constants/scale';
import { useAppSelector, useOpenTradesData, useStyles, useAppDispatch } from '~/hooks';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import {avatarsSelector, watchlistDataSelector} from '~/state/selectors';
import PositionGroup from './PositionGroup';
import { FONT_SIZE_LS } from '~/constants';
import { Theme } from '~/theme';
import { FontAwesome5 } from "~/components/core/Icons";
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import { changeOpenTradesActive, loadUserKeepALiveData } from "~/state/reducers/userDataSlice";
import { getAvatars } from "~/state/reducers/avatarSlice";

export default function OpenTrades() {
    const { positions, openSymbol, setOpenSymbol } = useOpenTradesData();
    const watchlist = useAppSelector(watchlistDataSelector);
    const avatars = useAppSelector(avatarsSelector);

    const styles = useStyles(stylesGenerator);
    const [investorUids, setInvestorUids] = useState<{[uid: string]: string}>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(changeOpenTradesActive(true));
        dispatch(loadUserKeepALiveData());
        return () => {
            dispatch(changeOpenTradesActive(false));
        };
    }, []);

    useEffect(() => {
        let uids = new Set<string>([]);
        Object.entries(positions).map((item, key) => {
            for(let i=0;i<item[1].length;i++) {
                const investorUid = item[1][i].investorUid;
                if (investorUid) {
                    uids.add(investorUid);
                }
            }
        });
        if (uids.size == 0) {
            return;
        }
        let result: {[uid: string]: string} = {};
        let loadUids: string[] = [];
        uids.forEach(uid => {
            const avatar = avatars.avatarUrls[uid];
            if (avatar) {
                result[uid] = avatar;
            } else {
                loadUids.push(uid);
                result[uid] = avatars.defaultAvatarUrl;
            }
        })
        setInvestorUids(result);
        if (loadUids.length > 0) {
            dispatch(getAvatars(loadUids));
        }
    }, [positions, avatars]);

    return (
        <>
            {Object.entries(positions).length > 0 ? (
                <ScrollbarWrapper>
                    <View style={styles.openTradesContainer}>
                        {
                            Object.entries(positions).map((item, key) => (
                                <View
                                    style={styles.button}
                                    key={key}
                                >
                                    <PositionGroup setOpenSymbol={setOpenSymbol} positions={item[1]} symbol={item[0]} isOpen={openSymbol === item[0]} investorUid={null} investorAvatar={null}/>
                                </View>
                            ))
                        }
                        { 
                        investorUids ?
                        Object.entries(investorUids).map((uid, k) => (
                            Object.entries(positions).map((item, key) => (
                                <View
                                    style={styles.button}
                                    key={key + '_' + k}
                                >
                                    <PositionGroup setOpenSymbol={setOpenSymbol} positions={item[1]} symbol={item[0]} isOpen={openSymbol === item[0] + '_' + uid[0]} investorUid={uid[0]} investorAvatar={uid[1]} />
                                </View>
                            ))
                        ))
                        
                        : null}
                    </View>
                </ScrollbarWrapper>
            ) : (
                <View style={styles.emptyContainer}>
                    <Pressable
                        onPress={() => linkTo(paths.watchlistItem(watchlist[0].id))}
                        style={styles.messageContainer}
                    >
                        <View style={styles.background_message}>
                            <View style={styles.messageIcon}>
                                <FontAwesome5 name="exclamation" style={styles.messageIconColor} size={scaleSize(15)} />
                            </View>
                            <Text style={styles.messageText} fontType="LATO_BOLD">
                                {isDesktop() ? 'empty-open-trades-message-desktop' : 'empty-open-trades-message'}
                            </Text>
                        </View>
                    </Pressable>
                    {!isDesktop() ? (
                        <View style={styles.buttonContainer}>
                            <View style={styles.buttonPosition}>
                                <Button
                                    title={'explore-markets'}
                                    titleSize={22}
                                    onPress={() => linkTo(paths.watchlistItem(watchlist[0].id))}
                                />
                            </View>
                        </View>
                    ) : null}
                </View>
            )}
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        messageText: { fontSize: FONT_SIZE_LS, color: theme.white },
        iconExclamation: { marginLeft: scaleSize(-16), paddingRight: scaleSize(18) },
        iconChatbubble: { transform: [{ rotate: '-90deg' }] },
        emptyContainer: { height: '100%', width: '100%', flex: 1, alignItems: 'center' },
        buttonPosition: {
            width: '91%',
            justifyContent: 'center',
            alignItems: 'center',
            marginHorizontal: '4.5%',
        },
        buttonContainer: {
            width: '100%',
            height: '50%',
            paddingBottom: scaleSize(17),
            justifyContent: 'flex-end',
        },
        messageIconColor: { color: theme.background_message },
        messageIcon: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: scaleSize(6),
            backgroundColor: theme.white,
            height: scaleSize(26),
            width: scaleSize(26),
            borderRadius: scaleSize(13),
        },
        background_message: {
            height: scaleSize(80),
            backgroundColor: theme.background_message,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        messageContainer: {
            height: '50%',
            width: '100%',
            justifyContent: 'flex-end',
        },
        openTradesContainer: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            paddingTop: scaleSize(7),
            paddingLeft: scaleSize(7),
        },
        button: { width: '100%' },
    });
