import { useEffect, useState } from 'react';
import CustomerInfoService from '~/api-services/CustomerInfoService';
import { CustomerInfo } from '~/api-services/CustomerInfoService/types';

export const useCustomerInfo = (provider: string) => {
    const [customerInfo, setCustomerInfo] = useState<CustomerInfo>();

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        switch (provider) {
            case 'ARENAMONEY':
                {
                    const { status, data } = await CustomerInfoService.getCustomerInfoArenaMoneyPayForm();
                    if (status === 200 && data.status) {
                        setCustomerInfo(data.returnData);
                    }
                }
                break;
            case 'PAYTIKO':
                {
                    const { status, data } = await CustomerInfoService.getCustomerInfoPaytikoPayForm();
                    if (status === 200 && data.status) {
                        setCustomerInfo(data.returnData);
                    }
                }
                break;
            case 'TRUSTPAYMENTS':
            {
                const { status, data } = await CustomerInfoService.getCustomerInfoTrustpayments();
                if (status === 200 && data.status) {
                    setCustomerInfo(data.returnData);
                }
            }
                break;
            case 'TRUSTPAYMENTSIDEAL':
            {
                const { status, data } = await CustomerInfoService.getCustomerInfoIdeal();
                if (status === 200 && data.status) {
                    setCustomerInfo(data.returnData);
                }
            }
                break;
            case 'EMERCHANTPAYFORM':
            {
                const { status, data } = await CustomerInfoService.getCustomerInfoEMerchant();
                if (status === 200 && data.status) {
                    setCustomerInfo(data.returnData);
                }
            }
            break;
            default:
                setCustomerInfo(undefined);
        }
    }

    return {
        customerInfo,
    };
};
