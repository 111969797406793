import React, { useMemo, useEffect } from 'react';
import { View, StyleSheet, Image, Pressable } from 'react-native';
import { Text } from '~/components/core';
import { Position } from '~/api-services/LoadService/types';
import PositionItem from './PositionItem';
import { useAppDispatch, useAppSelector, useStyles } from '~/hooks';
import { Feather } from "~/components/core/Icons";
import { accountBalanceSelector, appSettingsSelector, symbolsSelector } from '~/state/selectors';
import { FONT_SIZE_XS, FONT_SIZE_S } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { Theme } from '~/theme';
import { formatCurrency, formatNumber } from '~/lib/format';
import { loadSymbols } from '~/state/reducers/symbolsSlice';

type Props = {
    positions: Position[];
    symbol: string;
    isOpen: boolean;
    investorUid: null | string;
    investorAvatar: null | string;
    setOpenSymbol: Function;
};

export default function PositionGroup({ positions, symbol, isOpen, investorUid, investorAvatar, setOpenSymbol }: Props) {
    const styles = useStyles(stylesGenerator);
    const dataSymbol = useAppSelector((state) => symbolsSelector(state)[symbol]);
    const currency = useAppSelector(accountBalanceSelector)?.currency || '';
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!dataSymbol) {
            dispatch(loadSymbols([symbol]));
        }
    }, [dispatch, dataSymbol]);


    const [volume, volumeFormatted, result, resultFormatted, numPoistions] = useMemo(() => {
        const { vol, res, num } = positions.reduce(
            (memo, pos) => ({
                vol: memo.vol + ((pos.investorUid === investorUid) ? (pos.pending ? 0 : pos.side === 'sell' ? -1 : 1) * pos.volume : 0),
                res: memo.res + (pos.investorUid === investorUid ? pos.result : 0),
                num: memo.num + (pos.investorUid === investorUid ? 1 : 0),
            }),
            {
                vol: 0,
                res: 0,
                num: 0,
            }
        );
        const volFormatted = formatNumber(vol, dataSymbol && dataSymbol.volumePrecision ? dataSymbol.volumePrecision : 0);
        const resFormatted = formatNumber(res, 2);
        const numPoistions = num;
        return [vol, volFormatted, res, resFormatted, numPoistions];
    }, [positions]);
    const { colorScheme } = useAppSelector(appSettingsSelector);
    if(numPoistions > 0 && dataSymbol) {
        return (
            <>
                <Pressable
                    onPress={() => setOpenSymbol(investorUid ? isOpen ? null : symbol + '_' + investorUid: isOpen ? null : symbol)}
                    style={[
                        styles.positionGroupContainer,
                        isOpen ? styles.positionGroupOpen : styles.positionGroupClose,
                        colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
                    ]}
                    key={'instrumentContainer' + symbol}
                >
                    <View style={styles.instrumentIcon}>
                        <View style={investorUid ? styles.positionsCountCT : styles.positionsCount}>
                            <Text style={styles.positionCountText}>
                                {numPoistions > 9 ? '9+' : numPoistions.toString()}
                            </Text>
                        </View>
                       { investorUid ? 
                       <View style={styles.avatarContainer}>
                        {investorAvatar ? 
                        <Image
                            source={{
                                uri: investorAvatar,
                            }}
                            style={[styles.avatarStyle, isOpen ? null : styles.avatarGroupClose]}
                        />
                        : null}
                       </View>
                       : 
                       <Feather style={styles.positionGroupIconColor} name={'menu'} size={scaleSize(22)} /> }
                    </View>
                    <View style={styles.instrumentName}>
                        <Text fontType="LATO_BOLD" style={[styles.instrumentNameText, {paddingLeft: investorUid ? scaleSize(10) : 0}]}>
                            {dataSymbol.nameTranslated}
                        </Text>
                    </View>
                    <View key={'positionContainer' + symbol} style={styles.positionGroupItemContainer}>
                        <View style={styles.positionGroupVolume}>
                            <View style={styles.volumeBackground}>
                                {volume > 0 ? (
                                    <Text fontType="LATO_BOLD" style={styles.volumeText}>
                                        +
                                    </Text>
                                ) : null}
                                <Text fontType="LATO_BOLD" style={styles.volumeText}>
                                    {volumeFormatted}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.positionGroupResult}>
                            <Text
                                fontType="LATO_BOLD"
                                style={[
                                    styles.positionResultText,
                                    result >= 0 ? styles.positionResultPositive : styles.positionResultNegative,
                                ]}
                            >
                                {formatCurrency(result, 2, currency)}
                            </Text>
                        </View>
                    </View>
                </Pressable>
                {isOpen &&
                    positions.map((position, index) => (
                        <PositionItem
                            dataSymbol={dataSymbol}
                            position={position}
                            lastItem={index === numPoistions - 1}
                            currency={currency}
                            investorUid={investorUid}
                            key={index}
                        />
                    ))}
            </>
        );}
        else {
            return null;
        }
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        avatarContainer: {
            position: 'absolute',
            left: 0
        },
        avatarGroupClose: {borderBottomLeftRadius: scaleSize(4.8),},
        avatarStyle: {
            width: scaleSize(48),
            height: scaleSize(48),
            borderTopLeftRadius: scaleSize(4.8),
        },
        positionGroupContainer: {
            flexDirection: 'row',
            borderWidth: 1,
            width: '100%',
            borderTopLeftRadius: scaleSize(5),
            borderTopRightRadius: scaleSize(5),
            height: scaleSize(50),
            backgroundColor: theme.backgroundSecondary,
        },
        positionGroupClose: { borderRadius: scaleSize(5), marginTop: scaleSize(7) },
        positionGroupOpen: {
            borderTopLeftRadius: scaleSize(5),
            borderTopRightRadius: scaleSize(5),
            marginTop: scaleSize(7),
        },
        borderColorDark: { borderColor: theme.background },
        borderColorWhite: { borderColor: theme.border_2 },
        positionResultPositive: {
            color: theme.text_upPrice,
        },
        positionResultNegative: {
            color: theme.text_downPrice,
        },
        positionGroupItemContainer: {
            flexDirection: 'row',
            width: '64%',
            height: scaleSize(50),
            justifyContent: 'center',
        },
        instrumentIcon: { width: '14%', justifyContent: 'center', alignItems: 'center' },
        instrumentName: { flexWrap: 'wrap', width: '22%', justifyContent: 'center' },
        positionGroupVolume: { width: '55%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
        positionGroupResult: { width: '45%', justifyContent: 'center', alignItems: 'flex-end', paddingRight: '2%' },
        instrumentNameText: { fontSize: FONT_SIZE_S, letterSpacing: 0, color: theme.text_symbol },
        volumeBackground: {
            backgroundColor: theme.background_message,
            borderRadius: scaleSize(2),
            flexDirection: 'row',
            paddingHorizontal: scaleSize(5),
            paddingVertical: scaleSize(3),
        },
        volumeText: { color: theme.white, fontSize: FONT_SIZE_XS, letterSpacing: 0.5 },
        positionsCount: {
            marginBottom: scaleSize(-12),
            marginLeft: scaleSize(-6),
            borderRadius: scaleSize(8),
            backgroundColor: theme.tabIconSelected,
            width: scaleSize(16),
            height: scaleSize(16),
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
        },
        positionsCountCT: {
            marginBottom: scaleSize(30),
            marginLeft: scaleSize(-34),
            borderRadius: scaleSize(8),
            backgroundColor: theme.tabIconSelected,
            width: scaleSize(16),
            height: scaleSize(16),
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
        },
        positionCountText: { fontSize: FONT_SIZE_XS, color: theme.white },
        positionResultText: { fontSize: FONT_SIZE_S, letterSpacing: 0.5 },
        positionGroupIconColor: { color: theme.background_message },
    });
