import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import AddFundsAlternative from '../features/AddFundsAlternative';

type Props = {
    currentAmount?: string;
    setActiveRightTab?: Function;
    setProvider?: Function;
};

export default function AddFundsAlternativeScreen({ setActiveRightTab, setProvider, currentAmount }: Props) {
    return (
        <ScreenWrapper>
            <AddFundsAlternative setActiveRightTab={setActiveRightTab}
                setProvider={setProvider}
                currentAmount={currentAmount}/>
        </ScreenWrapper>
    );
}
