import React from 'react';
import { View, StyleSheet } from 'react-native';

import { isDesktop, scaleSize } from '~/constants/scale';
import { useAppSelector, useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { Button, Text } from '~/components/core';
import { FONT_SIZE_LS, FONT_SIZE_S } from '~/constants';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import { Entypo } from "~/components/core/Icons";
import i18n from '~/i18n';
import { accountDetailsSelector } from '~/state/selectors';


type Props = {
    goToFirstStep: Function;
    setActiveRightTab: Function;
};

export default function Finished({ setActiveRightTab, goToFirstStep }: Props) {
    const styles = useStyles(stylesGenerator);
    const accountDetails = useAppSelector(accountDetailsSelector);


    return (
        <View style={styles.container}>
            <View style={styles.contentWithTexts}>
                <View style={styles.content}>
                    <View style={styles.messageContainer}>
                        <View style={styles.checkContainer}>
                            <Entypo
                                name="check"
                                style={styles.messageIconColorSuccess}
                                size={scaleSize(26)}
                            />

                        </View>
                        <Text style={styles.messageText} fontType="LATO_BOLD">
                            {i18n.t('finish') + '!'}
                        </Text>
                    </View>
                </View>
                <View style={styles.textContainer}>
                    <Text fontType="LATO_BOLD" style={styles.text}>
                        {i18n.t('vc-finished-text1') + ' ' + accountDetails.name + ','}
                    </Text>
                    <Text fontType="LATO_BOLD" style={styles.text}>
                        vc-finished-text2
                    </Text>
                    <Text fontType="LATO_BOLD" style={styles.text}>
                        vc-finished-text3
                    </Text>
                    <Text fontType="LATO_BOLD" style={styles.text}>
                        vc-finished-text4
                    </Text>
                </View>
            </View>
            <View style={styles.buttonsContainer}>
                <View style={styles.buttonContainer}>
                    <Button
                        onPress={() => goToFirstStep()}
                        title="back"
                        backgroundColor={styles.backButtonStyle}
                    />
                </View>
                <View style={styles.buttonContainer}>
                    <Button
                        onPress={() => {
                            isDesktop() && setActiveRightTab
                                ? setActiveRightTab('more')
                                : linkTo(paths.more);

                        }}
                        title="close"
                        backgroundColor={styles.closeButtonStyle}
                    />
                </View>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        closeButtonStyle: { backgroundColor: theme.background_message },
        textContainer: { paddingHorizontal: scaleSize(17) },
        text: { paddingTop: scaleSize(20), fontSize: FONT_SIZE_S, color: theme.text_symbol },
        contentWithTexts: { justifyContent: 'center' },
        backButtonStyle: { backgroundColor: theme.tabIconDefault },
        content: {
            width: '100%',
            borderBottomLeftRadius: scaleSize(5),
            borderBottomEndRadius: scaleSize(5),
            backgroundColor: theme.background_message,
            height: scaleSize(104),
            justifyContent: 'center',
            alignItems: 'center'
        },
        messageIconColorSuccess: { color: theme.tabIconSelected },
        messageContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            paddingHorizontal: scaleSize(7),
        },
        messageText: {
            fontSize: FONT_SIZE_LS,
            color: theme.white,
            textAlign: 'center',
        },
        checkContainer: {
            height: scaleSize(40),
            width: scaleSize(40),
            borderRadius: scaleSize(20),
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: scaleSize(12),
            backgroundColor: theme.white
        },
        buttonsContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingVertical: scaleSize(20),
            width: '100%'
        },
        buttonContainer: {
            marginHorizontal: scaleSize(10),
            width: scaleSize(150),
            height: scaleSize(40),
        },
        container: {
            flex: 1,
            justifyContent: 'space-between',
            height: '100%',
        },
    });
