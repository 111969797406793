import React, { useState, useEffect, useRef } from 'react';
import { Image, StyleSheet, View, Pressable } from 'react-native';
import { FONT_SIZE_L, FONT_SIZE_S } from '~/constants';
import { useAppSelector, useStyles, useAppDispatch, useLogout, useIsAppActive } from '~/hooks';
import { appSettingsSelector, accountDetailsSelector, dealerSelector, userIsLoggedInSelector, userAuthDataSelector } from '~/state/selectors';
import { Text, Switch, Button } from '~/components/core';
import { Theme } from '~/theme';
import { Ionicons, Entypo } from "~/components/core/Icons";
import { saveAppSettings } from '~/state/reducers/appSettingsSlice';
import VerificationService from '~/api-services/VerificationService';
import config from '~/config';


type Props = {
    setActiveRightTab: Function;
    setOpenLanguageMenu: Function;
    openLanguageMenu: boolean;
    activeRightTab: string;
};

export default function DesktopHeader({ setActiveRightTab, setOpenLanguageMenu, openLanguageMenu, activeRightTab }: Props) {
    const styles = useStyles(stylesGenerator);
    const { language } = useAppSelector(appSettingsSelector);
    const accountDetails = useAppSelector(accountDetailsSelector);
    const [colorSchemeSwitch, setColorSchemeSwitch] = useState<'light' | 'dark' | undefined>('light');
    const [loadSettings, setLoadSettings] = useState(false);
    const { colorScheme } = useAppSelector(appSettingsSelector);
    const dispatch = useAppDispatch();
    const logout = useLogout();
    const dealer = useAppSelector(dealerSelector);
    const [showVCButton, setShowVCButton] = useState(false);
    const reloadDataPollRef = useRef<NodeJS.Timer>();
    const isAppActive = useIsAppActive();
    const isLoggedIn = useAppSelector(userIsLoggedInSelector);
    const { type } = useAppSelector(userAuthDataSelector);

    useEffect(() => {
        if (!loadSettings) {
            setColorSchemeSwitch(colorScheme);
            setLoadSettings(true);
        }
    }, [loadSettings, colorScheme]);

    useEffect(() => {
        loadCustomerData();
    }, [dealer]);
    console.log('config.THEME3: ' + config.THEME);

    useEffect(() => {
        if (isAppActive && isLoggedIn && showVCButton === true && dealer === 'EVER') {
            reloadDataPollRef.current = setInterval(() => {
                loadCustomerData();
            }, 180000); // refetch 1 min
        } else {
            reloadDataPollRef.current && clearInterval(reloadDataPollRef.current);
        }
        return () => {
            reloadDataPollRef.current && clearInterval(reloadDataPollRef.current);
        };
    }, [isLoggedIn, isAppActive, activeRightTab]);

    async function loadCustomerData() {

        if (dealer === 'EVER') {
            const { status, data } = await VerificationService.getCustomerData();
            if (status === 200 && data.status) {
                if (data.returnData.businessAndProfessionalActivity != null &&
                    data.returnData.servicesToUse != null &&
                    data.returnData.assetManagement != null &&
                    data.returnData.howManyTransactions != null &&
                    data.returnData.educationFinancialServicesPast3Years != null &&
                    data.returnData.depositLeverageQuestion != null &&
                    data.returnData.tradedUsingLeverageMagnifiesPotentialProfitsLossesText != null &&
                    data.returnData.realDeliveryAsset != null &&
                    data.returnData.automaticallyClosedPosition != null &&
                    data.returnData.whatHappenStopLossOrder != null &&
                    data.returnData.howCharacterizeKnowledge != null) {
                    setShowVCButton(false);
                }
                else {
                    setShowVCButton(true);
                }
            }
        }
        else {
            setShowVCButton(false);
        }
    }

    return (
        <View style={[styles.container]}>
            <View style={styles.contentContainer}>
                <View style={styles.leftContainer}>
                    <View style={styles.profileContainer}>
                        <Ionicons name="person-circle-outline" style={styles.profileIconColor} size={22} />
                    </View>
                    <View style={styles.clientNameContainer}>
                        <Text fontType="LATO_BOLD" style={styles.balanceLabel} noTranslate={true}>
                            {accountDetails ? accountDetails?.name + '/' + accountDetails?.id : ''}
                        </Text>
                    </View>
                    {
                    type==='REAL' ?
                    showVCButton ?
                        <Pressable
                            style={styles.VCContainer}
                            onPress={() => {
                                setActiveRightTab('veryfication-center')
                            }}
                        >
                            <Button
                                title="verification-center"
                                buttonHeight={22}
                                titleSize={FONT_SIZE_S}
                                onPress={() => {
                                    setActiveRightTab('veryfication-center')
                                }}
                            />
                        </Pressable>
                        :
                        <Pressable
                            style={styles.addFundsContainer}
                            onPress={() => {
                                setActiveRightTab('add-money');
                            }}
                        >
                            <Button
                                title="account-balance-add-funds"
                                buttonHeight={22}
                                titleSize={FONT_SIZE_S}
                                onPress={() => {
                                    setActiveRightTab('add-money');
                                }}
                            />
                        </Pressable> : null}
                </View>
                <View style={styles.rightContainer}>
                    <View style={styles.logoutContainer}>
                        <Button
                            title="logout-label"
                            buttonHeight={22}
                            backgroundColor={styles.logoutButtonStyle}
                            titleSize={FONT_SIZE_S}
                            onPress={logout}
                        />
                    </View>
                    <View style={styles.switchContainer}>
                        <View style={styles.pressableArea}>
                            <View style={styles.textPadding}>
                                <Text fontType="LATO_REGULAR" style={[styles.swithText, styles.themeLightText]}>
                                    theme-light
                                </Text>
                            </View>
                            <Switch
                                value={colorSchemeSwitch === 'dark'}
                                style={styles.switchButton}
                                onValueChange={(value) => {
                                    setColorSchemeSwitch(value ? 'dark' : 'light');
                                    dispatch(saveAppSettings({ colorScheme: value ? 'dark' : 'light' }));
                                }}
                            />
                            <View style={styles.textPadding}>
                                <Text fontType="LATO_REGULAR" style={[styles.swithText, styles.themeDarkText]}>
                                    theme-dark
                                </Text>
                            </View>
                        </View>
                    </View>
                    <Pressable style={styles.languageContainer} onPress={() => setOpenLanguageMenu(!openLanguageMenu)}>
                        {language === 'de' ? (
                            <Image
                                source={require('../../../../assets/images/flags/de.png')}
                                style={styles.languageFlag}
                            />
                        ) : language === 'fr' ? (
                            <Image
                                source={require('../../../../assets/images/flags/fr.png')}
                                style={styles.languageFlag}
                            />
                        ) : language === 'es' ? (
                            <Image
                                source={require('../../../../assets/images/flags/es.png')}
                                style={styles.languageFlag}
                            />
                        ) : language === 'ru' ? (
                            <Image
                                source={require('../../../../assets/images/flags/ru.png')}
                                style={styles.languageFlag}
                            />
                        ) : (
                            <Image
                                source={require('../../../../assets/images/flags/en.png')}
                                style={styles.languageFlag}
                            />
                        )}
                        <Text fontType="LATO_REGULAR" style={styles.languageText}>
                            {'language-' + language}
                        </Text>
                        <Entypo name="chevron-down" size={14} style={styles.languagePicker} />

                    </Pressable>

                    <Pressable style={styles.iconCircle} onPress={() => setActiveRightTab('more')}>
                        <Ionicons name="settings-sharp" size={15} style={styles.iconColor} />
                    </Pressable>
                </View>
            </View>
            <View style={styles.greenLine} />
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        languageFlag: { width: 30, height: 20, marginRight: 10 },
        languagePicker: { color: theme.languagePicker, marginLeft: 5 },
        languageText: { fontSize: FONT_SIZE_S, color: theme.desktopHeaderTextColor },
        languageContainer: { marginRight: 40, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
        logoutButtonStyle: { backgroundColor: theme.logoutButtonBackgroundColor },
        logoutContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            width: 80,
            height: 22,
            marginRight: 40,
        },
        addFundsContainer: { marginLeft: 60, width: 110, height: 22, justifyContent: 'center', alignItems: 'center' },
        VCContainer: { marginLeft: 60, width: 150, height: 22, justifyContent: 'center', alignItems: 'center' },
        textPadding: { paddingHorizontal: 10 },
        pressableArea: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 40,
        },
        switchContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        switchButton: {
            justifyContent: 'flex-end',
        },
        themeLightText: { color: theme.themeLightText },
        themeDarkText: { color: theme.themeDarkText },
        swithText: { fontSize: FONT_SIZE_S, justifyContent: 'flex-start' },
        iconCircle: {
            marginRight: 15,
            height: 30,
            width: 30,
            borderRadius: 15,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.background_message,
        },
        iconColor: { color: theme.white },
        greenLine: { width: '100%', height: 1, backgroundColor: theme.greenLineDesktop },
        clientNameContainer: { paddingLeft: 15, justifyContent: 'center', alignItems: 'center' },
        rightContainer: {
            flexDirection: 'row',
            height: 59,
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        leftContainer: {
            flexDirection: 'row',
            height: 59,
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        profileContainer: {
            backgroundColor: theme.tabIconSelected,
            height: 24,
            width: 24,
            borderRadius: 12,
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 15,
        },
        profileIconColor: {
            color: theme.white,
        },
        contentContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: theme.background,
        },
        container: {
            width: '100%',
            height: 60,
        },
        balanceContainer: {
            flexDirection: 'row',
            paddingLeft: 50,
            justifyContent: 'center',
            alignItems: 'center',
        },
        balanceLabel: {
            fontSize: FONT_SIZE_L,
            color: theme.desktopHeaderTextColor,
        },
        balanceText: {
            marginLeft: 15,
            fontSize: FONT_SIZE_L,
            color: theme.text_pendingOrder,
            letterSpacing: 0.5,
        },
    });
