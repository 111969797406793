import React from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { scaleSize } from '~/constants/scale';
import { Theme } from '~/theme';
import { useStyles } from '~/hooks';
import { FontAwesome } from "~/components/core/Icons";

type Props = {
    checked: boolean;
    setChecked: Function;
    disabled?: boolean;
};

const Checkbox = ({ checked, setChecked, disabled }: Props) => {
    const styles = useStyles(stylesGenerator);

    return (
        <Pressable style={styles.checkboxContainer} onPress={() => setChecked(disabled && disabled === true ? checked : !checked)}>
            {checked ? <FontAwesome name="check" color={'#76CAC0'} size={scaleSize(12)} /> : null}
        </Pressable>
    );
};

export default Checkbox;

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        checkboxContainer: {
            height: scaleSize(20),
            width: scaleSize(20),
            borderRadius: scaleSize(5),
            borderColor: theme.equityValue,
            borderWidth: 1,
            backgroundColor: theme.backgroundSecondary,
            justifyContent: 'center',
            alignItems: 'center',
        },
    });
