import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Switch, Text } from '~/components/core';
import { isDesktop, scaleSize } from '~/constants/scale';
import ConfirmationMessage from '../ConfirmationMessage';
import Header from '../More/Header';
import { useStyles } from '~/hooks';
import PushnotificationsService from '~/api-services/PushnotificationsService';
import { NotificationCategory } from '~/api-services/PushnotificationsService/types';
import { MaterialCommunityIcons, MaterialIcons, Ionicons } from "~/components/core/Icons";
import { Theme } from '~/theme';
import { FONT_SIZE_S } from '~/constants';

export default function NotificationSettings() {
    const styles = useStyles(stylesGenerator);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [confirmationProps, setConfirmationProps] = useState<{ text: string; error: boolean }>({
        text: '',
        error: false,
    });

    const [categories, setCategories] = useState<NotificationCategory[]>([]);

    useEffect(() => {
        if (categories.length === 0) {
            PushnotificationsService.getSubscribedCategories().then((res) => {
                if (res.status !== 200 || !res.data.status) {
                    return;
                }
                setCategories(res.data.returnData);
            });
        }
    }, [categories]);

    async function savePushNotificationsSettings(newNotificationCategories: NotificationCategory[]) {
        let list: string[] = [];
        for (let category of newNotificationCategories) {
            if (category.subscribed) {
                list.push(category.code);
            }
        }
        const { status, data } = await PushnotificationsService.setSubscribedCategories(list);
        if (status !== 200) {
            setConfirmationProps({ text: 'Error', error: true });
            setConfirmationVisible(true);
        } else {
            if (data.status) {
                setConfirmationProps({ text: 'save-nottification-success', error: false });
                setConfirmationVisible(true);
            } else {
                setConfirmationProps({ text: 'save-nottification-error', error: true });
                setConfirmationVisible(true);
            }
        }
    }

    function changeCategory(categoryCode: string, subscribed: boolean) {
        let newNotificationCategories: NotificationCategory[] = [];
        for (let category of categories) {
            if (category.code !== categoryCode) {
                newNotificationCategories.push(category);
            } else {
                newNotificationCategories.push({
                    code: category.code,
                    subscribed: subscribed,
                    unsubscribeDescriptionTranslated: category.unsubscribeDescriptionTranslated,
                    titleTranslated: category.titleTranslated,
                });
            }
        }
        setCategories(newNotificationCategories);
        savePushNotificationsSettings(newNotificationCategories);
    }

    return (
        <>
            {!isDesktop() ? <Header /> : null}
            <View style={styles.container}>
                <View style={styles.viewContainer}>
                    {categories &&
                        categories.length !== 0 &&
                        categories.map((category: NotificationCategory, key) => (
                            <View style={styles.row}>
                                <View style={styles.rowLabel}>
                                    {category.code === 'PENDING_ORDER' ? (
                                        <View style={styles.iconCircle}>
                                            <MaterialIcons
                                                name="pending-actions"
                                                size={scaleSize(14)}
                                                style={styles.notificationsIcon}
                                            />
                                        </View>
                                    ) : category.code === 'MARGIN_CALL' ? (
                                        <View style={styles.iconCircle}>
                                            <MaterialCommunityIcons
                                                name="margin"
                                                size={scaleSize(14)}
                                                style={styles.notificationsIcon}
                                            />
                                        </View>
                                    ) : category.code === 'IMPORTANT_NEWS' ? (
                                        <View style={styles.iconCircle}>
                                            <Ionicons
                                                name="newspaper-outline"
                                                size={scaleSize(14)}
                                                style={styles.notificationsIcon}
                                            />
                                        </View>
                                    ) : null}
                                    <Text fontType="LATO_REGULAR" style={styles.notificationLabelText}>
                                        {category.titleTranslated}
                                    </Text>
                                </View>
                                <View style={styles.rowValue}>
                                    <View style={styles.switchContainer} key={key}>
                                        <Switch
                                            value={category.subscribed}
                                            onValueChange={() => {
                                                changeCategory(category.code, !category.subscribed);
                                            }}
                                        />
                                    </View>
                                </View>
                            </View>
                        ))}
                    <ConfirmationMessage
                        text={confirmationProps.text}
                        error={confirmationProps.error}
                        visible={confirmationVisible}
                        setVisible={setConfirmationVisible}
                    />
                </View>
            </View>
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        notificationLabelText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        iconCircle: {
            width: scaleSize(24),
            height: scaleSize(24),
            borderRadius: scaleSize(12),
            borderWidth: scaleSize(1),
            borderColor: theme.tabIconSelected,
            marginRight: scaleSize(10),
            justifyContent: 'center',
            alignItems: 'center',
        },
        notificationsIcon: { color: theme.tabIconSelected },
        row: {
            flexDirection: 'row',
            width: scaleSize(341),
            justifyContent: 'space-between',
            padding: scaleSize(10),
            borderBottomWidth: 1,
            borderBottomColor: theme.border_2,
        },
        rowValue: {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        rowLabel: { justifyContent: 'center', alignItems: 'center', flexDirection: 'row' },
        buttonContainer: { paddingBottom: scaleSize(20) },
        switchContainer: { paddingLeft: scaleSize(4) },
        container: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingHorizontal: scaleSize(17),
            height: '100%',
        },
        viewContainer: { height: '90%', justifyContent: 'flex-start' },
    });
